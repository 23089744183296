import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { booleaner } from 'src/utils/functionUtils'
import { makeStyles } from '@mui/styles';
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    '&.radio-horizontal': {
      width: 'fit-content',
      '& $group': {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center'
      }
    }
  },
  group: {
    margin: theme.spacing(0)
  }
}));

function RadioField(props) {
  const { label, isHorizontal, disable, data, style, ...rest } = props;
  const classes = useStyles();
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm, setTouched } = helper || {}
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const _onChange = (event) => {
    if (!touched) setTouched(true)
    const valueSelected = event.target.value
    const option = data.find(e => e.value === valueSelected)
    option && setValueForm && setValueForm(option, true)
  }

  return (
    <FormControl
      // component="fieldset"
      // required
      {...rest}
      error={isError}
      className={classnames(classes.formControl, {
        'radio-horizontal': isHorizontal,
        'radio-vertical': !isHorizontal
      })}
    >
      {label ? <FormLabel component="legend">{label}</FormLabel> : <React.Fragment />}
      <RadioGroup
        aria-label={label}
        name={label}
        className={classes.group}
        style={style}
        value={selectedValue && data.length ? (selectedValue.value ?? selectedValue) : ''}
        onBlur={field.onBlur}
        onChange={_onChange}
      >
        {data.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio color='secondary' icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      <input
        readOnly
        style={{ opacity: 0, position: 'absolute', pointerEvents: 'none', left: 136 }}
        type="checkbox" id="gender" required={rest.required} checked={!!(selectedValue && data.length ? (selectedValue.value ?? selectedValue) : '')} />
      {_renderHelperText()}
    </FormControl>
  );
}

RadioField.defaultProps = {
  data: []
};

RadioField.propTypes = {
  data: PropTypes.array.isRequired
};

export default RadioField;
