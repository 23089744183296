import React from 'react';
import { Grid, Typography, TextField, Link } from '@mui/material';
import { useFormikContext } from 'formik';
import {
    InputField,
    CheckboxField,
    RadioField,
    BooleanRadio,
    AutocompleteFieldAsync,
    SelectField,
    DatePickerField,
    AutocompleteField
} from 'src/components/CustomFields';
import {
    TRUST_TYPE_OPTIONS,
    TRUSTEE_TYPE_OPTIONS,
    TAX_EXEMPTION_DETAILS_OPTIONS,
    TRUST_COUNTRY_ESTABLISHED,
    AUSTRALIAN_TAX_OPTIONS,
    STREET_TYPE,
    STATE_OF_ISSUE_OPTIONS,
    TAX_STATUS_OPTIONS,
    TAX_STATUS,
    FATCA_STATUS_OPTIONS,
    FATCA_STATUS,
    TIN_REASON_OPTIONS,
    TRUST_ACTIVITY_OPTIONS
} from 'src/constants'
import COUNTRIES from 'src/countries'
import dataStorage from 'src/dataStorage'
import ChessInfo from 'src/components/ChessInfo'
import formModel from '../FormModels/formModels';
import { getEnv } from 'src/utils/functionUtils';
import HelpText from 'src/components/HelpText'
import LabelText from 'src/components/LabelText'
import HeadText from 'src/components/HeadText'
import HelpLabel from 'src/components/HelpLabel'

const { formField } = formModel

export default function TrustDetails() {
    const { values: formValues } = useFormikContext();

    const renderTaxInformation = () => {
        switch (formValues.tax_status?.value) {
            case TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <BooleanRadio name={formField.tax_resident_of_australia.name} label={formField.tax_resident_of_australia.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                        </Grid>
                        {
                            !formValues.tax_resident_of_australia
                                ? <React.Fragment />
                                : <React.Fragment>
                                    <Grid item xs={12}>
                                        <AutocompleteField required name={formField.country_of_tax_residency.name} label={formField.country_of_tax_residency.label} data={COUNTRIES} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BooleanRadio name={formField.tin_ask.name} label={formField.tin_ask.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                                    </Grid>
                                    {
                                        formValues.tin_ask
                                            ? <Grid item xs={12} sm={12}>
                                                <InputField required name={formField.taxpayer_identification_number.name} label={formField.taxpayer_identification_number.label} fullWidth />
                                            </Grid>
                                            : <Grid item xs={12}>
                                                <SelectField required name={formField.taxpayer_identification_number_exemption_reason.name} label={formField.taxpayer_identification_number_exemption_reason.label} data={TIN_REASON_OPTIONS} fullWidth />
                                            </Grid>
                                    }
                                </React.Fragment>
                        }
                    </React.Fragment>
                )
            case TAX_STATUS.FINANCIAL_INSTITUTION:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <BooleanRadio name={formField.gin_asked.name} label={formField.gin_asked.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                        </Grid>
                        {
                            formValues.gin_asked
                                ? <Grid item xs={12} sm={12}>
                                    <InputField required name={formField.global_intermediary_identification_number.name} label={formField.global_intermediary_identification_number.label} fullWidth />
                                </Grid>
                                : <React.Fragment>
                                    <Grid item xs={12}>
                                        <SelectField required name={formField.fatca_status.name} label={formField.fatca_status.label} data={FATCA_STATUS_OPTIONS} fullWidth />
                                    </Grid>
                                    {
                                        formValues.fatca_status?.value === FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION
                                            ? <Grid item xs={12}>
                                                <InputField name={formField.trustee_global_intermediary_identification_number.name} label={formField.trustee_global_intermediary_identification_number.label} helperText={'Note: If the Trust is a Trustee-Documented Trust, please provide the Trustee\'s GIIN'} fullWidth />
                                            </Grid>
                                            : <React.Fragment />
                                    }
                                    {
                                        formValues.fatca_status?.value === FATCA_STATUS.OTHER
                                            ? <Grid item xs={12}>
                                                <InputField required name={formField.fatca_status_other.name} label={formField.fatca_status_other.label} fullWidth />
                                            </Grid>
                                            : <React.Fragment />
                                    }
                                </React.Fragment>
                        }
                        <Grid item xs={12}>
                            <BooleanRadio
                                tooltip={<Typography component='span' variant='body2'>CRS Participating Jurisdictions are on the OECD website at <Link target='_blank' href='http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/crs-by-jurisdiction'>http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/crs-by-jurisdiction</Link></Typography>}
                                name={formField.is_fi_non_participarting_crs_managed_by_other_fi.name}
                                label={formField.is_fi_non_participarting_crs_managed_by_other_fi.label}
                                data={AUSTRALIAN_TAX_OPTIONS}
                                fullWidth />
                        </Grid>
                        {
                            formValues.is_fi_non_participarting_crs_managed_by_other_fi
                                ? <React.Fragment>
                                    <Grid item xs={12}>
                                        <BooleanRadio
                                            tooltip={<div>
                                                <Typography component='span' variant='body2'>A Beneficial Owner is any individuals that directly or indirectly control the Trust. Control includes control by acting as Trustee; or by means of Trusts, agreements, arrangements, understandings and practices; or exercising control through the capacity to direct the Trustee; or the ability to appoint or remove the Trustees</Typography>
                                                <br /><br />
                                                <Typography component='span' variant='body2'>A Controlling Person is any individual who directly or indirectly exercises control over the Trust. For a Trust, this includes all Trustees, Settlors, Protectors or Beneficiaries. For a Trustee company this includes any beneficial owners controlling more than 25% of the shares in the company or Senior Managing Officials.</Typography>
                                                <br /><br />
                                                <Typography component='span' variant='body2'>Tax Residency rules differ by country. Whether an individual is tax resident of a particular country is often (but not always) based on the amount of time a person spends in a country, the location of a person’s residence or place of work. For the US, tax residency can be as a result of citizenship or residency.</Typography>
                                                <br /><br />
                                            </div>}
                                            name={formField.is_controlling_person_foreign_tax_resident.name}
                                            label={formField.is_controlling_person_foreign_tax_resident.label}
                                            data={AUSTRALIAN_TAX_OPTIONS}
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BooleanRadio name={formField.tax_resident_of_australia.name} label={formField.tax_resident_of_australia.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                                    </Grid>
                                    {
                                        !formValues.tax_resident_of_australia
                                            ? <React.Fragment />
                                            : <React.Fragment>
                                                <Grid item xs={12}>
                                                    <AutocompleteField required name={formField.country_of_tax_residency.name} label={formField.country_of_tax_residency.label} data={COUNTRIES} fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <BooleanRadio name={formField.tin_ask.name} label={formField.tin_ask.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                                                </Grid>
                                                {
                                                    formValues.tin_ask
                                                        ? <Grid item xs={12} sm={12}>
                                                            <InputField required name={formField.taxpayer_identification_number.name} label={formField.taxpayer_identification_number.label} fullWidth />
                                                        </Grid>
                                                        : <Grid item xs={12}>
                                                            <SelectField required name={formField.taxpayer_identification_number_exemption_reason.name} label={formField.taxpayer_identification_number_exemption_reason.label} data={TIN_REASON_OPTIONS} fullWidth />
                                                        </Grid>
                                                }
                                            </React.Fragment>
                                    }
                                </React.Fragment>
                                : <React.Fragment>
                                </React.Fragment>
                        }
                    </React.Fragment>
                )
            case TAX_STATUS.OTHER:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <BooleanRadio name={formField.is_controlling_person_foreign_tax_resident.name} label={formField.is_controlling_person_foreign_tax_resident.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <BooleanRadio name={formField.tax_resident_of_australia.name} label={formField.tax_resident_of_australia.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                        </Grid>
                        {
                            !formValues.tax_resident_of_australia
                                ? <React.Fragment />
                                : <React.Fragment>
                                    <Grid item xs={12}>
                                        <AutocompleteField required name={formField.country_of_tax_residency.name} label={formField.country_of_tax_residency.label} data={COUNTRIES} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BooleanRadio name={formField.tin_ask.name} label={formField.tin_ask.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                                    </Grid>
                                    {
                                        formValues.tin_ask
                                            ? <Grid item xs={12} sm={12}>
                                                <InputField required name={formField.taxpayer_identification_number.name} label={formField.taxpayer_identification_number.label} fullWidth />
                                            </Grid>
                                            : <Grid item xs={12}>
                                                <SelectField required name={formField.taxpayer_identification_number_exemption_reason.name} label={formField.taxpayer_identification_number_exemption_reason.label} data={TIN_REASON_OPTIONS} fullWidth />
                                            </Grid>
                                    }
                                </React.Fragment>
                        }
                    </React.Fragment>
                )
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <HeadText>Trust Details</HeadText>
            </Grid>
            <Grid item xs={12}>
                <InputField required name={formField.trust_name.name} label={formField.trust_name.label} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectField required name={formField.trust_type.name} label={formField.trust_type.label} data={TRUST_TYPE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputField name={formField.trust_abn.name} label={formField.trust_abn.label} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <HelpLabel label='In which country was the trust established?'
                    tooltip={'Sorry, we only accept applications for trusts established in Australia.'}
                />
                <AutocompleteField
                    disabled={true}
                    required
                    name={formField.trust_country_of_establishment.name}
                    label={formField.trust_country_of_establishment.label}
                    data={COUNTRIES}
                    fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.trust_asset_source_details.name} label={formField.trust_asset_source_details.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <SelectField
                    required
                    name={formField.trust_activity.name}
                    label={formField.trust_activity.label}
                    data={TRUST_ACTIVITY_OPTIONS}
                    fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.trust_description.name} label={formField.trust_description.label} fullWidth />
            </Grid>
            <Grid item xs={12} className='addressContainer'>
                {
                    formValues.trust_address_manual_address
                        ? <React.Fragment />
                        : <AutocompleteFieldAsync
                            required
                            name={formField.trust_address_full_address.name}
                            label={formField.trust_address_full_address.label}
                            fullWidth />
                }
                <CheckboxField name={formField.trust_address_manual_address.name} label="Cannot find your address? Manual Input" />
            </Grid>
            {
                formValues.trust_address_manual_address
                    ? <React.Fragment>
                        <Grid item xs={6} sm={6}>
                            <InputField name={formField.trust_address_unit_flat_number.name} label={formField.trust_address_unit_flat_number.label} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <InputField required name={formField.trust_address_street_number.name} label={formField.trust_address_street_number.label} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <InputField required name={formField.trust_address_street_name.name} label={formField.trust_address_street_name.label} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <SelectField required name={formField.trust_address_street_type.name} label={formField.trust_address_street_type.label} data={STREET_TYPE} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <InputField required name={formField.trust_address_city_suburb.name} label={formField.trust_address_city_suburb.label} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <SelectField required name={formField.trust_address_state.name} label={formField.trust_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <InputField required name={formField.trust_address_postcode.name} label={formField.trust_address_postcode.label} fullWidth />
                        </Grid>
                        <Grid item xs={6} sm={6}></Grid>
                    </React.Fragment>
                    : <React.Fragment>
                    </React.Fragment>
            }
            <Grid item xs={12}>
                <BooleanRadio name={formField.beneficiaries_membership_of_a_class.name} label={formField.beneficiaries_membership_of_a_class.label} data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
            </Grid>
            {
                formValues.beneficiaries_membership_of_a_class
                    ? <Grid item xs={12} sm={12}>
                        <InputField required name={formField.membership_class_details.name} label={formField.membership_class_details.label} fullWidth />
                    </Grid>
                    : <React.Fragment />
            }
            <Grid item xs={12}>
                <HeadText>Tax Information</HeadText>
            </Grid>
            <Grid item xs={12}>
                <BooleanRadio
                    tooltip={<Typography component='span' variant='body2'>Some people or organisations are exempt from quoting a TFN in relation to their investments. For details to see if this applies to you, <Link target='_blank' href='https://www.ato.gov.au/print-publications/tfn-and-abn-information-for-savings-accounts-and-investments/?page=8'>see the ATO website.</Link></Typography>}
                    name={formField.trust_tax_exemption.name}
                    label={formField.trust_tax_exemption.label}
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                />
            </Grid>
            {
                formValues.trust_tax_exemption
                    ? <Grid item xs={12}>
                        <SelectField required name={formField.trust_tax_exemption_details.name} label={formField.trust_tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                    </Grid>
                    : <Grid item xs={12}>
                        <InputField
                            name={formField.trust_tfn.name}
                            label={formField.trust_tfn.label}
                            helperText={"We ask for your TFN (Tax File Number) so we can make sure you are charged tax at the correct rate. You don't have to provide your TFN, but if you do, it can help ensure you don't pay too much tax."}
                            fullWidth />
                    </Grid>
            }
            <Grid item xs={12}>
                <HelpLabel label='Tax Status'
                    tooltip={
                        <div>
                            <Typography component='span' variant='body2'><span style={{ fontWeight: 'bold' }}>• ACTIVE NON-FINANCIAL ENTITY (NFE)</span> - Active NFEs include entities where, during the previous reporting period, less than 50% of their gross income was passive income (e.g. dividends, interests and royalties) and less than 50% of assets held produced passive income.</Typography>
                            <br />
                            <Typography component='span' variant='body2'><span style={{ fontWeight: 'bold' }}>• FINANCIAL INSTITUTION</span> - A custodial or depository institution, an investment entity or a specified insurance company for FATCA/CRS purposes e.g. a bank or insurance company.</Typography>
                            <br />
                            <Typography component='span' variant='body2' ><span style={{ fontWeight: 'bold' }} >• AUSTRALIAN REGISTERED CHARITY</span> - A company that is an Australian Registered Charity.</Typography>
                            <br />
                            <Typography component='span' variant='body2'><span style={{ fontWeight: 'bold' }}>• OTHER</span> - Entities that are not previously listed e.g. Passive Non-Financial entities.</Typography>
                        </div>
                    }
                />
                <SelectField required name={formField.tax_status.name} label={formField.tax_status.label} data={TAX_STATUS_OPTIONS} fullWidth />
            </Grid>
            {renderTaxInformation()}
        </Grid>
    );
}
