import React from 'react'
import {
    Chip
} from '@mui/material';


const ChipAccountStatus = ({ ...props }) => {
    const styles = {
        backgroundColor: {
            'IN KYC': '#FCEDBE',
            'REJECTED': '#FCBEBE',
            'PENDING KYC APPROVAL': '#FCEDBE',
            'PENDING BANK ACCOUNT INFO': '#CCC4E0',
            'PENDING BANK TPA': '#CCC4E0',
            'PENDING APPLICATION SUBMIT': '#FCEDBE',
            'IN PENDING DEVELOPMENT': '#FCEDBE',
            'IN REFERRED': '#FCEDBE',
            'IN DEVELOPMENT': '#FCEDBE',
            'PENDING MANUAL STEPS': '#FCEDBE',
            'IN REVIEW': '#FCEDBE',
            'PENDING APPROVAL': '#FCEDBE',
            'PENDING AUTO STEPS': '#FCEDBE',
            'COMPLETED': '#D2E6C8',
            'CANCELLED': '#FCBEBE'
        }
    }
    return (
        <Chip style={{ backgroundColor: styles.backgroundColor[props.value] }} label={props.value || '--'} />
    )
}

export default ChipAccountStatus
