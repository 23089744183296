import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import dataStorage, { initialStorage } from 'src/dataStorage';

const NavItem = ({
  href,
  icon: Icon,
  title,
  onClick,
  isParent,
  open,
  ...rest
}) => {
  const navigate = useNavigate()
  const location = useLocation();

  const active = href ? !!matchPath({
    path: href,
    end: true
  }, location.pathname) : false;

  const onMenuClick = () => {
    if (onClick) onClick()
    else {
      if (title === 'New Application') {
        Object.assign(dataStorage, initialStorage)
      }
      document.body.className = title
      navigate(href)
    }
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Button
        onClick={onMenuClick}
        sx={{
          backgroundColor: active && 'rgba(255,255,255, 0.08)',
          borderRadius: 1,
          color: active ? 'secondary.main' : 'neutral.300',
          fontWeight: active && 'fontWeightBold',
          justifyContent: 'space-between',
          px: 3,
          mx: 2,
          mb: 1,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400'
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)'
          },
          '& span': {
            marginLeft: 1
          }
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItem: 'center' }}>
          {Icon}
          <span>
            {title}
          </span>
        </div>
        {isParent ? open ? <ExpandMore /> : <ChevronRight /> : null}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string
};

export default NavItem;
