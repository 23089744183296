import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { DashboardNavbar } from './DashboardNavbar';
import { DashboardSidebar } from './DashboardSidebar';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Footer } from './Footer'

const DashboardLayoutRoot = styled('div')(({ theme }) => {
  return ({
    display: 'flex',
    minHeight: '100vh',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    }
  })
});

export const DashboardLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Outlet />
          <Footer />
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => {
        setSidebarOpen(true)
      }} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  );
};
