import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

export default function DatePickerField(props) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;
    const { setValue, setTouched } = helper;
    const isError = touched && error && true;
    const { value } = field;
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (value) {
            const date = new Date(value);
            setSelectedDate(date);
        } else {
            setSelectedDate(null);
        }
    }, [value]);

    function _onChange(date) {
        if (date == null) return setValue('');
        if (date) {
            setSelectedDate(date);
            try {
                const ISODateString = date.toISOString();
                setValue(ISODateString);
            } catch (error) {
                setValue(date);
            }
        } else {
            setValue(date);
        }
    }

    const _onClose = () => {
        setTouched(field.name, true)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                {...field}
                {...props}
                autoOk={true}
                // disableToolbar
                defaultCalendarMonth={props.maxDate || props.minDate}
                ampm={false}
                variant="inline"
                inputVariant="outlined"
                value={selectedDate}
                onChange={_onChange}
                onClose={_onClose}
                renderInput={(params) => <TextField
                    {...field}
                    {...props}
                    {...params}
                    error={isError}
                    helperText={isError && error}
                    fullWidth />}
            />
        </LocalizationProvider>
    );
}
