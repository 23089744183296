import dataStorage from 'src/dataStorage'
import {
    getData,
    postData,
    putData,
    deleteData,
    getSessionUrl,
    getAuthUrl,
    getRefreshUrl,
    getDecodeUrl,
    getOpeningAccountUrl,
    getBusinessInfoUrl,
    getPerformSearchUrl,
    getMetaDataAddressUrl
} from 'src/api/api'
import CryptoJS from 'react-native-crypto-js';
import moment from 'moment'
import 'moment-timezone';
import {
    DEFAULT_APPLICANT,
    ACCOUNT_TYPE,
    ACCOUNT_TYPE_PUT_DRAFT,
    ACCOUNT_STATUS,
    GENDER_OPTIONS,
    TITLE_OPTIONS,
    BSB_OPTION,
    LIST_OF_PIDS,
    CMA_SOURCE_OF_FUNDS_OPTIONS,
    CMA_ACCOUNT_PURPOSE_OPTIONS,
    SOURCE_OF_WEALTH_OPTIONS,
    GOVERNMENT_ID_TYPE_OPTIONS,
    STATE_OF_ISSUE_OPTIONS,
    MEDICARE_CARD_COLOUR_OPTIONS,
    MEDICARE_CARD_COLOUR,
    CMA,
    GENDER_BY_TITLE
} from 'src/constants'
import { aspectFile } from 'src/constants'
import at from 'lodash/at'
import logger from 'src/utils/logger'
import { ENVIROMENT_BACK } from 'src/api/api'
export function getGender(title) {
    return GENDER_BY_TITLE[title] || 'OTHER'
}

export function setLang(lang) { }
export function setTheme(theme) { }
export function setFontSize(size) { }

export function checkKycVerify(status) {
    const stateSuccessfully = ['EKYC_VERIFIED', 'EKYC_VERIFIED_ADMINS', 'EKYC_VERIFIED_WITH_CHANGES'];
    return stateSuccessfully.includes(status)
}

export function scrollToTop() {
    const root = document.getElementById('topMark')
    root && root.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
}

export function isBroker() {
    return dataStorage.userType === 1
}

export function isMorrison() {
    return dataStorage.env === 'morrison' || dataStorage.env === 'equix'
}

export function isOtherApplicant() {
    return dataStorage.isSubApplicant
}

export function checkSubmitted() {
    const applicant = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex]
    const isSubmitted = applicant && (applicant.applicant_agreement)
    if (dataStorage.isOperatorSupport) {
        return (dataStorage.accountStatus && dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC) || isSubmitted
    }
    return dataStorage.accountStatus || isSubmitted
}

export function getFullAddress(source = {}, prefix = '') {
    if (source[`${prefix}_manual_address`]) {
        const formatUnitNumber = source[`${prefix}_unit_flat_number`] ? source[`${prefix}_unit_flat_number`] + '/' : ''
        return formatUnitNumber +
            source[`${prefix}_street_number`] + ' ' +
            source[`${prefix}_street_name`] + ' ' +
            source[`${prefix}_street_type`].label + ' ' +
            source[`${prefix}_city_suburb`] + ' ' +
            source[`${prefix}_state`].label + ' ' +
            source[`${prefix}_postcode`]
    } else {
        return source[`${prefix}_full_address`] && source[`${prefix}_full_address`].full_address
    }
}

export function getEnv() {
    if (dataStorage.enviroment === 'PROD') return 'PROD'
    return 'DEV'
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, typeAspectFile) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    switch (typeAspectFile) {
        // case aspectFile.Image: {
        //     canvas.width = 720;
        //     canvas.height = 300;
        //     break
        // }
        // case aspectFile.Icon: {
        //     canvas.width = 56;
        //     canvas.height = 56;
        //     break
        // }
        default: {
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;
            break
        }
    }

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL("image/jpeg");
    return canvas;
}

export const generateDownload = async (imageSrc, crop, getFileCropped, aspectImage) => {
    if (!crop || !imageSrc) return

    const canvas = await getCroppedImg(imageSrc, crop, 0, aspectImage);

    canvas.toBlob(
        (blob) => {
            getFileCropped(blob)
        },
        "image/jpeg",
        0.66
    );
};

export function getCurrentAestDate(time) {
    const d = new Date()
    const timezone = 10
    const utc = d.getTime() - (d.getTimezoneOffset() * 60000)
    // Current +10 (AEST) time
    const aest = new Date(utc + (3600000 * -timezone))
    // Current +11 (ADST) time
    const adst = new Date(utc + (3600000 * -(timezone + 1)))
    // Set to start of the month DST starts (October)
    // and time of rollover (0200)
    const dstStart = new Date(aest.getFullYear(), (10 - 1), 1, 2, 0, 0, 0)
    // Set to start of the month DST ends (April)
    // and time of rollover (0300)
    const dstEnd = new Date(aest.getFullYear(), (4 - 1), 1, 3, 0, 0, 0);

    // Now, calculate the position of the first Sunday of the start and end month,
    // which is when DST actually starts, or ends.
    // One of 1001 great uses for mod!
    dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7));
    dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDate()) % 7));

    // Now we simply check if the current date is between the two regions
    // If ADST is after the end datetime and AEST is before the start datetime,
    // then DST is not in effect. You have to think about this one a little bit...
    // it matters which clock you check, otherwise you might have incorrect 1 hour
    // edge cases around the start/end datetimes.
    // An alternative and perhaps easier way to calculate all this would be to just
    // work everything out against UTC and do away with the need for two Dates.
    // 11:15:03 23/Nov/2021 Australian Eastern Standard Time
    if ((adst.getTime() > dstEnd.getTime()) && (aest.getTime() < dstStart.getTime())) {
        // No DST!
        return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Standard Time`
    }
    // Yes, DST!
    return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Daylight Time`
}

export function mapManualAddress(dist, source, prefix, srcPrefix) {
    dist[`${prefix}_country`] = 'AUSTRALIA'
    const formatUnitNumber = source[`${srcPrefix || prefix}_unit_flat_number`] ? (source[`${srcPrefix || prefix}_unit_flat_number`] + '/') : ''
    const streetType = source[`${srcPrefix || prefix}_street_type`].label || source[`${srcPrefix || prefix}_street_type`]
    const state = source[`${srcPrefix || prefix}_state`].label || source[`${srcPrefix || prefix}_state`]
    // Set full address
    dist[`${prefix}_full_address`] = formatUnitNumber +
        source[`${srcPrefix || prefix}_street_number`] + ' ' +
        source[`${srcPrefix || prefix}_street_name`] + ' ' +
        streetType + ' ' +
        source[`${srcPrefix || prefix}_city_suburb`] + ' ' +
        state + ' ' + source[`${srcPrefix || prefix}_postcode`]
    // Set address line 1
    dist[`${prefix}_address_line_1`] = formatUnitNumber +
        source[`${srcPrefix || prefix}_street_number`] + ' ' +
        source[`${srcPrefix || prefix}_street_name`] + ' ' +
        streetType + ' '
    source[`${srcPrefix || prefix}_street_number`] && (dist[`${prefix}_street_number`] = source[`${srcPrefix || prefix}_street_number`])
    source[`${srcPrefix || prefix}_street_name`] && (dist[`${prefix}_street_name`] = source[`${srcPrefix || prefix}_street_name`])
    streetType && (dist[`${prefix}_street_type`] = streetType)
    source[`${srcPrefix || prefix}_city_suburb`] && (dist[`${prefix}_city_suburb`] = source[`${srcPrefix || prefix}_city_suburb`])
    source[`${srcPrefix || prefix}_postcode`] && (dist[`${prefix}_postcode`] = source[`${srcPrefix || prefix}_postcode`])
    state && (dist[`${prefix}_state`] = state)
    source[`${srcPrefix || prefix}_unit_flat_number`] && (dist[`${prefix}_unit_flat_number`] = source[`${srcPrefix || prefix}_unit_flat_number`])
}

export function mapDataAddress(dist, id, prefix) {
    const source = dataStorage.addressData[id]
    if (!source) return
    dist[`${prefix}_country`] = 'AUSTRALIA'
    source?.unit_flat_number && (dist[`${prefix}_unit_flat_number`] = source.unit_flat_number)
    source?.full_address && (dist[`${prefix}_full_address`] = source.full_address)
    source?.street_number && (dist[`${prefix}_street_number`] = source.street_number)
    source?.street_name && (dist[`${prefix}_street_name`] = source.street_name)
    source?.street_type && (dist[`${prefix}_street_type`] = source.street_type)
    source?.city_suburb && (dist[`${prefix}_city_suburb`] = source.city_suburb)
    source?.postcode && (dist[`${prefix}_postcode`] = source.postcode)
    source?.state && (dist[`${prefix}_state`] = source.state)
    source?.address_line_1 && (dist[`${prefix}_address_line_1`] = source.address_line_1)
    source?.address_line_2 && (dist[`${prefix}_address_line_2`] = source.address_line_2)
}

export function getFirstField(obj = {}, path = '') {
    const firstField = Object.keys(obj)[0]
    if (firstField && typeof obj[firstField] === 'object') return getFirstField(obj[firstField], firstField)
    else return `${path ? path + '.' : ''}${firstField}`
}

export function formatNumber(number, decimal = 2) {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(number);
}

export function addEventListenerWindow() {
    window.onbeforeunload = (ev) => {
        ev.preventDefault();
        ev.returnValue = '';
        return ''
    }
}

export function getWhiteLabel() {
    const dataEnv = dataStorage.env;
    // console.log(dataEnv, 'test1')
    if (!dataEnv) return '';
    switch (dataEnv) {
        case 'dev1': return 'EQUIX'
        case 'morrison': return 'Morrison Securities'
        case 'equitystorytrader': return 'Equity Story Trader'
        case 'ricard': return 'Shares.exchange'
        case 'mps': return 'MPS'
        case 'optixtrading': return 'Optix Trading'
        case 'tradeforgood': return 'Trade For Good'
        default: return 'EQUIX'
    }
}

export const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: 24 * 60 * 60 * 1000 * 365 / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
}
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

export function getRelativeTime(d1, d2 = new Date()) {
    const elapsed = d1 - d2

    // "Math.abs" accounts for both "past" & "future" scenarios
    for (const u in units) {
        if (Math.abs(elapsed) > units[u] || u === 'second') {
            return rtf.format(Math.round(elapsed / units[u]), u)
        }
    }
}

export function setBranding() {
    const icon = document.querySelector("link[rel*='icon']")
    const favIcon = dataStorage.config?.favicon
    icon && (icon.href = favIcon)
}

export function capitalizeFirstLetter(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizer(string = '') {
    if (typeof string !== 'string') return string // dom in dropdown
    const list = string.toLowerCase().split(' ').map(e => e && capitalizeFirstLetter(e))
    return list.join(' ')
}

export function clone(any) {
    try {
        return any ? JSON.parse(JSON.stringify(any)) : any;
    } catch (error) {
        return any
    }
}

export function booleaner(str) {
    if (typeof str === 'boolean') return str
    return !!(str === 'true')
}

export function getMobilePhoneValue(field, prefix) {
    field = (field + '').replace(/\+/g, '');
    return `au|${prefix || ''}${field}`
}

export function makeCapitalizeFunc() {
    // eslint-disable-next-line no-extend-native
    String.prototype.toCapitalize = function () {
        return this.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ')
    }
}

export function getListOrganizationCode(obj) {
    return Object.keys(obj).map(e => ({ label: (e + '').toCapitalize(), value: e }))
}

export function getListBrokerScheduleCode(arr) {
    return arr.map(e => ({ label: `${e.Schedule_Code} - ${e.Schedule_Description}`, value: e.Schedule_Code }))
}

export function getListVettingRule(arr) {
    return arr.map(e => ({ label: e.branch_name, value: e.branch_id }))
}

export function getListBranchCode(obj = {}, organizationCode) {
    if (!obj[organizationCode]) return []
    return (obj[organizationCode].branch_code || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

export function getListAdvisorCode(obj = {}, organizationCode, branchCode) {
    if (!obj[organizationCode] || !branchCode) return []
    const dicBranchCode = obj[organizationCode].branch_code.reduce((acc, cur) => {
        acc[cur.code] = cur.advisor_code
        return acc
    }, {})
    return (dicBranchCode[branchCode] || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

function autoGenNameDraft(obj = {}, index) {
    let { formData: { applicant_details: applicantDetails, account_type: accountType, rootBusiness } } = obj
    if (!applicantDetails || applicantDetails.length === 0) return accountType
    if (index) applicantDetails = [applicantDetails[index]]
    const names = []
    applicantDetails.forEach((e) => names.push(renderFullname(e)))
    return names.join(', ')
}

function getApplicantId(obj) {
    if (dataStorage.applicantId) return dataStorage.applicantId
    const { formData, index = 0 } = obj
    return formData?.applicant_details?.[index]?.applicant_id
}

export function removeVietnamese(str) {
    // Gộp nhiều dấu space thành 1 space
    str = str.replace(/\s+/g, ' ');
    // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
    str = str.trim();
    // bắt đầu xóa dấu tiếng việt  trong chuỗi
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
}

export function createDraftOtherApplicant(equixId, applicantId, applicantEmail, data) {
    data.updated = +new Date()
    const url = getOpeningAccountUrl(`/draft?equix_id=${equixId}&applicant_id=${applicantId}&applicant_email=${applicantEmail}`)
    const sendData = {
        data,
        name: autoGenNameDraft(data, data.index)
    }
    return postData(url, sendData)
}

export function getFullName(data) {
    const title = data.title?.label ? data.title.label + ' ' : ''
    return `${title}${data.first_name} ${data.last_name}`
}

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

export async function getAddressData(listId = []) {
    const lstEncrypted = []
    listId.forEach(id => {
        if (!dataStorage.addressData[id]) {
            lstEncrypted.push(encodeURIComponent(CryptoJS.AES.encrypt(id, dataStorage.addressKey).toString()))
        }
    })
    if (!lstEncrypted.length) {
        return
    }
    const metaUrl = getMetaDataAddressUrl(lstEncrypted.join(','));
    const [res, err] = await getData(metaUrl)
    if (!err) {
        if (res && res.length) {
            res.forEach(item => dataStorage.addressData[item.id] = item);
        }
    }
    return
}

export async function getDraftOfRetailByOperator(draftId, email) {
    const [res, err] = await getData(getOpeningAccountUrl(`/retail-draft?draft_id=${draftId}&email=${email}`))
    if (err) {
        logger.error(err, `getDraft retail error ${error}`)
        dataStorage.goToApp && dataStorage.goToApp()
        return [res, err]
    } else {
        dataStorage.listDraft = [{ ...res, id: draftId }]
        dataStorage.dicDraft = dataStorage.listDraft[0]
        dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
        if ([ACCOUNT_TYPE.TRUST, ACCOUNT_TYPE.COMPANY, ACCOUNT_TYPE.FUND].includes(dataStorage.accountType)) {
            await getAccountStatus(dataStorage.equixId)
            return [res, err]
        }
        const applicants = dataStorage.dicDraft?.data?.formData?.applicant_details
        applicants && applicants.forEach(async (e, i) => {
            if (e.applicant_id === dataStorage.applicantId) {
                dataStorage.activeIndex = i || 0
                dataStorage.indexApplicant = i
                if (i > 0) dataStorage.isSubApplicant = true
                if (checkKycVerify(e.ekyc_overall_status)) {
                    dataStorage.isSubmitted = true
                }
                await getAccountStatus(dataStorage.equixId)
                return [res, err]
            }
        })
        return [res, err]
    }
}

export async function getAccountStatus(equixId) {
    if (!equixId) return
    const [res, err] = await getData(getOpeningAccountUrl(`?equix_id=${equixId}`))
    if (err) {
        logger.log(err, 'get account status error')
    } else {
        dataStorage.accountStatus = res[0]?.account_status
        dataStorage.morrisonStatus = res[0]?.morrison_status
    }
}

export async function getDraft(path, draftId) {
    const finish = () => {
        dataStorage.goToApp && dataStorage.goToApp()
        delete dataStorage.goToApp
        return
    }
    const [res, err] = await getData(getOpeningAccountUrl(`/draft${path || ''}`))
    if (err) {
        logger.error(err, `getDraft error ${error}`)
        dataStorage.goToApp && dataStorage.goToApp()
        return
    } else {
        if (res.name) {
            dataStorage.listDraft = [{ ...res, id: draftId }]
        } else {
            if (res.data) {
                const { data, ...pagination } = res
                dataStorage.paginateDraft = pagination
                dataStorage.listDraft = Object.keys(data).map(k => ({ id: k, ...data[k] }))
            } else {
                dataStorage.listDraft = Object.keys(res).map(k => ({ id: k, ...res[k] }))
            }
        }
        if (dataStorage.listDraft.length === 1) {
            dataStorage.dicDraft = dataStorage.listDraft[0]
            dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0
            dataStorage.equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            dataStorage.applicantId = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex].applicant_id
            if (dataStorage.activeIndex > 0) dataStorage.isSubApplicant = true
            else dataStorage.isSubApplicant = false
            dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
            const equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            if (equixId && !dataStorage.activeIndex) {
                await getAccountStatus(equixId)
            } else {
                dataStorage.accountStatus = null
            }
            finish()
        } else {
            finish()
        }
    }
}

export async function saveDraft(obj, cb) {
    obj.updated = +new Date()
    const { id, name, ...rest } = obj
    const requestFunc = id ? putData : postData
    let url = getOpeningAccountUrl('/draft')
    if (id) {
        const applicantId = getApplicantId(obj)
        if (dataStorage.isOperatorSupport && applicantId) {
            url = getOpeningAccountUrl(`/retail-draft?draft_id=${id}&email=${dataStorage.registerEmail}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
        } else {
            if (isOtherApplicant() && applicantId) {
                url = getOpeningAccountUrl(`/draft?draft_id=${id}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
            } else {
                url = getOpeningAccountUrl(`/draft?draft_id=${id}`)
            }
        }
        // let dataFromData = {};
        // const getFormData = rest?.formData
        // if (!ACCOUNT_TYPE_PUT_DRAFT?.[getFormData.account_type]) return;
        // // delete key have characters mailing_address api put Data.
        // for (let x in getFormData) {
        //     if (!(x?.includes('mailing_address') && x != null)) {
        //         dataFromData = { ...dataFromData, [x]: getFormData[x] }
        //     }
        // }
        // rest.formData = dataFromData
        // // **
    } else if (dataStorage.isSubApplicant) {
        url = getOpeningAccountUrl(`/draft?equix_id=${dataStorage.equixId}`)
    }
    const sendData = {
        name: autoGenNameDraft(rest, dataStorage.isSubApplicant && !dataStorage.isOperatorSupport ? obj.index : null),
        data: rest
    }
    const [res, err] = await requestFunc(url, sendData)
    if (err) {
        logger.error(err, `saveDraft error ${error}`)
    } else {
        if (!id) dataStorage.listDraft.push({ ...sendData, id: res.draft_id })
        if (res.draft_id) {
            dataStorage.dicDraft = {
                ...sendData,
                id: res.draft_id
            }
        }
        cb && cb()
        console.log('saveDraft success')
    }
}

export function getFilterObj(tableState, listFilter) {
    const isSortNone = tableState.sortOrder?.direction === 'none'
    const sortObj = {
        sortField: isSortNone ? '' : tableState.sortOrder?.name,
        sortType: isSortNone ? '' : tableState.sortOrder?.direction?.toUpperCase()
    }
    const filterArray = tableState.filterList.reduce((acc, cur, index) => {
        if (cur.length) {
            const name = listFilter[index]
            const obj = cur[0]
            if (obj.from || obj.to) {
                acc.push({
                    name: obj.field,
                    from: +moment(obj.from).startOf('day'),
                    to: +moment(obj.to).endOf('day')
                })
            } else {
                acc.push({
                    name,
                    value: (obj + '').toLowerCase(),
                    opt: 'contains'
                })
            }
        }
        return acc
    }, [])
    const res = {}
    if (filterArray.length) res.filter = filterArray
    if (Object.keys(tableState.sortOrder).length) res.sort = sortObj
    return res
}


export async function deleteApplication(equixId, cb) {
    const url = getOpeningAccountUrl(`/joint/${equixId}`)
    const [response, error] = await deleteData(url)
    if (error) {
        dataStorage.showAlert?.({ type: 'error', message: error })
    } else {
        cb && cb()
        dataStorage.showAlert?.({ type: 'success', message: 'Delete application successfully' })
    }
}
export async function rejectApplication(equixId, cb) {
    const url = getOpeningAccountUrl(`/operator/reject?equix_id=${equixId}&environment=${ENVIROMENT_BACK[dataStorage.env]}&token=${dataStorage.accessToken}`)
    const [response, error] = await getData(url)
    if (error) {
        dataStorage.showAlert?.({ type: 'error', message: error })
    } else {
        cb && cb()
        dataStorage.showAlert?.({ type: 'success', message: 'Reject application successfully' })
    }
}

export async function kycApplication(equixId, type, cb) {
    const url = getOpeningAccountUrl(`/${type}/${equixId}`)
    const [response, error] = await postData(url)
    if (error) {
        dataStorage.showAlert?.({ type: 'error', message: error })
    } else {
        dataStorage.accountStatus = response.account_status
        dataStorage.updateAccountStatus && dataStorage.updateAccountStatus(response.account_status)
        cb && cb(response.account_status)
        dataStorage.showAlert?.({ type: 'success', message: 'KYC application successfully' })
    }
}


export async function moveAplicationToDraft(equixId, cb, account_status = '') {
    const { IN_KYC, PENDING_KYC_APPROVAL, PENDING_APPLICATION_SUBMIT } = ACCOUNT_STATUS;
    let url;
    if (IN_KYC == account_status) {
        url = getOpeningAccountUrl(`/move-to-draft?equix_id=${equixId}&token=${dataStorage.accessToken}`)
    }
    if ([PENDING_KYC_APPROVAL, PENDING_APPLICATION_SUBMIT].includes(account_status)) {
        url = getOpeningAccountUrl(`/operator/reject?equix_id=${equixId}&environment=${ENVIROMENT_BACK[dataStorage.env]}&token=${dataStorage.accessToken}`)
    }
    const [response, error] = await getData(url)
    if (error) {
        dataStorage.showAlert?.({ type: 'error', message: error })
    } else {
        cb && cb()
        dataStorage.showAlert?.({ type: 'success', message: 'Move to draft successfully' })
    }
}

export async function approveAplication(equixId, accountStatus) {
    const url = getOpeningAccountUrl(`/operator/approve?equix_id=${equixId}&account_status=${accountStatus}&environment=${ENVIROMENT_BACK[dataStorage.env]}&token=${dataStorage.accessToken}`)
    const [response, error] = await getData(url)
    if (error) {
        dataStorage.showAlert?.({ type: 'error', message: error })
    } else {
        dataStorage.showAlert?.({ type: 'success', message: 'Approve application successfully' })
    }
}

export function checkShow(listDepend, formValues) {
    if (typeof listDepend === 'object' && Object.keys(listDepend).length) {
        const keys = Object.keys(listDepend)
        for (let index = 0; index < keys.length; index++) {
            const depend = at(formValues, keys[index])[0] ?? {}
            const value = depend.value || depend
            const refs = listDepend[keys[index]]
            if (Array.isArray(refs)) {
                if (!refs.includes(value)) return false
            } else {
                if (value !== refs) return false
            }
        }
    }
    return true
}

export function getABN(value) {
    const number = (value + '').match(/\d/g)?.join('') || ''
    return number
}

export const clearTrashFieldAndMapData = (obj = {}, path = '', formValues, formField, skipFields) => {
    try {
        Object.keys(obj).forEach(field => {
            const pathField = `${path ? path + '.' : ''}${field}`
            let item = obj[field]
            if (typeof item === 'string') {
                obj[field] = obj[field].trim()
                item = item.trim()
            }
            if (![undefined, null, ''].includes(item)) {
                if (Array.isArray(item)) {
                    item.forEach((e, i) => {
                        clearTrashFieldAndMapData(e, `${pathField}[${i}]`, e, formField, skipFields)
                    })
                } else {
                    if (skipFields && skipFields.includes(field)) return
                    if (['super_fund_abn', 'company_abn', 'trust_abn'].includes(field)) {
                        obj[field] = getABN(item)
                        item = getABN(item)
                    }
                    if (typeof item === 'object' && Object.keys(item).length && (!Object.prototype.hasOwnProperty.call(item, 'label') && !Object.prototype.hasOwnProperty.call(item, 'value') && !Object.prototype.hasOwnProperty.call(item, 'full_address'))) return clearTrashFieldAndMapData(item, pathField, formValues, formField, skipFields)
                    if (typeof item === 'object' && Object.keys(item).length && Object.prototype.hasOwnProperty.call(item, 'label') && Object.prototype.hasOwnProperty.call(item, 'value')) {
                        item = item.value
                        obj[field] = item
                    }
                    if ([undefined, null, ''].includes(item)) {
                        delete obj[field]
                    } else {
                        const fieldModel = at(formField, pathField.replace(/\[\d]/, ''))[0] ?? {}
                        if (fieldModel && fieldModel.dependentShow) {
                            if (!checkShow(fieldModel.dependentShow, formValues)) {
                                delete obj[field]
                            }
                        }
                    }
                }
            } else delete obj[field]
        })
    } catch (error) {
        logger.error('clearTrashFieldAndMapData error ', error)
    }
}
export const renderApplicants = (applicant_details = []) => {
    try {
        const getListApplicats = applicant_details.map((e, i) => {
            const name = e.first_name && e.last_name ? renderFullname(e) : '';
            return { label: name || `Applicant ${i + 1}`, value: e.applicant_id }
        })
        return [...getListApplicats, { label: 'Other', value: 'OTHER' }]
    } catch (err) {
        return [{ label: 'Other', value: 'OTHER' }]
    }
}
export function isMyApplicant(index) {
    return isBroker() || (dataStorage.isSubApplicant || index === 0)
}
export const getPickAddress = (formValues = {}) => {
    let result = ''
    if (formValues.pick_address_director) {
        const pickValue = formValues.pick_fund_address?.value
        if (pickValue === 'company_registered_office_address') {
            result = getFullAddress(formValues, 'company_registered_office_address')
        } else if (pickValue === 'company_principal_place_of_business_address') {
            result = getFullAddress(formValues, 'company_principal_place_of_business_address')
        } else {
            const pickApplicant = formValues.applicant_details.find(e => e.applicant_id === pickValue)
            result = getFullAddress(pickApplicant, 'residential_address')
        }
    } else {
        result = getFullAddress(formValues, 'super_fund_address')
    }
    return result
}
export const renderFullname = (Applicants = {}) => {
    const { title = {}, first_name: firstName, middle_name: middleName, last_name: lastName } = Applicants;
    const titleText = (title.label || title) ? (title.label || title) + ' ' : ''
    return middleName ? `${titleText}${firstName} ${middleName} ${lastName}` : `${titleText}${firstName} ${lastName}`
}
export const formatDate = (value, format = 'DD/MM/YYYY') => {
    return moment(value).format(format);
}
