import React from 'react';
import { Box, CardMedia, Grid, Typography, Link, Button, CircularProgress } from '@mui/material';
import dataStorage from 'src/dataStorage';
import { ACCOUNT_STATUS } from 'src/constants'
import { makeStyles } from '@mui/styles';
import { getWhiteLabel } from 'src/utils/functionUtils';
import DownloadApp from 'src/components/DownloadApp'
import ImageReview from 'src/components/ImageReview'
const useStyles = makeStyles(theme => ({
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  }
}))

export const ACCOUNT_STATUS_DISPLAY = {
  [ACCOUNT_STATUS.IN_KYC]: {
    status: 'KYC IN PROGRESS',
    title: 'Your application #ApplicationID is under review.',
    description: <Typography variant="subtitle1" align="left">
      <br /><br />
      Please contact&nbsp;
      <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
        {`${dataStorage.config.supportEmail}`}
      </Link> if you need support and provide your User login and Application ID.
    </Typography>
  },
  [ACCOUNT_STATUS.REJECTED]: 'REJECTED',
  [ACCOUNT_STATUS.PENDING_KYC_APPROVAL]: 'PENDING KYC APPROVAL',
  [ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO]: 'PENDING BANK ACCOUNT INFO',
  [ACCOUNT_STATUS.PENDING_BANK_TPA]: 'PENDING BANK TPA',
  [ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT]: 'PENDING APPLICATION SUBMIT',
  [ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT]: 'IN PENDING DEVELOPMENT',
  [ACCOUNT_STATUS.IN_REFERRED]: 'IN REFERRED',
  [ACCOUNT_STATUS.IN_DEVELOPMENT]: 'IN DEVELOPMENT',
  [ACCOUNT_STATUS.PENDING_MANUAL_STEPS]: 'PENDING MANUAL STEPS',
  [ACCOUNT_STATUS.IN_REVIEW]: 'IN REVIEW',
  [ACCOUNT_STATUS.PENDING_APPROVAL]: 'PENDING APPROVAL',
  [ACCOUNT_STATUS.PENDING_AUTO_STEPS]: 'PENDING AUTO STEPS',
  [ACCOUNT_STATUS.COMPLETED]: 'COMPLETED',
  [ACCOUNT_STATUS.CANCELLED]: 'CANCELLED'
}

function ApplicationSuccess({ backChooseDraft }) {
  const classes = useStyles()
  const backDraftList = () => {
    backChooseDraft()
  }
  const { equix_id: equixId } = dataStorage.applicantInfo
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <ImageReview />
        <Grid item xs={12} sm={8}>
          <Typography component="h1" variant="h5" align="left" style={{ fontWeight: 'bold' }}>
            Your Individual Account application is being processed...
          </Typography>
          <Typography component="h1" variant="body1" align="left">
            #{equixId || dataStorage.equixId}
          </Typography>
          <Typography variant="subtitle1" align="left">
            <br />
            We have received and are processing your information. You can access the {getWhiteLabel()} App now while we are creating your trading account.
            <br /><br />
            Please check your email for a Welcome email with instructions to download and use our state-of-the-art HTML5 and mobile apps.
            <br /><br />
            <DownloadApp />
            For any questions, reach out to us at&nbsp;
            <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
              {`${dataStorage.config.supportEmail}`}
            </Link> anytime!
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={backDraftList}>Back to Application List</Button>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationSuccess;
