import React from 'react';
import { TextField, CircularProgress, ClickAwayListener, Autocomplete, } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { at } from 'lodash';
import { useField, FieldArray } from 'formik';
import {
  getSearchUserUrl, getData, getSearchSubscriberUrl,
  postData
} from 'src/api/api'
import CheckIcon from '@mui/icons-material/Check'
import logger from 'src/utils/logger'
const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    '& .MuiAutocomplete-inputRoot': {
      padding: 0
    }
  }
}))

export default function SearchUserField(props) {
  const classes = useStyles()
  const { label, data, name, readOnly, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setValue: setValueForm } = helper;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const timeoutId = React.useRef(0)

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [])

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const getListSuggestUser = async (text = '') => {
    if (text.length < 2) return
    setLoading(true)
    const url = getSearchSubscriberUrl(1, 50, text?.toLowerCase())
    //(text + '').toLowerCase()
    const [response, error] = await getData(url)
    if (error) {
      setLoading(false)
      logger.error(error, 'getListSuggestUser error')
    } else {
      setOptions(response?.data || [])
      setLoading(false)
    }
  }
  const checkExistEmail = React.useCallback((email) => {
    if (!email) return false
    return Boolean(meta.value.find(e => {
      if (e?.user_login_id) return e.user_login_id === email
      if (e?.email) return e.email === email

    }));
  }, [meta.value])

  function onChangeText(e) {
    if (!open && !readOnly) setOpen(true)
    const text = e.target.value || ''
    timeoutId.current && clearTimeout(timeoutId.current)
    timeoutId.current = window.setTimeout(() => getListSuggestUser(text), 500)
  }

  function _onChange(event, option, reason) {
    if (reason === 'selectOption') {
      const email = option[option.length - 1]?.email;
      if (checkExistEmail(email)) return;
      setValueForm && setValueForm(option)
    } else if (reason === 'removeOption') {
      setValueForm && setValueForm(option)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Autocomplete
          // className={classes.container}
          {...field}
          {...rest}
          multiple
          onChange={_onChange}
          disableClearable
          open={open}
          onOpen={() => {
            readOnly || setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          fullWidth
          filterOptions={x => x}
          getOptionLabel={(option) => option?.email || option?.user_login_id || ''}
          renderOption={(props, option, state) => {
            return (
              <div {...props} style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '8px 16px', cursor: 'pointer' }}>
                {option.email}
                {checkExistEmail(option.email) ? <CheckIcon style={{ fill: 'green' }} /> : <React.Fragment />}
              </div>
            );
          }}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              {...rest}

              variant="outlined"
              error={touched && error && true}
              helperText={_renderHelperText()}
              onChange={onChangeText}
              label={label}
              type="search"
              fullWidth
              InputProps={{
                ...params.InputProps,
                readOnly: readOnly,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </div>
    </ClickAwayListener>
  );
}
