/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles'
import {
  Typography,
  Theme,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import EditNotification from './EditNotification'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: '86vw',
    // background: 'red',
    outline: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: 24,
    right: 24
  }
}))

const EditNotificationModal = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  useImperativeHandle(ref, () => ({
    showModal: showModal
  }), [])

  const showModal = () => setOpen(true)

  const handleModalToggle = () => {
    setOpen(old => !old)
  };

  return (
    <Modal
      open={open}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className={classes.modalContainer}>
        {/* <IconButton
                    onClick={handleModalToggle}
                    className={classes.closeIcon}
                >
                    <CloseIcon />
                </IconButton> */}
        <EditNotification handleModalToggle={handleModalToggle} infoNoti={props.infoNoti} />
      </div>
    </Modal>
  );
})

export default EditNotificationModal