import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogin: false
};

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginState(state, actions) {
            state.isLogin = true
        }
    }
})
const { actions, reducer } = auth
export const { setLoginState } = actions;
export default reducer;

