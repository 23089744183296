import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles'
import {
    Typography,
    Theme,
    Grid,
    CircularProgress,
    Button,
    Modal,
    Chip,
    IconButton
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import {
    InputField,
    SearchUserField
} from '../CustomFields'
import Yup from 'src/utils/customYup'
import { setAlert } from 'src/redux/alert/alert'
import {
    putData,
    deleteData,
    getCreateNewSegmentUrl,
    getDeleteSegmentUrl,
    getUpdateSegmentUrl
} from 'src/api/api'
import dataStorage from 'src/dataStorage'
import {
    updateSegment,
    deleteSegment
} from 'src/redux/segment/segment'
import ConfirmModal from '../ConfirmModal'
import { useFormikContext, FieldArray, FormikContextType } from 'formik';
import CloseIcon from '@mui/icons-material/Close'
import equal from 'deep-equal'
const initial = {
    segment_id: '',
    name: '',
    subscribers: []
}
const validationSchema = Yup.object().shape({
    name: Yup.string().checkRequired('Segment Name is required'),
    subscribers: Yup.array().checkRequired('Must have at least one subscriber in segment')
})

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: 600,
        background: theme.palette.background.default,
        padding: theme.spacing(2),
        borderRadius: 10,
        boxShadow: theme.shadows[1],
        outline: 0,
        // marginTop: '23%',
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -150px)',
        width: '100%',
        maxWidth: 533
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        '& button ~ button': {
            marginLeft: theme.spacing(2)
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '-1px',
        right: 0
    }, labelUploadFile: {
        color: '#65748B',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.4375em',
        fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
        transformOrigin: 'top left',
        whiteSpace: 'nowrap',
        '-webkit-transform': 'translate(0, -1.5px) scale(0.75)'
    },
    styleButtonClose: {
        background: "#B75D69",
        '&:hover': {
            background: '#9D4358'
        }
    }
}))

const EditSegmentModal = forwardRef((props, ref) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [checkReviewModal, setCheckReviewModal] = React.useState(false);
    const initialValues = React.useRef(initial)
    const rawValues = React.useRef()
    const [editMode, setEditMode] = React.useState(true)
    const confirmModalRef = React.useRef();
    const confirmModalClearRef = React.useRef();
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }), [])

    const showModal = (data, editMode) => {
        // console.log
        rawValues.current = {
            segment_id: data.segment_id,
            name: data.name,
            subscribers: data.subscribers.map(e => ({ user_id: e.user_id, email: e.user_login_id }))
        }
        initialValues.current = {
            ...initial,
            ...rawValues.current
        }
        if (typeof editMode === 'boolean') setEditMode(editMode)
        setOpen(true)
    }

    const handleModalToggle = () => {
        setOpen(old => !old)
        props.setcheckModal('')
    };
    const onSubmit = async (values, actions) => {
        setLoading(true)
        // console.log(values.subscribers)
        const subscribers = values.subscribers.map(e => e.subscriber_id || e.user_id)
        const [response, error] = await putData(getUpdateSegmentUrl(values.segment_id), {
            name: values.name,
            subscribers
        })
        if (error) {
            dataStorage.showAlert?.({ message: error, type: 'error' })
            setLoading(false)
        } else {
            rawValues.current = {
                segment_id: values.segment_id,
                name: values.name,
                subscribers: values.subscribers
            }
            const newSegment = {
                name: values.name,
                segment_id: values.segment_id,
                total_subscribers: subscribers.length,
                last_updated: +new Date(),
                actor: dataStorage.userInfo.user_login_id
            }
            dispatch(updateSegment(newSegment))
            dataStorage.showAlert?.({ type: 'success', message: 'The segment information has been updated successfully!' })
            props.checkModal == 2 && handleModalToggle()
            setLoading(false)
            setEditMode(false)
            // handleModalToggle()
        }
    };

    const onDeleteSegment = async (segmentId) => {
        const [response, error] = deleteData(getDeleteSegmentUrl(segmentId))
        if (error) {
            dataStorage.showAlert?.({ type: 'error', message: error })
        } else {
            dispatch(deleteSegment(segmentId))
            dataStorage.showAlert?.({ type: 'success', message: `Delete segment ${segmentId} successfully` })
            handleModalToggle()
        }
    }
    const clearNoti = (resetForm, values) => {
        resetForm({ values: { ...initial, segment_id: values.segment_id } })
    }
    const clickClear = (resetForm, values) => {
        confirmModalClearRef?.current?.showModal?.(() => clearNoti(resetForm, values), {
            title: 'Confirm clear',
            content: 'Are you sure you want to clear data?'
        })
    }
    const navigateModalReview = (resetForm, values) => () => {
        props.checkModal == 2 && handleModalToggle()
        resetForm && resetForm({ values: { ...values, ...rawValues.current } })
        setEditMode(false)
    }

    const renderButtons = (resetForm, values, setValues) => {
        if (!editMode) {
            return (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained" color="primary"
                        onClick={(e) => {
                            e.preventDefault()
                            setEditMode(true)
                            setCheckReviewModal(true)
                        }}
                        disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Edit'}
                    </Button>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => {
                            confirmModalRef?.current?.showModal?.(() => onDeleteSegment(values.segment_id))
                        }}
                        className={classes.styleButtonClose}
                    >Delete</Button>
                    <Button
                        variant="contained" color="secondary"
                        onClick={handleModalToggle}
                    >Close</Button>
                </div>
            )
        }
        return (
            <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" type="submit" disabled={loading || equal(initialValues.current, values)}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
                <Button
                    variant="contained" color="secondary"
                    onClick={() => { clickClear(resetForm, values) }}
                    className={classes.styleButtonClose}
                >Clear</Button>
                <Button
                    variant="contained" color="secondary"
                    onClick={navigateModalReview(resetForm, values)}
                >Close</Button>
            </div>
        )
    }
    const searchEmail = (values) => {
        return <>
            <Grid item xs={12} md={12} >
                <p className={classes.labelUploadFile}>Subscribers</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {values?.subscribers?.length ?
                        <FieldArray name="subscribers" >
                            {fieldArrayProps => {
                                const { form } = fieldArrayProps;
                                return form?.values?.subscribers?.map((current, index) => {
                                    return <>
                                        <div key={index} style={{ marginBottom: 5 }}>
                                            <Chip label={current?.email || current?.user_id || ''} />


                                            <span style={{ marginRight: 5 }}></span>
                                        </div>
                                    </>
                                })
                            }}
                        </FieldArray> : null
                    }
                </div>
            </Grid>
        </>
    }
    const formView = (values) => {
        setCheckReviewModal(true);

        return (<>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                    <InputField inputProps={{ readOnly: true }} name={'segment_id'} variant="standard" label={'Segment Id'} fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputField inputProps={{ readOnly: true }} name={'name'} variant="standard" label={'Segment Name'} fullWidth />
                </Grid>
                {searchEmail(values)}
            </Grid></>)
    }
    const formEdit = (chipProps) => {
        // setCheckReviewModal(false)
        return (<>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                    <InputField inputProps={{ readOnly: true }} variant="standard" name={'segment_id'} label={'Segment Id'} fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputField name={'name'} label={'Segment Name'} fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                    <SearchUserField
                        {...chipProps}

                        name={'subscribers'}
                        label={'Subscribers'}
                        fullWidth />
                </Grid>
            </Grid>
        </>)
    }
    const renderForm = () => {
        const chipProps = editMode ? {} : {
            ChipProps: {
                clickable: false,
                onDelete: null
            }
        }
        return (
            <Formik
                initialValues={initialValues.current}
                validationSchema={validationSchema}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ resetForm, values, ...formik }) => {
                    // console.log(values)
                    return (
                        <Form id={props.formId} autoComplete='off'>
                            <IconButton
                                onClick={!editMode ? handleModalToggle : navigateModalReview(resetForm, values)}
                                className={classes.closeIcon}
                            >
                                <CloseIcon />
                            </IconButton>
                            {!editMode ? formView(values) :
                                formEdit(chipProps)}
                            {renderButtons(resetForm, values)}
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={handleModalToggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <>
                    <div className={classes.modalContainer}>
                        <Typography id='top' component="h1" variant="h6" align="center">
                            {editMode ? 'Edit Segment' : 'Preview Segment'}
                        </Typography>
                        <div style={{ height: 16 }} />
                        {renderForm()}
                    </div>
                </>
            </Modal>
            <ConfirmModal ref={confirmModalRef} />
            <ConfirmModal ref={confirmModalClearRef} textButton={'Clear'} />

        </React.Fragment >
    );
})

export default EditSegmentModal