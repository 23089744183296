import React from 'react';
import { Box, CardMedia, Grid, Typography, Link, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageReview from 'src/components/ImageReview'

const useStyles = makeStyles(theme => ({
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  },
  resendEmail: {
    marginTop: 5
  },
  buttonProgress: {
    position: 'absolute'
  }
}))

function ApplicationDeleted({ backChooseDraft }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <ImageReview />
        <Grid item xs={12} sm={8}>
          <Typography component="h1" variant="h5" align="left">
            <Box fontWeight="fontWeightBold">Application Deleted</Box>
          </Typography>
          <Typography variant="subtitle1" align="left">
            <br /><br />
            Your application has been withdrawn. If you think this was a mistake, please contact&nbsp;
            <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
              {`${dataStorage.config.supportEmail}`}
            </Link> for support.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={backChooseDraft}>Back to Application List</Button>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationDeleted;
