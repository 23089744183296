import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';

export default function InputField({ ...props }) {
  const { errorText, helperText, name, typeNumber, typeNumberPrice, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setFieldError, setFieldValue } = useFormikContext();
  const [label, setlableField] = React.useState(rest.label)
  function _renderHelperText() {
    if (touched && error) {
      return error;
    } else if (helperText) {
      return helperText
    }
  }
  React.useEffect(() => {
    (field?.value?.length && rest.ext) && setlableField(rest.ext)
  }, [])
  const onChange = (event) => {
    const getValueField = event.target.value
    if (typeNumberPrice == "number") {
      const getVlaue = (getValueField).replaceAll('-', '');
      const getLengthCharacters = (getVlaue.match(/\./g) || []).length;
      if (getLengthCharacters > 1) return
      const validateNumber = /^[\d.]{0,}$/;

      if ((getVlaue.match(/\./g) || []).length) {
        const findIndexString = getVlaue?.indexOf('.');
        const checkInvalid = getVlaue.slice(findIndexString + 1, getVlaue.length).length;
        if (checkInvalid > 4) return;
      }
      if (!validateNumber.test(getVlaue)) return;
      setFieldValue(field.name, getVlaue)
      return
    }
    if (typeNumber == "number") {
      const getVlaue = (getValueField).replaceAll('-', '');
      const validateNumber = /^[\d]{0,}$/;
      if (!validateNumber.test(getVlaue)) return;
      setFieldValue(field.name, getVlaue)
      return
    }
    setFieldValue(field.name, getValueField)
    // if (rest.type === 'email' && !!error) { // remove old error
    //   setFieldError(field.name, '')
    // }
  }
  const onFocus = (event) => {
    rest.ext && setlableField(rest.ext)
  }
  const onBlur = (event) => {
    const getValueField = event.target.value;
    helper.setTouched(true)
    setFieldValue(field.name, getValueField, true)
    if (field?.value?.length) {
      rest.ext && setlableField(rest.ext)
    } else {
      rest.ext && setlableField(`${rest.label}`)
    }
  }
  return (
    <TextField
      variant="outlined"
      type="text"
      error={Boolean(touched && error && true)}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      label={rest?.ext ? label : rest.label}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={field.value || ''}
    />
  );
}
