import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
    SelectField,
    AutocompleteField
} from 'src/components/CustomFields';
import { useFormikContext } from 'formik';
import { getData, getBrokerDetailUrl, getVettingRuleUrl } from 'src/api/api'
import {
    getListOrganizationCode,
    getListBrokerScheduleCode,
    getListVettingRule,
    getListBranchCode,
    getListAdvisorCode
} from 'src/utils/functionUtils'
import dataStorage from 'src/dataStorage';
import formModel from '../FormModels/formModels';
import logger from 'src/utils/logger'
const { formField } = formModel

export default function BrokerData(props) {
    const { setFieldValue, disabled } = props;
    const { values: formValues } = useFormikContext();
    const listOrganizationCode = React.useRef([])
    const listBranchCode = React.useRef([])
    const listAdvisorCode = React.useRef([])
    const listBrokerageSchedule = React.useRef([])
    const listVettingRule = React.useRef([])
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const createPromise = async (url) => {
        const [res, err] = await getData(url)
        if (err) {
            logger.error(err, `createPromise ${url} error`)
            return []
        } else {
            return res.data || res || []
        }
    }

    const setDataOption = React.useCallback(() => {
        let update = true
        if (formValues.organization_code?.value) {
            listBranchCode.current = getListBranchCode(dataStorage.brokerData?.listOrganizationCode, formValues.organization_code.value)
            if (formValues.branch_code?.value) {
                listAdvisorCode.current = getListAdvisorCode(dataStorage.brokerData?.listOrganizationCode, formValues.organization_code.value, formValues.branch_code.value)
            }
            if (formValues.branch_code?.value && listBranchCode.current.length) {
                const option = listBranchCode.current.find(e => e.value === formValues.branch_code.value)
                if (!option) {
                    setFieldValue(formField.branch_code.name, null)
                    setFieldValue(formField.advisor_code.name, null)
                    update = false
                } else {
                    if (formValues.advisor_code?.value) {
                        const option = listAdvisorCode.current.find(e => e.value === formValues.advisor_code.value)
                        if (!option) {
                            setFieldValue(formField.advisor_code.name, null)
                            update = false
                        }
                    }
                }
            }
            update && forceUpdate()
        }
    }, [forceUpdate, formField.advisor_code.name, formField.branch_code.name, formValues.advisor_code, formValues.branch_code, formValues.organization_code, setFieldValue])

    React.useEffect(() => {
        setDataOption()
    }, [forceUpdate, formValues.organization_code, formValues.branch_code, formValues.advisor_code, setFieldValue, formField.branch_code.name, formField.advisor_code.name, setDataOption])

    React.useEffect(() => {
        let isMount = true

        const setOptions = () => {
            if (dataStorage.brokerData && dataStorage.brokerData.listBrokerageSchedule) listBrokerageSchedule.current = getListBrokerScheduleCode(dataStorage.brokerData.listBrokerageSchedule)
            if (dataStorage.brokerData && dataStorage.brokerData.listOrganizationCode) listOrganizationCode.current = getListOrganizationCode(dataStorage.brokerData.listOrganizationCode)
            if (dataStorage.brokerData && dataStorage.brokerData.listVettingRule) listVettingRule.current = getListVettingRule(dataStorage.brokerData.listVettingRule)
        }
        const getBrokerDetail = () => {
            if (!isMount) return
            const listPromise = []
            const brokerUrl = getBrokerDetailUrl()
            const vettingUrl = getVettingRuleUrl()
            listPromise.push(createPromise(brokerUrl), createPromise(vettingUrl))
            if (listPromise.length) {
                Promise.all(listPromise).then(res => {
                    if (res[0] && res[1]) {
                        dataStorage.brokerData = {
                            listBrokerageSchedule: res[0].Brokerage_Schedule_List,
                            listVettingRule: res[1].branch,
                            listOrganizationCode: res[0].Organization_Matrix
                        }
                    }
                    setOptions()
                    setDataOption()
                    forceUpdate()
                })
            }
        }
        if (!dataStorage.brokerData) {
            getBrokerDetail();
        } else {
            setOptions()
            forceUpdate()
        }
        return () => {
            isMount = false
        }
    }, [])

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="button" gutterBottom>
                    BROKER DETAILS
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SelectField disabled={disabled} required name={formField.organization_code.name} label={formField.organization_code.label} data={listOrganizationCode.current} fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SelectField disabled={disabled} required name={formField.branch_code.name} label={formField.branch_code.label} data={listBranchCode.current} fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SelectField disabled={disabled} required name={formField.advisor_code.name} label={formField.advisor_code.label} data={listAdvisorCode.current} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectField disabled={disabled} required name={formField.branch.name} label={formField.branch.label} data={listVettingRule.current} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <AutocompleteField disabled={disabled} required name={formField.tradeable_products.equity.name} label={formField.tradeable_products.equity.label} data={listBrokerageSchedule.current} fullWidth />
            </Grid>
        </React.Fragment>
    )
}
