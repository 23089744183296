import React from 'react'
import {
  Chip
} from '@mui/material';


const ChipSegment = ({ ...props }) => {
  const [state, setstate] = React.useState(3)
  const expandArray = (data) => {
    if (state === 3) return setstate(data.length)
    setstate(3)
  }
  return (
    <>
      {props?.value?.slice(0, state)?.map((segment, i) => {
        return <Chip key={`chip_segment_${i}`} label={segment} size='small' style={{ margin: 4, display: 'table' }} />
      })}
      {props?.value?.length > 3 ?
        <a style={{ marginLeft: 6, cursor: 'pointer' }} className="showMoreSub" onClick={() => expandArray(props.value)}>{state === 3 ? 'Show More' : 'Hide'}</a> : null
      }

    </>
  )
}

export default ChipSegment
