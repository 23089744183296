import React from 'react';
import { Link, Typography } from '@mui/material';
import dataStorage from 'src/dataStorage';
import MailIcon from '@mui/icons-material/Mail'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    appFooter: {
        padding: theme.spacing(3),
        [theme.breakpoints.down(600)]: {
            padding: theme.spacing(2)
        },
        backgroundColor: 'transparent'
    }
}));


export const Footer = ({ color }) => {
    const classes = useStyles();

    return (
        <div className={classes.appFooter} align="center">
            <Typography
                variant="subtitle2"
                align="center"
                component='span'
                style={{ fontWeight: 'bold', color }}
            >
                Customer support:&nbsp;&nbsp;&nbsp;
                {<MailIcon style={{ fill: color, marginBottom: -2, fontSize: 16 }} />}
                <Link color="secondary" target="_blank" href={`mailto:${dataStorage.config?.supportEmail}`} style={{ fontWeight: 400 }}>
                    &nbsp;{dataStorage.config?.supportEmail}
                </Link>
            </Typography>
            <div style={{ height: 8 }} />
            <Typography
                variant="body2"
                align="center"
                style={{ color }}
            >
                {dataStorage.config?.footer}
            </Typography>
            <Typography
                variant="body2"
                align="center"
                style={{ color }}
            >
                {dataStorage.config?.footerAddition}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 5, cursor: 'pinter' }} onClick={() => {
                window.open('https://www.novus-fintech.com/', '_blank')
            }}>
                <span style={{ color: color || 'inherit' }} className={'styleTextFooter'}>Powered by</span>
                <img style={{ height: 20, marginLeft: 8 }} src={color === 'white' ? '/images/NovusFintechDark.svg' : '/images/NovusFintechLight.svg'} />
            </div>
        </div>
    );
}
