import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles'
import {
    Typography,
    Theme,
    Grid,
    CircularProgress,
    Button,
    Modal,
    IconButton
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import {
    InputField,
    SearchUserField
} from '../CustomFields'
import { setAlert } from 'src/redux/alert/alert'
import {
    postData,
    getCreateNewSegmentUrl
} from 'src/api/api'
import dataStorage from 'src/dataStorage'
import { addSegment } from 'src/redux/segment/segment'
import ConfirmModal from '../ConfirmModal'
import Yup from 'src/utils/customYup'
import CloseIcon from '@mui/icons-material/Close'

const initialValues = {
    name: '',
    subscribers: []
}
const validationSchema = Yup.object().shape({
    name: Yup.string().checkRequired('Segment Name is required'),
    subscribers: Yup.array().checkRequired('Must have at least one subscriber in segment'),
})

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        background: theme.palette.background.default,
        padding: theme.spacing(2),
        borderRadius: 10,
        boxShadow: theme.shadows[1],
        width: '100%',
        maxWidth: 533
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        '& button ~ button': {
            marginLeft: theme.spacing(2)
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '-1px',
        right: 0
    }
}))

// eslint-disable-next-line react/display-name
const CreateSegmentModal = forwardRef((props, ref) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const confirmModalRef = React.useRef();
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }), [])

    const showModal = () => setOpen(true)

    const handleModalToggle = () => {
        setOpen(old => !old)
    };
    const cbConfirmSubmit = async (values, action) => {
        setLoading(true)
        const subscribers = values.subscribers.map(e => e.subscriber_id)
        const [response, error] = await postData(getCreateNewSegmentUrl(), {
            name: values.name,
            subscribers
        })
        if (error) {
            dataStorage.showAlert?.({ message: error, type: 'error' })
            setLoading(false)
        } else {
            const newSegment = {
                name: values.name,
                segment_id: response.segment_id,
                total_subscribers: subscribers.length,
                last_updated: +new Date(),
                actor: dataStorage.userInfo.user_login_id
            }
            dispatch(addSegment(newSegment))
            dataStorage.showAlert?.({ message: `Create new segment ${values.name} successfully`, type: 'success' })
            setLoading(false)
            handleModalToggle()
        }
    }
    const confirmSubmit = (values, actions) => {
        confirmModalRef?.current?.showModal?.(() => cbConfirmSubmit(values, actions), {
            title: 'Confirm create',
            content: 'Are you sure you want to create this segment?'
        })
    }
    const onSubmit = async (values, actions) => {
        confirmSubmit(values, actions)
    };

    const renderButtons = () => {
        return (
            <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Create'}
                </Button>
                <Button
                    variant="contained" color="secondary"
                    onClick={handleModalToggle}
                >Close</Button>
            </div>
        )
    }

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={onSubmit}
            >
                {() => (
                    <Form id={props.formId} autoComplete='off'>
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={12}>
                                <InputField name={'name'} label={'Segment Name'} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <SearchUserField name={'subscribers'} label={'Subscribers'} fullWidth />
                            </Grid>
                        </Grid>
                        {renderButtons()}
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Modal
            open={open}
            onClose={handleModalToggle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        // className={classes.styleModal}
        >
            <div className={classes.modalContainer}>
                <Typography id='top' component="h1" variant="h6" align="center">
                    NEW SEGMENT
                </Typography>
                <div style={{ height: 16 }} />
                {renderForm()}
                <IconButton
                    onClick={handleModalToggle}
                    className={classes.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
                <ConfirmModal title={' '} textCancel={'Close'} content={'Are you sure you want to continue?'} ref={confirmModalRef} textButton={'create'} />
            </div>

        </Modal>
    );
})

export default CreateSegmentModal