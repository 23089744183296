import React from 'react';
import { useFormikContext } from 'formik';
import FullApplicationDetailsReview from './FullApplicationDetailsReview';
import FullMainApplicantOther from './fullMainApplicantOther';
import { isOtherApplicant } from 'src/utils/functionUtils';

export default function ReviewApplication() {
  const { values: formValues } = useFormikContext()

  return (
    <React.Fragment>
      {isOtherApplicant() ? <FullMainApplicantOther formValues={formValues} /> : <FullApplicationDetailsReview formValues={formValues} />}
    </React.Fragment>
  );
}
