import React from 'react';
import { TextField, CircularProgress, Button, ClickAwayListener, Autocomplete } from '@mui/material';
import { at } from 'lodash';
import { useField } from 'formik';
import { getSearchSegmentUrl, getData } from 'src/api/api'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import logger from 'src/utils/logger'
export default function AutocompleteFieldAsyncSegment(props) {
  const { label, data, isValid, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setValue: setValueForm } = helper;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const timeoutId = React.useRef()
  const isValidRef = React.useRef(props.isValid)

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [])

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const getListSuggestAddress = async (text = '') => {
    if (text.length < 2) return
    setLoading(true)
    const url = getSearchSegmentUrl(text)
    // const data = { query: { bool: { must: [{ query_string: { query: `*${text.toLowerCase()}*` } }] } }, sort: [] }
    const [res, err] = await getData(url)
    if (err) {
      setLoading(false)
      logger.error(err, 'getListSuggestAddress error: ')
    } else {
      if (res?.data) setOptions(res?.data || [])
      setLoading(false)
    }
  }

  function onChangeText(e) {
    if (!open) setOpen(true)
    const text = e.target.value.trim() || ''
    timeoutId.current && clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => getListSuggestAddress(text), 500)
  }

  function _onChange(event, option, reason) {
    if (reason === 'selectOption') {
      isValidRef.current = true
      option.isValid = true
      setValueForm && setValueForm(option)
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Autocomplete
          style={{ flex: 1 }}
          {...field}
          {...rest}
          onChange={_onChange}
          disableClearable
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={x => x}
          getOptionLabel={(option) => option?.name || ''}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              {...rest}
              variant="outlined"
              error={touched && error && true}
              helperText={_renderHelperText()}
              onChange={onChangeText}
              label={label}
              type="search"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        {
          Object.prototype.hasOwnProperty.call(props, 'isValid')
            ? (isValidRef.current
              ? null
              : <Button
                style={{ marginLeft: 16 }}
                onClick={() => onChangeText({ target: { value: field.value?.full_address || '' } })}
                variant="outlined"
              >
                <ChevronLeft />
                {'Confirm this address'}
              </Button>)
            : null
        }
      </div>
    </ClickAwayListener>
  );
}
