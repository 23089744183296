import React from 'react';
import { Box, CardMedia, Grid, Typography, Link, Button, CircularProgress } from '@mui/material';
import dataStorage from 'src/dataStorage';
import { ACCOUNT_STATUS } from 'src/constants'
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import {
  getWhiteLabel,
  getDraft
} from 'src/utils/functionUtils';
import DownloadApp from 'src/components/DownloadApp'
import ImageReview from 'src/components/ImageReview'
const useStyles = makeStyles(theme => ({
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  },
  resendEmail: {
    marginTop: 5
  },
  buttonProgress: {
    position: 'absolute'
  }
}))

function ApplicationSuccess({ backChooseDraft }) {
  const classes = useStyles()
  const { equix_id: equixId } = dataStorage.applicantInfo
  // console.log(dataStorage.applicantInfo)
  const listApplicantOther = () => {
    const saveApplicantInfo = dataStorage?.applicantInfo?.applicant_details?.slice(1);
    // console.log(dataStorage.applicantInfo)
    return saveApplicantInfo ? saveApplicantInfo.map(currenApp => (currenApp?.first_name)).join(', ') : null
  }
  // console.log(listApplicantOther())
  const sendEmail = () => {
    return (
      <>
        <Typography component="h1" variant="h5" align="left" style={{ fontWeight: 'bold' }}>
          Your Joint Account application is being processed...
        </Typography>
        <Typography component="h1" variant="body1" align="left">
          #{equixId || dataStorage.equixId}
        </Typography>
        <Typography variant="subtitle1" align="left">
          <br /><br />
          We have received and are processing your information. You can access the {getWhiteLabel()} App now while we are creating your trading account.
          <br />
          <br />
          For any questions, reach out to us at&nbsp;
          <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
            {`${dataStorage.config.supportEmail}`}
          </Link> anytime!
        </Typography>

      </>
    )
  }
  const confirmApplicantOther = () => {
    return (<>
      <Typography component="h1" variant="h5" align="left" style={{ fontWeight: 'bold' }}>
        Your Joint Account application is being processed...
      </Typography>
      <Typography component="h1" variant="body1" align="left">
        #{equixId || dataStorage.equixId}
      </Typography>
      <Typography variant="subtitle1" align="left">
        <br /><br />
        Thank you for submitting your information. We’ll begin processing your application when all applicants have finalized their details.
        <br /><br />
        For any questions, reach out to us at&nbsp;
        <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
          {`${dataStorage.config.supportEmail}`}
        </Link> anytime!
      </Typography>
    </>)
  }
  const AllApplicationsSubmitted = () => {
    return (<>
      <Typography component="h1" variant="h5" align="left" style={{ fontWeight: 'bold' }}>
        Your Joint Account application is being processed...
      </Typography>
      <Typography component="h1" variant="body1" align="left">
        #{equixId || dataStorage.equixId}
      </Typography>
      <Typography variant="subtitle1" align="left">
        <br />
        We have received and are processing. You can use {getWhiteLabel()} App now while we are creating your trading account.
        <br /><br />
        Please contact the Primary Applicant to get login credentials to download and use our state-of-the-art HTML5 and mobile apps.
        <br /><br />
        <DownloadApp />
        For any questions, reach out to us at&nbsp;
        <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
          {`${dataStorage.config.supportEmail}`}
        </Link> anytime!
        <br /><br />
      </Typography>
    </>)
  }

  return (
    <React.Fragment>
      <Grid container spacing={3} >
        <ImageReview />
        <Grid item xs={12} sm={8} style={{ position: 'relative' }} >
          {dataStorage?.all_application_submitted ? AllApplicationsSubmitted() : dataStorage.isSubApplicant ? confirmApplicantOther() : sendEmail()}
        </Grid>
        <Grid item xs={12} sm={12} >
          <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={backChooseDraft}>Back to Application List</Button>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationSuccess;
