import React, { useCallback } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { useFormikContext, FieldArray, FormikContextType } from 'formik';
import formModels from './formModels'
import { RadioField, InputField, AutocompleteFieldAsyncSegment, DateTimePickerField, CheckboxField, AutocompleteFieldAsync, SelectField, SearchUserField } from '../CustomFields'
import { NOTIFICATION_TYPE_OPTIONS, NOTIFICATION_TYPE, DELIVERY_TYPE_OPTIONS, EXPIRE_TIME_OPTIONS, TYPE_URL, aspectFile, ORDER, DELIVERY_TYPE } from 'src/constants'
import { getPushIcontUrl, getPushLargeImagetUrl } from 'src/api/api'
import UploadField from '../CustomFields/UploadField'
import ChooseTypeUrl from './chooseTypeUrl'
import { FormHelperText, Grid, Chip, FormControl } from '@mui/material'
import dataStorage from 'src/dataStorage';

const { formField } = formModels

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        // background: 'red'
    },
    styleCheckBox: {
        '& div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 0,
            '& legend': {
                marginRight: 16,
                color: 'black',
            }
        }
    },
    spaceBox: {
        marginBottom: 14
    },
    styleCheckbox: {
        '& .MuiFormControl-root': {
            padding: 0,
            paddingTop: 8,
            '& .MuiButtonBase-root': {
                paddingBottom: 0,
            },
            '& .MuiTypography-root': {
                paddingTop: 3,
            }
        }

    },
    viewMessage: {
        position: 'absolute',
        left: 14,
        bottom: 14,
    },
    styleBoxAction: {
        '@media only screen and (max-width: 999px)': {
            padding: '0 !important',
            paddingLeft: '5px !important'
        }
    },
    styleDiliveryType: {
        '@media only screen and (max-width: 999px)': {
            paddingRight: '0 !important',
        }
    }
}));
function CreateNotificationForm(props) {
    const classes = useStyles();
    const formik = useFormikContext();
    const checkboxChanel = () => {
        return <Grid container spacing={0} style={{ borderRadius: 5, paddingBottom: 6 }}>
            <Grid item xs={12} md={3} className={classes.styleCheckbox} >
                <CheckboxField disableTextHelper={true} name={formModels.formField.mobile_push.name} label={formModels.formField.mobile_push.label} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.styleCheckbox}>
                <CheckboxField disableTextHelper={true} name={formModels.formField.desktop_push.name} label={formModels.formField.desktop_push.label} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.styleCheckbox}>
                <CheckboxField disableTextHelper={true} name={formModels.formField.email_push.name} label={formModels.formField.email_push.label} />
            </Grid>

            <Grid item xs={12} md={3} className={classes.styleCheckbox}>
                <CheckboxField disableTextHelper={true} name={formModels.formField.sms_push.name} label={formModels.formField.sms_push.label} />
            </Grid>
        </Grid>
    }

    const searchEmail = () => {
        return <>
            <Grid item xs={12} md={6}>
                <SearchUserField required={formik.values?.subsribers.length ? false : true} name={formModels.formField.subsribers.name} label={formModels.formField.email.label} fullWidth />
            </Grid>
        </>
    }
    const errorHelperChanel = () => {
        const { mobile_push, desktop_push, email_push, sms_push } = formik?.errors
        const { mobile_push: mobile_pushTouch, desktop_push: desktop_pushTouch, email_push: email_pushTouch, sms_push: sms_pushTouch } = formik.touched
        const helperError = mobile_push || desktop_push || email_push || sms_push;
        const touchedFields = mobile_pushTouch || desktop_pushTouch || email_pushTouch || sms_pushTouch;
        return (helperError && touchedFields) ? <FormHelperText error={true} style={{ margin: '0 14px' }}>{helperError}</FormHelperText> : null
    }
    const chooseTypeNoti = () => {
        return (<Grid item xs={12} md={12} style={{ margin: 0, padding: '0 5px 0 5px' }}>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, paddingTop: 0, width: '100%' }}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12} md={12} className={classes.styleCheckBox}>
                        <RadioField name={formModels.formField.notification_type.name} label={formModels.formField.notification_type.label} fullWidth data={NOTIFICATION_TYPE_OPTIONS} />
                    </Grid>
                </Grid>
                {formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ? <>
                    <Grid item xs={12} md={6} >
                        <Grid item xs={12} md={12} className={classes.styleCheckBox}>
                            <RadioField name={formModels.formField.delivery_type.name} label={formModels.formField.delivery_type.label} fullWidth data={DELIVERY_TYPE_OPTIONS} />
                        </Grid>
                    </Grid>
                </> : null}
            </Grid>
        </Grid>)
    }
    const searchField = () => {
        return <Grid item xs={12} md={12} className={classes.styleDiliveryType} style={{ margin: 0, padding: '0 5px 0 5px' }}>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, paddingTop: 0, width: '100%' }}>
                {formik.values?.notification_type?.value ?
                    <>
                        {
                            formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ?
                                searchEmail() :
                                <>
                                    <Grid item xs={12} md={6}  >
                                        <AutocompleteFieldAsyncSegment required name={formModels.formField.segment.name} label={formModels.formField.segment.label} fullWidth />
                                    </Grid>
                                </>
                        }
                    </>
                    : null}

                {formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ?
                    <Grid item xs={12} md={6} >
                        <Grid container spacing={2}  >
                            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} >
                                        <InputField name={formModels.formField.expire_number.name} label={formModels.formField.expire_number.label} typeNumber="number" fullWidth style={{ marginRight: 7 }} />
                                    </Grid>

                                    <Grid item xs={6} md={6} >
                                        <SelectField name={formModels.formField.option_type.name} label={formModels.formField.option_type.label} data={EXPIRE_TIME_OPTIONS} fullWidth />
                                    </Grid>
                                </Grid>

                            </Grid>
                            {formik.values?.delivery_type?.value !== DELIVERY_TYPE.INSTANT ?
                                <Grid item xs={12} md={6}>
                                    <DateTimePickerField
                                        name={formModels.formField.schedule_time.name}
                                        label={formModels.formField.schedule_time.label}
                                        required
                                        format="yyyy/MM/dd hh:mm a"
                                        minDate={new Date()}
                                        // maxDate={getMatchYear(18)}
                                        fullWidth
                                    />
                                </Grid> : null
                            }

                        </Grid>
                    </Grid> : null}
            </Grid>
        </Grid>
    }
    const FieldDisplayNoti = () => {
        return <Grid item xs={12} md={6} style={{ margin: 0, padding: '0 0px 0 5px' }}>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                <Grid item xs={12} md={12}>
                    {checkboxChanel()}
                    {errorHelperChanel()}
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} md={12}  >
                            <InputField required name={formModels.formField.title.name} label={formModels.formField.title.label} fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                <Grid item xs={12} md={12} >
                    <Grid item xs={12} md={12} style={{ position: 'relative' }}>
                        <InputField required name={formModels.formField.message.name} label={formModels.formField.message.label} multiline rows={4} fullWidth />
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                <Grid item xs={12} md={12} >
                    <Grid item xs={12} md={12}  >
                        <SelectField required name={formModels.formField.type_url.name} label={formModels.formField.type_url.label} fullWidth data={TYPE_URL} />
                    </Grid>
                </Grid>

            </Grid>
            <ChooseTypeUrl classes={classes} />
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6} >
                            <UploadField name={formModels.formField.icon.name} uploadReset={props.uploadIcon}
                                label={formModels.formField.icon.label}
                                spaceBox={true}
                                UrlIcon={getPushIcontUrl()}
                                createFile={true}
                                aspectImage={aspectFile.Icon}
                            />

                        </Grid>
                        <Grid item xs={6} md={6} style={formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ? {} : { display: 'none' }} >
                            <UploadField name={formModels.formField.large_image.name} uploadReset={props.uploadImage}
                                label={formModels.formField.large_image.label}
                                spaceBox={true}
                                UrlIcon={getPushLargeImagetUrl()}
                                createFile={true}
                                aspectImage={aspectFile.Image}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }
    const actions = () => {
        return <Grid item xs={12} md={6} className={classes.styleBoxAction} style={{ margin: 0, padding: '0 5px 0 0px' }}>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                {formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ?
                    <Grid item xs={12} md={12}>
                        <InputField name={formModels.formField.action1_title.name} label={formModels.formField.action1_title.label} fullWidth />
                    </Grid> : null
                }
            </Grid>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>
                {formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ?
                    <>
                        <Grid item xs={12} md={12} >
                            <Grid item xs={12} md={12}>
                                <InputField name={formModels.formField.action1_url.name} label={formModels.formField.action1_url.label} fullWidth />
                            </Grid>
                        </Grid>
                    </> : null
                }
                {/* 2 */}
            </Grid>
            <Grid container spacing={2} style={{ margin: 0, paddingRight: 0, width: '100%' }}>

                {formik.values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ?
                    <>
                        <Grid item xs={12} md={12} >
                            <Grid item xs={12} md={12} style={{ marginBottom: 13 }}>
                                <InputField name={formModels.formField.action2_title.name} label={formModels.formField.action2_title.label} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <InputField name={formModels.formField.action2_url.name} label={formModels.formField.action2_url.label} fullWidth />
                            </Grid>
                        </Grid>
                    </> : null}
                {/* 3 */}

            </Grid>
            <div style={{ padding: 5 }}></div>
        </Grid>
    }
    return (
        <Grid container spacing={3} >
            {chooseTypeNoti()}
            {searchField()}
            {FieldDisplayNoti()}
            {actions()}


        </Grid >
    );
}

export default CreateNotificationForm
