import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles'
import {
  Modal
} from '@mui/material';
import ReviewNoti from '../NewNotification/ReviewNoti'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: '86vw',
    outline: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: 24,
    right: 24
  }
}))

const PreviewNotification = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  useImperativeHandle(ref, () => ({
    showModal: showModal
  }), [])

  const showModal = () => setOpen(true)

  const handleModalToggle = () => {
    setOpen(old => !old)
  };

  return (
    <Modal
      open={open}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className={classes.modalContainer}>
        <ReviewNoti infoNoti={props.infoNoti} handleModalToggle={handleModalToggle} />

      </div>
    </Modal>
  );
})

export default PreviewNotification