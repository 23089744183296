/* eslint-disable no-debugger */
import React, { useState } from 'react';
import {
  Paper,
  Typography,
  CircularProgress, Button, IconButton, Backdrop
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Formik, Form } from 'formik';
import validationSchema from './validationSchema';
import formModels from './formModels';
import initialValues from './initialValues'
import EditNotificationForm from './EditNotificationForm'
// import { getPushDirectUrl } from '@app/api/index'
import { getPushIcontUrl, getPushLargeImagetUrl, getData, getSearchSymbolUrl, getSearchSegmentUrl, getUpdateNotificationUrl } from 'src/api/api'
import { NOTIFICATION_TYPE_OPTIONS, DELIVERY_TYPE, DELIVERY_TYPE_OPTIONS, ORDER_TYPE, Type, ORDER, NOTIFICATION_TYPE, TYPE_URL, EXPIRE_TIME_OPTIONS, STATUS_OPTIONS } from 'src/constants'
import { postData, putData } from 'src/api/api';
import { useDispatch, useSelector } from 'react-redux';
import dataStorage from 'src/dataStorage'
import ConfirmModal from 'src/components/ConfirmModal'
import { fetchNotifications } from 'src/redux/notification/notification'
import CloseIcon from '@mui/icons-material/Close'
import equal from 'deep-equal'
import logger from 'src/utils/logger'
const baseUrlEvn = {
  PROD: {
    equitystorytrader: 'https://equitystorytrader.equix.app/',
    equix: 'https://web.equix.app/',
    morrison: 'https://morrison.equix.app/',
    ricard: 'https://shares.exchange/',
    optixtrading: 'https://optixtrading.equix.app/',
    tradeforgood: 'https://tradeforgood.equix.app/'
  },
  UAT: {
    equitystorytrader: 'https://equity-story-uat.equix.app/',
    equix: 'https://uat.equix.app/',
    morrison: 'https://morrison-uat.equix.app/',
    ricard: 'https://ricard-uat.equix.app/',
    optixtrading: 'https://optixtrading-uat.equix.app/',
    tradeforgood: 'https://tradeforgood-uat.equix.app/'
  },
  DEV: {
    dev1: 'https://dev1.equix.app/'
  }
}

const { formId, formField } = formModels

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3),
    borderRadius: 10,
    // minHeight: '400px',
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 800,
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(52 41 41 / 50%)',
      borderRadius: 10
      // outline: '1px solid slategrey'
    },
    '@media screen and (max-width: 1700px)': {
      height: '92vh'
    }

  },
  content: {
    display: 'flex',
    flexDirection: "column",
    minHeight: 400
  },
  buttons: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end'
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 8
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 56,
    alignItems: 'center',
    position: 'fixed',
    width: 'calc(86vw - 56px)',
    background: theme.palette.background.paper,
    zIndex: 9,
    borderRadius: 10
  },
  styleButtonClose: {
    background: "#B75D69",
    '&:hover': {
      background: '#9D4358'
    }
  }
}))
const EditNotification = (props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false);
  const [getInitial, setInitial] = useState(null);
  const dispatch = useDispatch();
  const paginate = useSelector((state) => state.notification.paginate)
  const uploadIcon = React.useRef(null);
  const uploadImage = React.useRef(null);
  const confirmSendModalRef = React.useRef();
  const cleanModalRef = React.useRef();

  React.useEffect(() => {
    (async () => {
      await formatCode()
    })()
  }, [])
  const getSymbolArticle = async (symbolMultiple) => {
    let getListSymbol = [];
    const getArraySymbol = (symbolMultiple).split(',').map(e => {
      return e.trim().split('.')[0]
    });
    getArraySymbol.forEach((e) => getListSymbol.push(getData(getSearchSymbolUrl(e))))
    getListSymbol = await Promise.all(getListSymbol)
    return getListSymbol.map(e => e?.[0]?.[0])
  }
  const formatCode = async () => {
    try {
      setLoading(true)
      const initialForm = { ...props.infoNoti };
      const noti = props.infoNoti;
      // console.log(noti)
      initialForm.notification_type = NOTIFICATION_TYPE_OPTIONS.find(e => e.value === noti.notification_type);
      initialForm.audience = noti.audience_id;
      initialForm.delivery_type = DELIVERY_TYPE_OPTIONS.find(e => e.value == noti.delivery_type);
      initialForm.subsribers = [...noti.subscribers];
      const tranFormUrl = new URL(noti.url);
      const urlSearchParams = new URLSearchParams(tranFormUrl.search);
      const checkUrl = urlSearchParams.get('type');
      switch (checkUrl) {
        case Type.new_order: {
          const getSymbol = urlSearchParams.get('symbol');
          const url = getSearchSymbolUrl(getSymbol)
          const getSymbolApi = await getData(url);
          initialForm.symbol = getSymbolApi?.[0]?.[0]
          const orderType = ORDER_TYPE.find(e => e.value === urlSearchParams.get('order-type'));
          initialForm.order_type = orderType;
          initialForm.quantity = urlSearchParams.get('quantity')
          if (orderType?.value == ORDER.limit) {
            initialForm.price = urlSearchParams.get('price')
          }
          break
        }
        case Type.order_detail: {
          initialForm.orderId = urlSearchParams.get('order-id')
          break;
        }
        case Type.article: {
          const getListSymbols = await getSymbolArticle(urlSearchParams.get('symbol'))
          initialForm.symbolMultiple = getListSymbols
          initialForm.titleArticle = urlSearchParams.get('title')
          initialForm.urlArticle = urlSearchParams.get('url')
          break
        }
        case Type.security_detail: {
          const getSymbol = urlSearchParams.get('symbol');
          const url = getSearchSymbolUrl(getSymbol)
          const getSymbolApi = await getData(url);
          initialForm.symbol = getSymbolApi?.[0]?.[0]

          break;
        }
      }

      initialForm.Type_Url = TYPE_URL.find(e => e.value == checkUrl)
      initialForm.Type_Url_Label = TYPE_URL.find(e => e.value == checkUrl)?.label
      const getType = noti.expire_time.slice(-1);
      const getExpire = noti.expire_time.slice(0, noti.expire_time.length - 1);
      initialForm.expireNumber = getExpire;
      initialForm.optionType = EXPIRE_TIME_OPTIONS.find(e => e.value === getType)?.value
      initialForm.action1_title = noti.action1?.[0]?.title || '';
      initialForm.action1_url = noti.action1?.[0]?.url || '';
      initialForm.action2_title = noti.action2?.[0]?.title || '';
      initialForm.action2_url = noti.action2?.[0]?.url || '';
      try {
        if (noti?.segment_name) {
          const getSegment = await getData(getSearchSegmentUrl(noti?.segment_name || ''));
          initialForm.Segment = getSegment?.[0]?.data?.[0];
        }
      } catch (error) {
        logger.log(error)
      }
      initialForm.large_image = noti.large_image;
      initialForm.icon = noti.icon;
      initialForm.last_updated = new Date(noti.last_updated).toISOString()
      initialForm.schedule_time = new Date(noti.schedule_time).toISOString()
      initialForm.status = STATUS_OPTIONS.find(e => e.value == noti.status)
      !initialForm.symbolMultiple && (initialForm.symbolMultiple = []);
      initialForm.mobile_push = Boolean(noti.mobile_push)
      initialForm.desktop_push = Boolean(noti.desktop_push)
      initialForm.email_push = Boolean(noti.email_push)
      initialForm.sms_push = Boolean(noti.sms_push)

      setInitial(initialForm)
      setLoading(false)
    } catch (error) {
      console.log('YOLO: formatCode')
      logger.log(error)
      setTimeout(() => {
        setLoading(false)
        props.handleModalToggle && props.handleModalToggle()
        dispatch(fetchNotifications({ pageId: paginate.current_page, pageSize: paginate.page_size, search: '' }))
      }, 2000);
    }
  }
  // console.log(props.infoNoti)
  const submitClearNoti = (values, FormikHelpers) => {
    uploadIcon.current.value = ''
    uploadImage.current.value = ''
    FormikHelpers.resetForm({
      values: {
        ...initialValues,
        notification_type: values.notification_type,
        symbol: '',
        Segment: ''
      }
    })
    FormikHelpers.setErrors({})
  }
  const clearNoti = (values, FormikHelpers) => {
    cleanModalRef?.current?.showModal?.(() => submitClearNoti(values, FormikHelpers), {
      title: 'Confirm clear',
      content: 'Are you sure you want to clear data?'
    })
  }

  const renderButtons = (isSubmitting, values, formik) => {
    const text = values?.delivery_type?.value === DELIVERY_TYPE.SCHEDULE && values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ? 'Save' : 'Send';
    return (
      <div className={classes.buttons}>
        <Button variant='contained' color="secondary" className={classes.styleButtonClose} style={{ marginRight: 5 }} onClick={() => clearNoti(values, formik)}>Clear</Button>
        <Button variant='contained' color="primary" type="submit" disabled={equal(getInitial, values) || isSubmitting || values?.notification_type?.value === NOTIFICATION_TYPE.SEGMENT}>
          {isSubmitting ? <CircularProgress size="25px" /> : text}
        </Button>
      </div>
    )
  }
  const getUrlField = async (nameField, field, URl) => {
    const createForm = new FormData();
    createForm.set(nameField, field)
    // createForm.append('file', field, nameField);
    if (!field) return
    try {
      const getUrl = await postData(URl, createForm, {
        'Content-Type': `multipart/form-data`
      });
      if (getUrl[0].success) {
        return getUrl[0].url
      }
    } catch (err) {
      return ''
    }
  }
  // console.log(urlByEvn)
  const getURl = (obj) => {
    // console.log(obj)
    // console.log(obj.Type_Url.value)
    try {
      // console.log('yolo ', obj);
      const pathUrl = obj.Type_Url?.value;
      const urlByEvn = baseUrlEvn?.[dataStorage.enviroment]?.[dataStorage.env] || baseUrlEvn.DEV.dev1;
      const baseUrl = new URL(urlByEvn);
      const searchUrl = new URLSearchParams(baseUrl.search);
      searchUrl.set('type', pathUrl)
      switch (obj.Type_Url.value) {
        case Type.new_order: {
          if (obj.symbol.symbol && obj.order_type.value && obj.quantity) {
            if (obj.order_type.value === ORDER.limit) {
              searchUrl.set('price', obj.price)
            }
            searchUrl.set('symbol', obj.symbol.symbol)
            searchUrl.set('quantity', obj.quantity)
            searchUrl.set('order-type', obj.order_type.value)
            baseUrl.search = searchUrl
            return baseUrl.href
          }
          return false
        }
        case Type.order_detail: {
          if (obj.orderId) {
            searchUrl.set('order-id', obj.orderId)
            baseUrl.search = searchUrl
            return baseUrl.href
          }
          return false
        }
        case Type.security_detail: {
          if (obj.symbol.symbol) {
            searchUrl.set('symbol', obj.symbol.symbol)
            baseUrl.search = searchUrl
            return baseUrl.href
          }
          return false
        }
        case Type.article: {
          if (obj.symbolMultiple.length && obj.titleArticle && obj.urlArticle) {
            const transform = obj.symbolMultiple.map(e => e.symbol + "." + e.exchanges[0]).join(',');
            // console.log(transform)
            searchUrl.set('symbol', transform)
            searchUrl.set('title', obj.titleArticle)
            searchUrl.set('url', obj.urlArticle)
            baseUrl.search = searchUrl
            return decodeURIComponent(baseUrl.href)
          }
          return false
        }
        default: {
          if (obj.symbol.symbol && obj.order_type.value && obj.quantity) {
            if (obj.order_type.value === ORDER.limit) {
              searchUrl.set('price', obj.price)
            }
            searchUrl.set('symbol', obj.symbol.symbol)
            searchUrl.set('quantity', obj.quantity)
            searchUrl.set('order-type', obj.order_type.value)
            baseUrl.search = searchUrl
            return baseUrl.href
          }
          return false
        }
      }
    } catch (error) {
      logger.error(error, 'getURl error')
    }
  }
  const submitSegment = async (obj, url, value, helperAction) => {
    helperAction.setSubmitting(true)
    if (value.title && value.message && url) {
      obj.title = value?.title;
      obj.message = value?.message;
      obj.url = url;
      if (typeof value?.mobile_push == 'boolean') obj.mobile_push = value?.mobile_push;
      if (typeof value?.desktop_push == 'boolean') obj.desktop_push = value?.desktop_push;
      if (typeof value?.email_push == 'boolean') obj.email_push = value?.email_push;
      if (typeof value?.sms_push == 'boolean') obj.sms_push = value?.sms_push;
      if (value?.icon && typeof value.icon != 'string') {
        obj.icon = await getUrlField('icon', value?.icon, getPushIcontUrl());
      } else if (typeof value?.icon === 'string') {
        obj.icon = value?.icon || ''
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.symbol = value.symbol?.code;
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.exchange = value.symbol?.display_exchange;
      }
      const [res, sessionError] = await putData(getUpdateNotificationUrl(value.notification_id), obj)
      if (sessionError && !res?.success) {
        dataStorage.showAlert?.({ message: sessionError, type: 'error' })
        helperAction.setSubmitting(false)
      } else {
        dispatch(fetchNotifications({ pageId: paginate.current_page, pageSize: paginate.page_size, search: '' }))
        dataStorage.showAlert?.({ message: 'Send notification successfully', type: 'success' })
        props.handleModalToggle && props.handleModalToggle()
      }
    }
    helperAction.setSubmitting(false)
  }
  const submitDirect = async (obj, url, value, helperAction) => {
    helperAction.setSubmitting(true)
    if (value.title && value.message && url) {
      obj.title = value?.title;
      obj.message = value?.message;
      obj.url = url;

      if (value?.action1_title && value?.action1_url) {
        obj.action1 = [
          {
            title: value?.action1_title,
            url: value?.action1_url
          }
        ]
      }
      if (value?.action2_title && value?.action2_url) {
        obj.action2 = [
          {
            title: value?.action1_title,
            url: value?.action1_url
          }
        ]
      }
      if (typeof value?.mobile_push == 'boolean') obj.mobile_push = value?.mobile_push;
      if (typeof value?.desktop_push == 'boolean') obj.desktop_push = value?.desktop_push;
      if (typeof value?.email_push == 'boolean') obj.email_push = value?.email_push;
      if (typeof value?.sms_push == 'boolean') obj.sms_push = value?.sms_push;
      if (value?.subsribers.length) {
        obj.subscribers = value?.subsribers.map(current => current?.subscriber_id || current?.subscriber)
      }
      if (+(value?.expireNumber) && (value?.optionType?.value)) {
        obj.expire_time = value?.expireNumber + value?.optionType?.value
      }
      if (value?.icon && typeof value.icon != 'string') {
        obj.icon = await getUrlField('icon', value?.icon, getPushIcontUrl());
      } else if (typeof value?.icon === 'string') {
        obj.icon = value?.icon || ''
      }
      if (value?.large_image && (value?.notification_type?.value !== NOTIFICATION_TYPE.SEGMENT && typeof value.large_image != 'string')) {
        obj.large_image = await getUrlField('large_image', value?.large_image, getPushLargeImagetUrl());
      } else if (typeof value?.large_image === 'string') {
        obj.large_image = value?.large_image || ''
      }
      if (value?.delivery_type?.value === DELIVERY_TYPE.SCHEDULE && value?.schedule_time) {
        const getTime = new Date(value?.schedule_time);
        obj.schedule_time = getTime.getTime()
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.symbol = value.symbol?.code;
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.exchange = value.symbol?.display_exchange;
      }
      const [res, sessionError] = await putData(getUpdateNotificationUrl(value.notification_id), obj)
      if (sessionError) {
        helperAction.setSubmitting(false)
        dataStorage.showAlert?.({ message: sessionError, type: 'error' })
      } else {
        if (res?.success) {
          dispatch(fetchNotifications({ pageId: paginate.current_page, pageSize: paginate.page_size, search: '' }))
          dataStorage.showAlert?.({ message: 'Send notification successfully', type: 'success' })
          props.handleModalToggle && props.handleModalToggle()
        }

      }
    }
    helperAction.setSubmitting(false)
  }
  const submitForm = (value, helperAction) => {
    helperAction.setSubmitting(false)
    try {
      const obj = {};
      const url = getURl(value)
      if (value?.notification_type?.value === NOTIFICATION_TYPE.SEGMENT) {
        confirmSendModalRef?.current?.showModal?.(() => submitSegment(obj, url, value, helperAction), {
          title: 'Confirm save',
          content: 'Are you sure you want to save this notification?'
        })
      } else {
        confirmSendModalRef?.current?.showModal?.(() => submitDirect(obj, url, value, helperAction), {
          title: 'Confirm send',
          content: 'Are you sure you want to save this notification?'
        })
      }
    } catch (err) {
      dataStorage.showAlert?.({ message: 'invalid argument', type: 'error' })
      helperAction.setSubmitting(false)
    }
  }
  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        <IconButton
          onClick={props.handleModalToggle}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography id='top' component="h1" variant="h6" align="center">
          EDIT NOTIFICATION
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Paper className={classes.paper} elevation={15}>
        {renderHeader()}
        <Formik
          initialValues={getInitial || initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={submitForm}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, errors, values, ...formik }) => {
            return (
              <Form id={formId} autoComplete='off' style={{ paddingTop: 56, width: '100%' }}>
                {/* {renderContent()} */}
                <EditNotificationForm uploadIcon={uploadIcon} uploadImage={uploadImage} />
                {renderButtons(isSubmitting, values, formik)}
              </Form>
            )
          }}
        </Formik>
        <Backdrop
          open={loading}
          style={{ color: 'white', zIndex: 10 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>

      <ConfirmModal title={' '} textButton={'Send'} textCancel={'Close'} content={'Are you sure you want to continue?'} ref={confirmSendModalRef} />
      <ConfirmModal title={' '} textButton={'Clear'} textCancel={'Close'} content={'Are you sure you want to continue?'} ref={cleanModalRef} />
    </>
  )
}

export default EditNotification