import React from 'react';
import {
    postData,
    getData,
    getSubscriberUrl
} from 'src/api/api'
import { useDispatch } from 'react-redux'
import { setAlert } from 'src/redux/alert/alert'
import {
    Paper,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChipSegment from 'src/components/Subscribers/ChipSegment'
import ChipSubscriberStatus from '../components/Subscribers/ChipSubscriberStatus'
import CustomTable, { TABLE_ACTION } from 'src/components/CustomTable'
import dataStorage from 'src/dataStorage'
import { getFilterObj } from 'src/utils/functionUtils'

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        margin: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        minHeight: 0,
        overflow: 'hidden',
        position: 'relative',
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0
        }
    }
}))

const FIELD = {
    EMAIL: 'email',
    USER_ID: 'subscriber_id',
    FULL_NAME: 'full_name',
    STATUS: 'status',
    SEGMENT_REG: 'segment_register',
}

const defaultPaginate = {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    totalPages: 0
}

const LIST_FILTER = [
    FIELD.EMAIL,
    FIELD.USER_ID,
    FIELD.FULL_NAME
]

const Subscribers = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const paginate = React.useRef(defaultPaginate)
    const searchText = React.useRef('')
    const [peddingRequest, setPeddingRequest] = React.useState(false)
    const timeout = React.useRef(0)
    React.useEffect(() => {
        dataStorage.closeSideBar && dataStorage?.closeSideBar(false)
    }, [])
    React.useEffect(() => {
        if (peddingRequest) {
            setTimeout(() => {
                setPeddingRequest(false)
            }, 1000);
        }
    }, [peddingRequest])
    const updatePaginate = (obj) => {
        paginate.current = {
            pageSize: obj.page_size,
            currentPage: obj.current_page,
            totalCount: obj.total_count,
            totalPages: obj.total_pages
        }
    }

    const getSubscriber = async (params) => {
        setLoading(true)
        let requestMethod = getData
        let data = {}
        if (params.filter) {
            requestMethod = postData
            data = params.filter
        }
        const url = getSubscriberUrl(params)
        const [response, error] = await requestMethod(url, data)
        setLoading(false)
        if (error) {
            dataStorage.showAlert?.({ type: 'error', message: error })
            setPeddingRequest(true)
            return
        }
        updatePaginate({ ...response, page_size: params.pageSize, current_page: response.current_page || 1 })
        setRows(response.data || [])
    }

    React.useEffect(() => {
        setLoading(true)
        getSubscriber({
            pageId: paginate.current.currentPage,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
        })
    }, [])

    const COLUMNS = [
        {
            name: FIELD.EMAIL,
            label: 'Email',
            options: {
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        left: "0",
                        background: "white",
                        zIndex: 100
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        left: 0,
                        background: "#F3F4F6",
                        zIndex: 101
                    }
                }),
                filter: true,
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: FIELD.USER_ID,
            label: 'User ID',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: FIELD.FULL_NAME,
            label: 'Full Name',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: FIELD.STATUS,
            label: 'Status',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <ChipSubscriberStatus value={value} />
                }
            }
        },
        {
            name: FIELD.SEGMENT_REG,
            label: 'Segment Reg',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <ChipSegment value={value} />
                }
            }
        }
    ];


    const onTableChange = (action, tableState) => {
        if (['propsUpdate', 'onFilterDialogOpen', 'onFilterDialogClose'].includes(action)) return
        timeout.current && clearTimeout(timeout.current)
        timeout.current = window.setTimeout(() => {
            const filterObj = getFilterObj(tableState, LIST_FILTER)
            switch (action) {
                case TABLE_ACTION.SORT:
                    getSubscriber({
                        pageId: 1,
                        pageSize: paginate.current.pageSize,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                case TABLE_ACTION.FILTER_CHANGE:
                    getSubscriber({
                        pageId: 1,
                        pageSize: paginate.current.pageSize,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                case TABLE_ACTION.PAGE_CHANGE:
                    getSubscriber({
                        pageId: tableState.page + 1,
                        pageSize: paginate.current.pageSize,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                case TABLE_ACTION.PAGE_SIZE_CHANGE:
                    getSubscriber({
                        pageId: paginate.current.currentPage,
                        pageSize: tableState.rowsPerPage,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                case TABLE_ACTION.SEARCH:
                    searchText.current = tableState.searchText
                    getSubscriber({
                        pageId: 1,
                        pageSize: paginate.current.pageSize,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                case TABLE_ACTION.SEARCH_CLOSE:
                    if (!searchText.current) return
                    getSubscriber({
                        pageId: paginate.current.currentPage,
                        pageSize: paginate.current.pageSize,
                        search: searchText.current,
                        ...filterObj
                    })
                    break;
                default: break;
            }
        }, 500)
    }

    const renderTable = () => {
        return (
            <CustomTable
                title='Subscribers'
                loading={loading}
                rows={rows}
                columns={COLUMNS}
                rowsPerPageOptions={[10, 25, 100]}
                rowCount={paginate.current.totalCount}
                pageSize={paginate.current.pageSize}
                pageId={paginate.current.currentPage}
                onTableChange={onTableChange}
            />
        )
    }

    return (
        <>
            <Paper className={classes.paperContainer}>
                {renderTable()}
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={peddingRequest}
                style={{ zIndex: 1000 }}
            >
                <CircularProgress style={{ color: 'white' }} />
            </Backdrop>
        </>
    );
};

export default Subscribers