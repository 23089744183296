import React from 'react';
import {
  Typography,
  Grid,
  Link,
  TextField
} from '@mui/material';
import formModels from '../FormModels/formModels';
import {
  GOVERNMENT_ID_TYPE,
  CMA_SOURCE_OF_FUNDS,
  CMA_ACCOUNT_PURPOSE,
  MEDICARE_CARD_COLOUR,
  OCCUPATION_TYPE
} from 'src/constants'
import { CheckboxField, InputField } from 'src/components/CustomFields'
import Confirmation from 'src/components/Confirmation'
import moment from 'moment'
import dataStorage from 'src/dataStorage';
import { useFormikContext } from 'formik';
import useStyles from '../../styles';
import {
  getFullAddress,
  getEnv,
  checkKycVerify,
  renderFullname
} from 'src/utils/functionUtils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const { formField } = formModels;

function FullApplicationDetailsReview(props) {
  const classes = useStyles()
  const { formValues } = props;

  const rennderApplicantDetails = (e, index) => {
    const mobilePhone = formValues?.applicant_details[index]?.applicant_mobile_phone?.value || `04${formValues.applicant_details[index]?.applicant_mobile_phone}`
    let expireDateFormat = 'MM/YYYY'
    const cardColour = formValues.applicant_details[index]?.government_id?.medicare_card_colour?.value || formValues.applicant_details[index]?.government_id?.medicare_card_colour;
    if ([MEDICARE_CARD_COLOUR.BLUE, MEDICARE_CARD_COLOUR.YELLOW].includes(cardColour)) {
      expireDateFormat = 'DD/MM/YY'
    }
    const { first_name: firstName, last_name: lastName } = formValues.applicant_details[index];
    const name = (firstName && lastName) ? renderFullname(formValues.applicant_details[index]) : `Applicant ${index + 1}`;
    return <React.Fragment key={`review_applicant_details_${index}`}>
      <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" >
          {name}
        </Typography>
        {checkKycVerify(formValues.applicant_details[index]?.ekyc_overall_status) || !index
          ? <CheckCircleIcon fontSize="medium" style={{ marginLeft: 8, fill: '#3c8554' }} alt='' />
          : null
        }
      </Grid>
      {
        dataStorage.applicantId === e.applicant_id
          ? <>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label='Full Name'
                value={name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.applicant_email.label}
                value={formValues.applicant_details[index].applicant_email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.dob.label}
                value={moment(formValues.applicant_details[index].dob).format('DD/MM/YYYY')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.nationality.label}
                value={formValues.applicant_details[index].nationality?.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={formValues.applicant_details[index].gender?.label}
                label={formField.applicant_details.gender.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={mobilePhone}
                label={formField.applicant_details.applicant_mobile_phone.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.residential_address_full_address.label}
                value={getFullAddress(formValues.applicant_details[index], 'residential_address')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.government_id.type.label}
                value={formValues.applicant_details[index].government_id.type.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.government_id.number.label[formValues.applicant_details[index].government_id?.type?.value]}
                value={formValues.applicant_details[index].government_id.number}
                fullWidth
              />
            </Grid>
            {/* Using Driver License */}
            {formValues.applicant_details[index].government_id?.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
              ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.government_id.state_of_issue.label}
                      value={formValues.applicant_details[index].government_id.state_of_issue.label}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              )
              : null}
            {/* Using Medicare Card */}
            {formValues.applicant_details[index].government_id?.type?.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
              ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label='Type'
                      value={formValues.applicant_details[index].government_id.medicare_card_colour.label + ' / Person No. ' + formValues.applicant_details[index].government_id.medicare_individual_reference_number.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.government_id.medicare_card_expiry_date.label}
                      value={moment(formValues.applicant_details[index].government_id.medicare_card_expiry_date).format(expireDateFormat)}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              )
              : null}
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.gender.label}
                value={formValues.applicant_details[index].gender.label ? formValues.applicant_details[index].gender.label : ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.australian_tax_resident.label}
                value={formValues.applicant_details[index].australian_tax_resident ? 'YES' : 'NO'}
                fullWidth
              />
            </Grid>
            {
              formValues.applicant_details[index].australian_tax_resident
                ? <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.tax_exemption.label}
                      value={formValues.applicant_details[index].tax_exemption ? 'YES' : 'NO'}
                      fullWidth
                    />
                  </Grid>
                  {
                    formValues.applicant_details[index].tax_exemption
                      ? <Grid item xs={12} sm={6}>
                        <TextField
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                          label={formField.applicant_details.tax_exemption_details.label}
                          value={formValues.applicant_details[index].tax_exemption_details?.label}
                          fullWidth
                        />
                      </Grid>
                      : <Grid item xs={12} sm={6}>
                        <TextField
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                          label={formField.applicant_details.tfn.label}
                          value={formValues.applicant_details[index].tfn}
                          fullWidth
                        />
                      </Grid>
                  }
                </React.Fragment>
                : <React.Fragment />
            }
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.source_of_wealth.label}
                value={formValues.applicant_details[index].source_of_wealth.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.occupation_type.label}
                value={formValues.applicant_details[index].occupation_type.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.occupation_category.label}
                value={formValues.applicant_details[index].occupation_category.label}
                fullWidth
              />
            </Grid>
            {
              formValues.applicant_details[index].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                ? <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.business_owner_trading_name.label}
                      value={formValues.applicant_details[index].business_owner_trading_name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.abn_acn_registration_number.label}
                      value={formValues.applicant_details[index].abn_acn_registration_number || 'NO'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.applicant_details.business_owner_trading_address_full_address.label}
                      value={formValues.applicant_details[index].business_owner_trading_address_full_address?.full_address}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
                : <React.Fragment />
            }
            <Confirmation index={index} formValues={formValues} />
          </>
          : <>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label='Full Name'
                value={name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.applicant_email.label}
                value={formValues.applicant_details[index].applicant_email}
                fullWidth
              />
            </Grid>
            {formValues?.applicant_details[index]?.gender?.label
              ? <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.applicant_details?.gender?.label}
                    value={formValues?.applicant_details[index]?.gender?.label ? formValues.applicant_details[index]?.gender?.label : ''}
                    fullWidth
                  />
                </Grid></>
              : <></>}
            {formValues.applicant_details[index]?.applicant_mobile_phone
              ? <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  value={mobilePhone}
                  label={formField.applicant_details.applicant_mobile_phone.label}
                  fullWidth
                />
              </Grid>
              : null}
            {formValues.applicant_details[index]?.dob
              ? <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={formField.applicant_details.dob.label}
                  value={moment(formValues.applicant_details[index].dob).format('DD/MM/YYYY')}
                  fullWidth
                />
              </Grid>
              : null}

          </>
      }
    </React.Fragment >
  }
  const getApplicantOther = () => {
    const checkKYC = { KYCTrue: [], KYCFalse: [] }
    const sortApplicant = formValues.applicant_details.slice(1)
    sortApplicant.forEach(current => {
      const findIndex = formValues.applicant_details.findIndex(currentApp => {
        return currentApp.applicant_id === current.applicant_id
      })
      if (current.ekyc_aml_consent) return checkKYC.KYCTrue.push({ index: findIndex, data: current })
      return checkKYC.KYCFalse.push({ index: findIndex, data: current });
    })
    return checkKYC
  }

  const renderNewMacquarieCMABankAccount = () => {
    return (
      <React.Fragment>
        {
          !formValues.use_existing_CMT_acc
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.cma_source_of_funds.label}
                    value={formValues.cma_source_of_funds?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER ? `Other - ${formValues.cma_source_of_funds_desc}` : formValues.cma_source_of_funds?.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.cma_account_purpose.label}
                    value={formValues.cma_account_purpose?.value === CMA_ACCOUNT_PURPOSE[getEnv()].OTHER ? `Other - ${formValues.cma_account_purpose_desc}` : formValues.cma_account_purpose?.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}
        {formValues.use_existing_CMT_acc
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={formField.bank_account_name.label}
                  value={formValues.bank_account_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={formField.bank_bsb.label + ' - ' + formField.bank_account_number.label}
                  value={formValues.bank_bsb.value + ' - ' + formValues.bank_account_number}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )
          : null}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={'Settlement Bank Account'}
            value={'New Macquarie Cma Bank Account'}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    )
  }

  const renderSettlementInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            SETTLEMENT INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={'HIN Options'}
            value={!formValues.settlement_method ? 'Transfer an existing HIN' : 'Establish a new HIN'}
            fullWidth
          />
        </Grid>
        {
          !formValues.settlement_method
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.settlement_existing_hin.label}
                    value={formValues.settlement_existing_hin}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.settlement_pid.label}
                    value={formValues.settlement_pid.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null
        }
      </React.Fragment>
    )
  }

  return (
    <Grid container spacing={3} className='reivewContainer'>
      <Grid item xs={12}>
        <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICATION SUMMARY</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          APPLICANT DETAILS
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.account_type.label}
          value={formValues.account_type}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {rennderApplicantDetails(formValues.applicant_details[0], 0)}
      {getApplicantOther().KYCTrue.map((e) => rennderApplicantDetails(e.data, e.index))}
      {getApplicantOther().KYCFalse.map((e) => rennderApplicantDetails(e.data, e.index))}
      {renderSettlementInformation()}
      {renderNewMacquarieCMABankAccount()}
    </Grid>
  );
}

export default FullApplicationDetailsReview;
