export const initialStorage = {
    applicantId: null,
    accountStatus: null,
    morrisonStatus: null,
    accountType: null,
    dicDraft: {},
    registerEmail: '',
    email_trade_confirmation_consent: false,
    ekyc_consent: false,
    toc_consent: false,
    applicantInfo: {},
    brokerData: null,
    activeIndex: 0,
    indexApplicant: 0,
    equixId: null,
    applicantOther: null,
    isSubApplicant: false,
    addressData: {},
    all_application_submitted: false,
    isOperatorSupport: false,
    isSubmitted: false,
    scope: ''
}

const dataStorage = {
    enviroment: 'PROD',
    userType: 0,
    addressKey: '',
    baseUrl: '',
    baseUrlSSE: 'https://dev1-biz-api.equix.app/v1',
    config: {},
    configAll: {},
    refreshToken: null,
    tokenRefresh: null,
    refreshTokenId: null,
    accessToken: null,
    deviceId: '',
    setError: null,
    theme: 'light',
    listEnv: ['dev1', 'uat', 'equix', 'morrison', 'ricard', 'equitystorytrader', 'optixtrading', 'tradeforgood'],
    listDraft: [],
    paginateDraft: {},
    refreshTimeoutId: null,
    env: '',
    userInfo: {},
    selfCreateNotification: '',
    showAlert: null,
    updateAccountStatus: null,
    ...initialStorage
}
export default dataStorage
