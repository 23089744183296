import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Cog as CogIcon } from '../icons/cog';
import { Lock as LockIcon } from '../icons/lock';
import { Selector as SelectorIcon } from '../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { User as UserIcon } from '../icons/user';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { Users as UsersIcon } from '../icons/users';
import { XCircle as XCircleIcon } from '../icons/x-circle';
import NavItem from './NavItem';
import dataStorage from 'src/dataStorage';
import { useSelector } from 'react-redux'
import Logo from './Logo';
import SidebarMenu from './SidebarMenu'


const items = (env) => [
  {
    href: `/${env}`,
    icon: (<ChartBarIcon fontSize="small" />),
    title: 'Dashboard'
  },
  {
    href: `/${env}/notifications`,
    icon: (<UsersIcon fontSize="small" />),
    title: 'Notifications'
  },
  {
    href: `/${env}/subscribers`,
    icon: (<ShoppingBagIcon fontSize="small" />),
    title: 'Subscribers'
  },
  {
    href: `/${env}/segments`,
    icon: (<UserIcon fontSize="small" />),
    title: 'Segments'
  },
  {
    href: `/${env}/onboarding`,
    icon: (<UserIcon fontSize="small" />),
    title: 'Onboarding'
  }
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const isLogin = useSelector(state => state.auth.isLogin)
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  useEffect(
    () => {
      if (open) {
        onClose?.();
      }
    },
    [location.pathname]
  );

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ p: 3 }}>
          <RouterLink
            to={`/${dataStorage.env}`}
          >
            <Logo
              src={dataStorage.config?.logoDark}
              style={{
                maxWidth: '100%'
              }}
            />
          </RouterLink>
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748'
          }}
        />
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <SidebarMenu />
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3
          }}
          style={{ opacity: 0 }}
        >
        </Box>
      </Box>
    </>
  );

  if (!isLogin) return <></>

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
