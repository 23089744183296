import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  TextField,
  Autocomplete
} from '@mui/material';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 210,
    overflow: 'hidden',
    '&  ul': {
      maxHeight: '100%'
    }
  }
}))
function AutocompleteField(props) {
  const { label, data, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const classes = useStyles();
  const { setValue: setValueForm } = helper;
  const { value: selectedValue } = field;
  const timeoutId = React.useRef(0)
  const textSearch = React.useRef('')

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [])

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const autoSelectOptionByText = () => {
    if (!data || !data.length) return
    const option = data.find(e => e.label.toLowerCase() === (textSearch.current + '').toLowerCase())
    if (option) setValueForm && setValueForm(option)
  }

  function onChangeText(e) {
    textSearch.current = e.target?.value?.trim() || ''
    timeoutId.current && clearTimeout(timeoutId.current)
    timeoutId.current = window.setTimeout(() => autoSelectOptionByText(), 1000)
  }

  function _onChange(event, option, reason) {
    if (reason === 'selectOption') {
      timeoutId.current && clearTimeout(timeoutId.current)
      setValueForm && setValueForm(option)
    }
  }

  return (
    <Autocomplete
      {...field}
      {...rest}
      onChange={_onChange}
      value={data.length ? selectedValue : ''}
      options={data}
      isOptionEqualToValue={(option, select) => {
        return select && option?.value === select?.value
      }}
      classes={{ paper: classes.paper }}
      getOptionLabel={item => (item?.label || '')}
      id="disable-clearable"
      // filterOptions={x => x}
      disableClearable
      renderInput={(params) => {
        return <TextField
          onBlur={() => helper.setTouched(true, true)}
          name={'password'}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            form: {
              autocomplete: 'off'
            }
          }}
          required={rest.required}
          value={(selectedValue && selectedValue.label) || textSearch.current || ''}
          variant="outlined"
          onChange={onChangeText}
          error={touched && error && true}
          helperText={_renderHelperText()}
          label={label}
          type="text" />
      }}
    />
  );
}

AutocompleteField.defaultProps = {
  data: []
};

AutocompleteField.propTypes = {
  data: PropTypes.array.isRequired
};

export default AutocompleteField;
