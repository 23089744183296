import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

function SelectField(props) {
  const { label, disable, data, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper || {};
  const { validateForm } = useFormikContext();
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  React.useEffect(() => {
    validateForm().then(res => {
    }).catch(err => {
      console.log(err);
    })
  }, [selectedValue?.value])

  const _onChange = (event) => {
    const valueSelected = event.target.value
    const option = data.find(e => e.value === valueSelected)
    option && setValueForm && setValueForm(option)
  }

  return (
    <FormControl variant="outlined" {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Select
        // disabled={disable}
        value={selectedValue && data.length ? (selectedValue.value ?? selectedValue) : ''}
        onChange={_onChange}
        onBlur={field.onBlur}
        name={field.name}
        label={`${label}1`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 205
            }
          },
          disableScrollLock: true
        }}
        // {...field}
        // {...rest}
        inputProps={{ readOnly: Boolean(rest?.checkReview) }}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectField;
