import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      '& > span': {
        height: 'fit-content',
        paddingTop: 2
      }
    }
  }
}));

export default function CheckboxField(props) {
  const classes = useStyles();
  const { label, disableTextHelper, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setValue } = helper;
  const isError = touched && error && true;

  function _renderHelperText() {
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl className={classes.formControl} {...rest} error={isError}>
      <FormControlLabel
        value={field.value}
        checked={!!field.value}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}

      />
      {!disableTextHelper ? _renderHelperText() : null}
      {/* {_renderHelperText()} */}
    </FormControl>
  );
}
