import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import StarBorder from '@mui/icons-material/StarBorder';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Cog as CogIcon } from '../icons/cog';
import { Lock as LockIcon } from '../icons/lock';
import { Selector as SelectorIcon } from '../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { User as UserIcon } from '../icons/user';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { Users as UsersIcon } from '../icons/users';
import { Article as ArticleIcon } from '../icons/article';
import { Bell as BellIcon } from '../icons/bell';
import { XCircle as XCircleIcon } from '../icons/x-circle';
import NavItem from './NavItem';
import dataStorage from 'src/dataStorage';
import { useSelector } from 'react-redux'
import Logo from './Logo';

export default function SidebarMenu() {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const env = dataStorage.env

    return (
        <List
            sx={{ width: '100%' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <NavItem
                key={'Dashboard'}
                icon={<ChartBarIcon fontSize="small" />}
                href={`/${env}`}
                title={'Dashboard'}
            />
            <NavItem
                key={'Notifications'}
                icon={<BellIcon fontSize="small" />}
                href={`/${env}/notifications`}
                title={'Notifications'}
            />
            <NavItem
                key={'Segments'}
                icon={<UsersIcon fontSize="small" />}
                href={`/${env}/segments`}
                title={'Segments'}
            />
            <NavItem
                key={'Subscribers'}
                icon={<UserIcon fontSize="small" />}
                href={`/${env}/subscribers`}
                title={'Subscribers'}
            />
            <NavItem
                isParent={true}
                open={open}
                key={'Onboarding'}
                icon={<ArticleIcon fontSize="small" />}
                href={`/${env}/onboarding`}
                title={'Onboarding'}
                onClick={handleClick}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <NavItem
                        key={'New Application'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/new_application`}
                        title={'New Application'}
                    />
                    <NavItem
                        key={'Drafts'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/drafts`}
                        title={'Drafts'}
                    />
                    <NavItem
                        key={'Pending Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/pending_application`}
                        title={'Pending Application'}
                    />
                    <NavItem
                        key={'Completed Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/completed_application`}
                        title={'Completed Application'}
                    />
                    <NavItem
                        key={'Rejected Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/reject_application`}
                        title={'Reject Application'}
                    />
                </List>
            </Collapse>
        </List>
    );
}
