const formModels = {
    formId: 'individualForm',
    formField: {
        notification_type: {
            name: 'notification_type',
            label: 'Notification Type'
        },
        audience: {
            name: 'audience',
            label: 'Audience'
        },
        delivery_type: {
            name: 'delivery_type',
            label: 'Delivery Type'
        },
        schedule_time: {
            name: 'schedule_time',
            label: 'Schedule Time'
        },
        expire_time: {
            name: 'expire_time',
            label: 'Expire Time'
        },
        segment: {
            name: 'Segment',
            label: 'Segment'
        },
        subsribers: {
            name: 'subsribers',
            label: 'Subscribers'
        },
        channel: {
            name: 'channel',
            label: 'Channel'
        },
        title: {
            name: 'title',
            label: 'Title'
        },
        message: {
            name: 'message',
            label: 'Message'
        },
        url: {
            name: 'url',
            label: 'URL'
        },
        email: {
            name: 'Email',
            label: 'Subscribers'
        },
        icon: {
            name: 'icon',
            label: 'Choose Icon'
        },
        large_image: {
            name: 'large_image',
            label: 'Choose Image'
        },
        action1_title: {
            name: 'action1_title',
            label: 'Action 1 Title'
        },
        action1_url: {
            name: 'action1_url',
            label: 'Action 1 URL'
        },
        action2_title: {
            name: 'action2_title',
            label: 'Action 2 Title'
        },
        action2_url: {
            name: 'action2_url',
            label: 'Action 2 URL'
        },
        mobile_push: {
            name: 'mobile_push',
            label: 'Mobile'
        },
        desktop_push: {
            name: 'desktop_push',
            label: 'Desktop'
        },
        email_push: {
            name: 'email_push',
            label: 'Email'
        },
        sms_push: {
            name: 'sms_push',
            label: 'SMS'
        },
        expire_number: {
            name: 'expireNumber',
            label: 'Expire'
        },
        option_type: {
            name: 'optionType',
            label: 'Type'
        },
        notification_section: {
            name: 'notification_section',
            label: 'Notification Section'
        },
        type_url: {
            name: 'Type_Url',
            label: 'Type Url'
        },
        attempted: {
            name: 'attempted',
            label: 'Attempted'
        },
        delivered: {
            name: 'delivered',
            label: 'Delivered'
        },
        clicked: {
            name: 'clicked',
            label: 'Clicked'
        },
        ctr: {
            name: 'ctr',
            label: 'CTR'
        },
        actor: {
            name: 'actor',
            label: 'Actor'
        },
        last_updated: {
            name: 'last_updated',
            label: 'Last Update'
        },
        type_url_label: {
            name: 'Type_Url_Label',
            label: 'Type Url'
        },
        status: {
            name: 'status',
            label: 'Status'
        },
        symbol: {
            name: 'symbol',
            label: 'Symbol'
        },
        order_type: {
            name: 'order_type',
            label: 'Order Type'
        },
        quantity: {
            name: 'quantity',
            label: 'Quantity'
        },
        price: {
            name: 'price',
            label: 'Price'
        },
        title_article: {
            name: 'titleArticle',
            label: 'Title'
        },
        url_article: {
            name: 'urlArticle',
            label: 'URL'
        },
        order_id: {
            name: 'orderId',
            label: 'Order ID'
        },
        symbol_multiple: {
            name: 'symbolMultiple',
            label: 'Symbol'
        }
    }
}
export default formModels