/* eslint-disable no-constant-condition */
import React from 'react';
import {
  Typography,
  Grid,
  Link
  , TextField, Button
} from '@mui/material';
import formModels from '../FormModels/formModels';
import { useFormikContext } from 'formik';
import {
  GOVERNMENT_ID_TYPE,
  OCCUPATION_TYPE,
  MEDICARE_CARD_COLOUR,
  CMA_ACCOUNT_PURPOSE,
  CMA_SOURCE_OF_FUNDS,
  FATCA_STATUS
} from 'src/constants'
import { CheckboxField, InputField } from 'src/components/CustomFields'
import moment from 'moment'
import dataStorage from 'src/dataStorage';
import ResendEmail from 'src/components/ResendEmail'
import Confirmation from 'src/components/Confirmation'
import useStyles from '../../styles';
import {
  getFullAddress,
  getEnv,
  checkKycVerify,
  isBroker,
  getPickAddress,
  renderFullname
} from 'src/utils/functionUtils'
import { ACCOUNT_TYPE } from 'src/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const { formField } = formModels;

function FullApplicationDetailsReview(props) {
  const classes = useStyles()
  const { values: formValues } = useFormikContext()

  const rennderApplicantDetails = (index) => {
    const mobilePhone = formValues?.applicant_details[index]?.applicant_mobile_phone?.value || `04${formValues.applicant_details[index]?.applicant_mobile_phone}`
    let expireDateFormat = 'MM/YYYY'
    const cardColour = formValues.applicant_details[index].government_id?.medicare_card_colour?.value || formValues.applicant_details[index].government_id?.medicare_card_colour;
    if ([MEDICARE_CARD_COLOUR.BLUE, MEDICARE_CARD_COLOUR.YELLOW].includes(cardColour)) {
      expireDateFormat = 'DD/MM/YY'
    }
    const { first_name: firstName, last_name: lastName, role_in_super_individual: roleIndividual, role_in_super_other: roleOther } = formValues.applicant_details[index]
    const name = (firstName && lastName) ? renderFullname(formValues.applicant_details[index]) : `Applicant ${index + 1}`;
    let role = ''
    if (formValues.trustee_type?.value === ACCOUNT_TYPE.COMPANY) {
      role = Object.values(formValues.applicant_details[index].role_in_super).filter(e => e).map(e => e.label).join(', ')
    } else {
      if (roleIndividual?.value === 'OTHER') {
        role = roleOther
      } else {
        role = roleIndividual?.label
      }
    }
    return <React.Fragment key={`review_applicant_details_${index}`}>
      <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" >
          {name}
        </Typography>
        {checkKycVerify(formValues.applicant_details[index]?.ekyc_overall_status)
          ? <CheckCircleIcon fontSize="medium" style={{ marginLeft: 8, fill: '#3c8554' }} alt='' />
          : null
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.role_in_super.label}
          value={role}
          fullWidth
        />
      </Grid>
      <Grid item xs={false} sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label='Full Name'
          value={name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.applicant_email.label}
          value={formValues.applicant_details[index].applicant_email}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.dob.label}
          value={moment(formValues.applicant_details[index].dob).format('DD/MM/YYYY')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.nationality.label}
          value={formValues.applicant_details[index]?.nationality?.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          value={formValues.applicant_details[index].gender?.label}
          label={formField.applicant_details.gender.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          value={mobilePhone}
          label={formField.applicant_details.applicant_mobile_phone.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.residential_address_full_address.label}
          value={getFullAddress(formValues.applicant_details[index], 'residential_address')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.government_id.type.label}
          value={formValues.applicant_details[index].government_id.type.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.government_id.number.label[formValues.applicant_details[index].government_id?.type?.value]}
          value={formValues.applicant_details[index].government_id.number}
          fullWidth
        />
      </Grid>
      {/* Using Driver License */}
      {formValues.applicant_details[index].government_id?.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
        ? (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.government_id.state_of_issue.label}
                value={formValues.applicant_details[index].government_id.state_of_issue.label}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        )
        : null}
      {/* Using Medicare Card */}
      {formValues.applicant_details[index].government_id?.type?.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
        ? (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label='Type'
                value={formValues.applicant_details[index].government_id.medicare_card_colour.label + ' / Person No. ' + formValues.applicant_details[index].government_id.medicare_individual_reference_number.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.government_id.medicare_card_expiry_date.label}
                value={moment(formValues.applicant_details[index].government_id.medicare_card_expiry_date).format(expireDateFormat)}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        )
        : null}
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.australian_tax_resident.label}
          value={formValues.applicant_details[index].australian_tax_resident ? 'YES' : 'NO'}
          fullWidth
        />
      </Grid>
      {
        formValues.applicant_details[index].australian_tax_resident
          ? <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.tax_exemption.label}
                value={formValues.applicant_details[index].tax_exemption ? 'YES' : 'NO'}
                fullWidth
              />
            </Grid>
            {
              formValues.applicant_details[index].tax_exemption
                ? <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.applicant_details.tax_exemption_details.label}
                    value={formValues.applicant_details[index].tax_exemption_details?.label}
                    fullWidth
                  />
                </Grid>
                : <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.applicant_details.tfn.label}
                    value={formValues.applicant_details[index].tfn}
                    fullWidth
                  />
                </Grid>
            }
          </React.Fragment>
          : <React.Fragment />
      }
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.source_of_wealth.label}
          value={formValues.applicant_details[index].source_of_wealth.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.occupation_type.label}
          value={formValues.applicant_details[index].occupation_type.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.applicant_details.occupation_category.label}
          value={formValues.applicant_details[index].occupation_category.label}
          fullWidth
        />
      </Grid>
      {
        formValues.applicant_details[index].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
          ? <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.business_owner_trading_name.label}
                value={formValues.applicant_details[index].business_owner_trading_name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.abn_acn_registration_number.label}
                value={formValues.applicant_details[index].abn_acn_registration_number || 'NO'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.applicant_details.business_owner_trading_address_full_address.label}
                value={formValues.applicant_details[index].business_owner_trading_address_full_address?.full_address}
                fullWidth
              />
            </Grid>
          </React.Fragment>
          : <React.Fragment />
      }
    </React.Fragment>
  }
  const getApplicantOther = () => {
    const checkKYC = { KYCTrue: [], KYCFalse: [] }
    const sortApplicant = formValues.applicant_details.slice(1)
    sortApplicant.forEach((current, index) => {
      if (current.ekyc_aml_consent) return checkKYC.KYCTrue.push({ indexApplicant: index + 1, data: current })
      return checkKYC.KYCFalse.push({ indexApplicant: index + 1, data: current });
    })
    return checkKYC
  }

  const renderFundDetails = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            FUND INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.super_fund_name.label}
            value={formValues.super_fund_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.smsf.label}
            value={formValues.smsf ? 'YES' : 'NO'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.super_fund_abn.label}
            value={formValues.super_fund_abn}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.super_fund_activity.label}
            value={formValues.smsf ? 'Superannuation / Pension' : formValues.super_fund_activity?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.abn_or_registration_licensing_details.label}
            value={formValues.abn_not_available ? formField.abn_not_available.label : formValues.abn_or_registration_licensing_details}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.name_of_regulator.label}
            value={formValues.name_of_regulator}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.super_fund_address_full_address.label}
            value={getPickAddress(formValues)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.super_fund_tax_exemption.label}
            value={formValues.super_fund_tax_exemption ? 'YES' : 'NO'}
            fullWidth
          />
        </Grid>
        {
          formValues.super_fund_tax_exemption
            ? <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.super_fund_tax_exemption_details.label}
                value={formValues.super_fund_tax_exemption_details?.label}
                fullWidth
              />
            </Grid>
            : <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.super_fund_tfn.label}
                value={formValues.super_fund_tfn}
                fullWidth
              />
            </Grid>
        }
        {
          formValues.smsf
            ? <React.Fragment>
            </React.Fragment>
            : <React.Fragment>
              {
                formValues.global_intermediary_identification_number
                  ? <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.global_intermediary_identification_number.label}
                      value={formValues.global_intermediary_identification_number}
                      fullWidth
                    />
                  </Grid>
                  : <React.Fragment />
              }
              {
                formValues.fatca_status?.value
                  ? <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.fatca_status.label}
                      value={formValues.fatca_status?.label}
                      fullWidth
                    />
                  </Grid>
                  : <React.Fragment />
              }
              {
                formValues.fatca_status?.value === FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION
                  ? <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.trustee_global_intermediary_identification_number.label}
                      value={formValues.trustee_global_intermediary_identification_number}
                      fullWidth
                    />
                  </Grid>
                  : <React.Fragment />
              }
              {
                formValues.fatca_status?.value === FATCA_STATUS.OTHER
                  ? <Grid item xs={12} sm={6}>
                    <TextField
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label={formField.fatca_status_other.label}
                      value={formValues.fatca_status_other}
                      fullWidth
                    />
                  </Grid>
                  : <React.Fragment />
              }
            </React.Fragment>
        }
      </React.Fragment >
    )
  }

  const renderAccountDesignation = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            ACCOUNT DESIGNATION
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.account_designation.label}
            value={formValues.account_designation + ' A/C'}
            // InputProps={{
            //   endAdornment: 'A/C'
            // }}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    )
  }

  const renderAdditionalInformation = () => {
    let value = ''
    if (formValues.applicant?.value === 'OTHER') {
      value = getFullAddress(formValues, 'mailing_address')
    } else {
      const activeIndex = formValues.applicant_details.findIndex(e => e.applicant_id === formValues.applicant?.value)
      if (activeIndex >= 0) {
        value = getFullAddress(formValues.applicant_details[activeIndex], 'residential_address')
      }
    }
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            ADDITIONAL INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={'Mailing Address'}
            value={value}
            fullWidth
          />
        </Grid>
        <Grid item xs={false} sm={6}></Grid>
      </React.Fragment>
    )
  }

  const renderCompanyDetails = () => {
    if (formValues.trustee_type?.value !== ACCOUNT_TYPE.COMPANY) return <></>
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            COMPANY INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_name.label}
            value={formValues.company_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_type.label}
            value={formValues.company_type.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_sector.label}
            value={formValues.company_sector.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_date_of_incorporation.label}
            value={moment(formValues.company_date_of_incorporation).format('DD/MM/YYYY')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_acn.label}
            value={formValues.company_acn}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_abn.label}
            value={formValues.company_abn}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_registered_office_address_full_address.label}
            value={getFullAddress(formValues, 'company_registered_office_address')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_principal_place_of_business_address_full_address.label}
            value={getFullAddress(
              formValues,
              formValues.company_same_as_roa ? 'company_registered_office_address' : 'company_principal_place_of_business_address'
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_work_phone.label}
            value={formValues.company_work_phone}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_mobile_phone.label}
            value={formValues.company_mobile_phone ? '04' + formValues.company_mobile_phone : ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={formField.company_tax_exemption.label}
            value={formValues.company_tax_exemption ? 'YES' : 'NO'}
            fullWidth
          />
        </Grid>
        {
          formValues.company_tax_exemption
            ? <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.company_tax_exemption_details.label}
                value={formValues.company_tax_exemption_details?.label}
                fullWidth
              />
            </Grid>
            : <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={formField.company_tfn.label}
                value={formValues.company_tfn}
                fullWidth
              />
            </Grid>
        }
      </React.Fragment>
    )
  }

  const renderSettlementInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            SETTLEMENT INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={'HIN Options'}
            value={!formValues.settlement_method ? 'Transfer an existing HIN' : 'Establish a new HIN'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            inputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            label={'Settlement Bank Account'}
            value={'New Macquarie Cma Bank Account'}
            fullWidth
          />
        </Grid>
        {
          !formValues.settlement_method
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.settlement_existing_hin.label}
                    value={formValues.settlement_existing_hin}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.settlement_pid.label}
                    value={formValues.settlement_pid.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null
        }
      </React.Fragment>
    )
  }

  const renderNewMacquarieCMABankAccount = () => {
    return (
      <React.Fragment>
        {
          !formValues.use_existing_CMT_acc
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.cma_source_of_funds.label}
                    value={formValues.cma_source_of_funds?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER ? `Other - ${formValues.cma_source_of_funds_desc}` : formValues.cma_source_of_funds?.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={formField.cma_account_purpose.label}
                    value={formValues.cma_account_purpose?.value === CMA_ACCOUNT_PURPOSE[getEnv()].OTHER ? `Other - ${formValues.cma_account_purpose_desc}` : formValues.cma_account_purpose?.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}
        {formValues.use_existing_CMT_acc
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={formField.bank_account_name.label}
                  value={formValues.bank_account_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={formField.bank_bsb.label + ' - ' + formField.bank_account_number.label}
                  value={formValues.bank_bsb.value + ' - ' + formValues.bank_account_number}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )
          : null}
      </React.Fragment>
    )
  }

  return (
    <Grid container spacing={3} className='reivewContainer'>
      <Grid item xs={12}>
        <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICATION SUMMARY</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={formField.account_type.label}
          value={`SUPER FUND ${formValues.trustee_type?.value}`}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          APPLICANT DETAILS
        </Typography>
      </Grid>
      {rennderApplicantDetails(0)}
      {getApplicantOther().KYCTrue.map(current => rennderApplicantDetails(current.indexApplicant))}
      {getApplicantOther().KYCFalse.map(current => rennderApplicantDetails(current.indexApplicant))}
      {renderCompanyDetails()}
      {renderFundDetails()}
      {renderAccountDesignation()}
      {renderAdditionalInformation()}
      {renderSettlementInformation()}
      {renderNewMacquarieCMABankAccount()}
      <Confirmation index={0} formValues={formValues} />
    </Grid>
  );
}

export default FullApplicationDetailsReview;
