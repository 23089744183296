import React from 'react'
import Typography from '@mui/material/Typography'
import {
    ACCOUNT_STATUS,
    ACCOUNT_STATUS_DISPLAY
} from 'src/constants'
import dataStorage from 'src/dataStorage'

export default function AccountStatus({ accountStatus }) {
    const [status, setStatus] = React.useState(accountStatus === ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT ? dataStorage.morrisonStatus : accountStatus)

    const setAccountStatus = (newStatus) => {
        setStatus(newStatus === ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT ? dataStorage.morrisonStatus : newStatus)
    }

    React.useEffect(() => {
        dataStorage.updateAccountStatus = setAccountStatus
        return () => {
            dataStorage.updateAccountStatus = null
        }
    }, [])

    return (
        <Typography component="h1" variant="h6" align="center">
            {status ? (ACCOUNT_STATUS_DISPLAY[status] || status) : 'NEW TRADING ACCOUNT'}
        </Typography>
    )
}
