import React from 'react'
import {
    Chip
} from '@mui/material';


const ChipSubscriberStatus = ({ ...props }) => {
    const styles = {
        backgroundColor: {
            'Active': '#D2E6C8',
            'Inactive': '#FCBEBE'
        }
    }
    return (
        <Chip style={{ backgroundColor: styles.backgroundColor[props.value] }} label={props.value || '--'} />
    )
}

export default ChipSubscriberStatus
