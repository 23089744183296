import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: '',
    type: 'info'
};

export const alert = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert(state, actions) {
            state.message = actions.payload.message
            state.type = actions.payload.type
        }
    }
})
const { actions, reducer } = alert
export const { setAlert } = actions;
export default reducer;

