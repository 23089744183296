import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    postData,
    getData,
    getSegmentUrl
} from 'src/api/api'

const initialState = {
    segments: [],
    paginate: {
        page_size: 10,
        current_page: 1,
        total_count: 0,
        total_pages: 0
    },
    selectedItems: [],
    loading: false,
    error: '',
    currentRequestId: undefined,
    peddingRequest: false
}

export const fetchSegments = createAsyncThunk(
    'segments/fetch',
    async (params) => {
        let requestMethod = getData
        let data = {}
        if (params.filter) {
            requestMethod = postData
            data = params.filter
        }
        const url = getSegmentUrl(params)
        const [response, error] = await requestMethod(url, data)
        if (error) throw error
        else return { response, error, pageSize: params.pageSize }
    }
);

export const segment = createSlice({
    name: 'segment',
    initialState,
    reducers: {
        setPaginate(state, action) {
            state.paginate = { ...state.paginate, ...action.payload }
        },
        addSegment(state, action) {
            state.segments = [action.payload, ...state.segments]
        },
        updateSegment(state, action) {
            const index = state.segments.findIndex(e => e.segment_id === action.payload.segment_id)
            state.segments[index] = action.payload
        },
        deleteSegment(state, action) {
            state.segments = state.segments.filter(e => e.segment_id !== action.payload)
        },
        setPeddingRequest(state) {
            state.peddingRequest = false
        }
    },
    extraReducers: (builder) => {
        // fetch segments
        builder.addCase(fetchSegments.pending, (state, action) => {
            state.loading = true;
            state.currentRequestId = action.meta.requestId;
            state.error = undefined
        })
        builder.addCase(fetchSegments.fulfilled, (state, action) => {
            if (action.meta.requestId === state.currentRequestId) {
                const { data, ...rest } = action.payload.response
                state.loading = false;
                state.segments = data || [];
                state.peddingRequest = false
                state.paginate = { ...state.paginate, ...rest, page_size: action.payload.pageSize, current_page: rest.current_page || 1 }
                state.currentRequestId = undefined;
            }
        })
        builder.addCase(fetchSegments.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (requestId === state.currentRequestId) {
                state.loading = false;
                state.error = action.error.message;
                state.currentRequestId = undefined;
                if (action.error.message) {
                    state.peddingRequest = true
                }
            }
        })
    }
})
const { actions, reducer } = segment
export const {
    setPaginate,
    addSegment,
    updateSegment,
    deleteSegment,
    setPeddingRequest
} = actions;
export default reducer;

