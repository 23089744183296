import React from 'react'
import {
    Snackbar
} from '@mui/material';
import { Alert, Color } from '@mui/lab';
import dataStorage from 'src/dataStorage';
import { scrollToTop } from 'src/utils/functionUtils'

const AlertComp = () => {
    const [alert, setAlert] = React.useState({})

    const showAlert = (obj) => {
        if (!obj) return
        scrollToTop()
        setAlert(obj)
    }

    const hideAlert = () => {
        setAlert({ message: '' })
    }

    React.useEffect(() => {
        dataStorage.showAlert = showAlert
        dataStorage.hideAlert = hideAlert
    }, [])

    return (
        <Snackbar open={!!alert?.message} onClose={() => setAlert({ message: '' })}
            autoHideDuration={null}
            disableWindowBlurListener={true}
            ClickAwayListenerProps={{
                mouseEvent: 'onClick',
                onClickAway: () => setTimeout(() => setAlert({ message: '' }), 3000)
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert elevation={6} variant="filled" severity={alert?.type}>
                {alert?.message}
            </Alert>
        </Snackbar>
    )
}

export default AlertComp