import { getEnv } from 'src/utils/functionUtils'

// ONBOARDING OPTIONS

export const ACCOUNT_TYPE = {
    INDIVIDUAL: 'INDIVIDUAL',
    JOINT: 'JOINT',
    COMPANY: 'COMPANY',
    TRUST: 'TRUST',
    FUND: 'SUPER_FUND',
    INTERNATIONAL_INVESTOR: 'INTERNATIONAL_INVESTOR'
};

export const ACCOUNT_TYPE_TEXT = {
    [ACCOUNT_TYPE.JOINT]: 'joint',
    [ACCOUNT_TYPE.INDIVIDUAL]: 'individual',
    [ACCOUNT_TYPE.COMPANY]: 'company',
    [ACCOUNT_TYPE.TRUST]: 'trust',
    [ACCOUNT_TYPE.FUND]: 'super-fund'
}

export const PERCENT_TO_VERIFIED = 25

export const EKYC_GOVID_STATUS = {
    EKYC_LOCKED_OUT: 'EKYC_LOCKED_OUT'
}

export const TYPE_OPTIONS = [
    { value: 'business', label: 'a business' },
    { value: 'individual', label: 'an individual' }
]

export const USER_TYPE = { BROKER: 1, RETAIL: 0 }

export const COMPANY_SECTOR = {
    ENERGY: 'Energy',
    MATERIALS: 'Materials',
    INDUSTRIALS: 'Industrials',
    CONSUMER_DISCRETIONARY: 'Consumer discretionary',
    CONSUMER_STAPLES: 'Consumer staples',
    HEALTH_CARE: 'Health care',
    FINANCIALS: 'Financials',
    INFORMATION_TECHNOLOGY: 'Information technology',
    TELECOMMUNICATIONS_SERVICES: 'Telecommunications services',
    UTILITIES: 'Utilities',
    REAL_ESTATE: 'Real estate'
}

export const INDUSTRY_CODE_OPTIONS = {
    [COMPANY_SECTOR.ENERGY]: [
        { value: 'OIL_GAS_DRILLING', label: 'Oil gas drilling' },
        { value: 'GAS_EQUIPMENT_SERVICES', label: 'Gas equipment services' },
        { value: 'INTEGRATED_OIL_GAS', label: 'Integrated oil gas' },
        { value: 'OIL_GAS_EXPLORATION_PRODUCTION', label: 'Oil gas exploration production' },
        { value: 'OIL_GAS_REFINING_MARKETING', label: 'Oil gas refining marketing' },
        { value: 'OIL_GAS_STORAGE_TRANSPORTATION', label: 'Oil gas storage transportation' },
        { value: 'COAL_CONSUMABLE_FUELS', label: 'Coal consumable fuels' }
    ],
    [COMPANY_SECTOR.MATERIALS]: [
        { value: 'COMMODITY_CHEMICALS', label: 'Commodity chemicals' },
        { value: 'DIVERSIFIED_CHEMICALS', label: 'Diversified chemicals' },
        { value: 'FERTILIZERS_AGRICULTURAL_CHEMICALS', label: 'Fertilizers agricultural chemicals' },
        { value: 'INDUSTRIAL_GASSES', label: 'Industrial gasses' },
        { value: 'SPECIALITY_CHEMICALS', label: 'Speciality chemicals' },
        { value: 'CONSTRUCTION_MATERIALS', label: 'Construction materials' },
        { value: 'METAL_GLASS_CONTAINERS', label: 'Metal glass containers' },
        { value: 'PAPER_PACKAGING', label: 'Paper packaging' },
        { value: 'ALUMINUM', label: 'Aluminum' },
        { value: 'DIVERSIFIED_METALS_MINING', label: 'Diversified metals mining' },
        { value: 'COPPER', label: 'Copper' },
        { value: 'GOLD', label: 'Gold' },
        { value: 'PRECIOUS_METALS_MINERALS', label: 'Precious metals minerals' },
        { value: 'SILVER', label: 'Silver' },
        { value: 'STEEL', label: 'Steel' },
        { value: 'FOREST_PRODUCTS', label: 'Forest products' },
        { value: 'PAPER_PRODUCTS', label: 'Paper products' }
    ],
    [COMPANY_SECTOR.INDUSTRIALS]: [
        { value: 'AEROSPACE_DEFENSE', label: 'Aerospace defense' },
        { value: 'BUILDING_PRODUCTS', label: 'Building products' },
        { value: 'CONSTRUCTION_ENGINEERING', label: 'Construction engineering' },
        { value: 'ELECTRICAL_COMPONENTS_EQUIPMENT', label: 'Electrical components equipment' },
        { value: 'HEAVY_ELECTRICAL_EQUIPMENT', label: 'Heavy electrical equipment' },
        { value: 'INDUSTRIAL_CONGLOMERATES', label: 'Industrial conglomerates' },
        { value: 'CONSTRUCTION_MACHINERY_HEAVY_TRUCKS', label: 'Construction machinery heavy trucks' },
        { value: 'AGRICULTURAL_FARM_MACHINERY', label: 'Agricultural farm machinery' },
        { value: 'INDUSTRIAL_MACHINERY', label: 'Industrial machinery' },
        { value: 'TRADING_COMPANIES_DISTRIBUTORS', label: 'Trading companies distributors' },
        { value: 'COMMERCIAL_PRINTING', label: 'Commercial printing' },
        { value: 'ENVIRONMENTAL_FACILITIES_SERVICES', label: 'Environmental facilities services' },
        { value: 'OFFICE_SERVICES_SUPPLIES', label: 'Office services supplies' },
        { value: 'DIVERSIFIES_SUPPER_SERVICES', label: 'Diversifies supper services' },
        { value: 'SECURITY_ALARM_SERVICES', label: 'Security alarm services' },
        { value: 'HUMAN_RESOURCES_EMPLOYMENT_SERVICES', label: 'Human resources employment services' },
        { value: 'RESEARCH_CONSULTING_SERVICES', label: 'Research consulting services' },
        { value: 'AIR_FREIGHT_LOGISTICS', label: 'Air freight logistics' },
        { value: 'AIRLINES', label: 'Airlines' },
        { value: 'MARINE', label: 'Marine' },
        { value: 'RAILROADS', label: 'Railroads' },
        { value: 'TRUCKING', label: 'Trucking' },
        { value: 'AIRPORT_SERVICES', label: 'Airport services' },
        { value: 'HIGHWAYS_RAILTRACKS', label: 'Highways railtracks' },
        { value: 'MARINE_PORTS_SERVICES', label: 'Marine ports services' }
    ],
    [COMPANY_SECTOR.CONSUMER_DISCRETIONARY]: [
        { value: 'AUTO_PARTS_EQUIPMENT', label: 'Auto parts equipment' },
        { value: 'TIRES_RUBBER', label: 'Tires rubber' },
        { value: 'AUTOMOBILE_MANUFACTURERS', label: 'Automobile manufacturers' },
        { value: 'MOTORCYCLE_MANUFACTURERS', label: 'Motorcycle manufacturers' },
        { value: 'CONSUMER_ELECTRONICS', label: 'Consumer electronics' },
        { value: 'HOME_FURNISHINGS', label: 'Home furnishings' },
        { value: 'HOMEBUILDING', label: 'Homebuilding' },
        { value: 'HOUSEHOLD_APPLIANCES', label: 'Household appliances' },
        { value: 'HOUSEWARES_SPECIALTIES', label: 'Housewares specialties' },
        { value: 'LEISURE_PRODUCTS', label: 'Leisure products' },
        { value: 'APPAREL_ACCESSORIES_LUXURY_GOODS', label: 'Apparel accessories luxury goods' },
        { value: 'FOOTWARE', label: 'Footware' },
        { value: 'TEXTILES', label: 'Textiles' },
        { value: 'CASINOS_GAMING', label: 'Casinos gaming' },
        { value: 'HOTELS_RESORTS_CRUISE_LINES', label: 'Hotels resorts cruise lines' },
        { value: 'LEISURE_FACILITIES', label: 'Leisure facilities' },
        { value: 'RESTAURANTS', label: 'Restaurants' },
        { value: 'EDUCATION_SERVICES', label: 'Education services' },
        { value: 'SPECIALIZED_CONSUMER_SERVICES', label: 'Specialized consumer services' },
        { value: 'DISTRUBUTORS', label: 'Distrubutors' },
        { value: 'INTERNET_DIRECT_MARKETING_RETAIL', label: 'Internet direct marketing retail' },
        { value: 'DEPARTMENT_STORES', label: 'Department stores' },
        { value: 'GENERAL_MERCHANDISE_STORES', label: 'General merchandise stores' },
        { value: 'APPAREL_RETAIL', label: 'Apparel retail' },
        { value: 'COMPUTER_ELECTRONICS_RETAIL', label: 'Computer electronics retail' },
        { value: 'HOME_IMPROVEMENT_RETAIL', label: 'Home improvement retail' },
        { value: 'SPECIALTY_STORES', label: 'Specialty stores' },
        { value: 'AUTOMOTIVE_RETAIL', label: 'Automotive retail' },
        { value: 'HOME_FURNISHING_RETAIL', label: 'Home furnishing retail' }
    ],
    [COMPANY_SECTOR.CONSUMER_STAPLES]: [
        { value: 'DRUG_RETAIL', label: 'Drug retail' },
        { value: 'FOOD_DISTRIBUTORS', label: 'Food distributors' },
        { value: 'FOOD_RETAIL', label: 'Food retail' },
        { value: 'HYPERMARKETS_SUPER_CENTERS', label: 'Hypermarkets super centers' },
        { value: 'BREWERS', label: 'Brewers' },
        { value: 'DISTILLERS_VINTERS', label: 'Distillers vinters' },
        { value: 'SOFT_DRINKS', label: 'Soft drinks' },
        { value: 'AGRICULTURAL_PRODUCTS', label: 'Agricultural products' },
        { value: 'PACKAGED_FOODS_MEATS', label: 'Packaged foods meats' },
        { value: 'TOBACCO', label: 'Tobacco' },
        { value: 'HOUSEHOLD_PRODUCTS', label: 'Household products' },
        { value: 'PERSONAL_PRODUCTS', label: 'Personal products' }
    ],
    [COMPANY_SECTOR.HEALTH_CARE]: [
        { value: 'HEALTHCARE_SUPPLIES', label: 'Healthcare equipment' },
        { value: 'HEALTHCARE_SUPPLIES', label: 'Healthcare supplies' },
        { value: 'HEALTHCARE_DISTRIBUTORS', label: 'Healthcare distributors' },
        { value: 'HEALTHCARE_SERVICES', label: 'Healthcare services' },
        { value: 'HEALTHCARE_FACILITIES', label: 'Healthcare facilities' },
        { value: 'MANAGED_HEALTHCARE', label: 'Managed healthcare' },
        { value: 'HEALTHCARE_TECHNOLOGY', label: 'Healthcare technology' },
        { value: 'BIOTECHNOLOGY', label: 'Biotechnology' },
        { value: 'PHARMECEURICALS', label: 'Pharmeceuricals' },
        { value: 'LIFE_SCIENCES_TOOLS_SERVICES', label: 'Life sciences tools services' }
    ],
    [COMPANY_SECTOR.FINANCIALS]: [
        { value: 'DIVERSIFIED_BANKS', label: 'Diversified banks' },
        { value: 'REGIONAL_BANKS', label: 'Regional banks' },
        { value: 'THRIFTS_MORTGAGE_FINANCE', label: 'Thrifts mortgage finance' },
        { value: 'OTHER_DIVERSIFIES_FINANCIAL_SERVICES', label: 'Other diversifies financial services' },
        { value: 'MULTI_SECTOR_HOLDINGS', label: 'Multi sector holdings' },
        { value: 'SPECIALIZED_FINANCE', label: 'Specialized finance' },
        { value: 'CONSUMER_FINANCE', label: 'Consumer finance' },
        { value: 'ASSET_MANAGEMENT_CUSTODY_BANKS', label: 'Asset management custody banks' },
        { value: 'INVESTMENT_BANKING_BROKERAGE', label: 'Investment banking brokerage' },
        { value: 'DIVERSIFIES_CAPITAL_MARKETS', label: 'Diversifies capital markets' },
        { value: 'FINANCIAL_EXCHANGES_DATA', label: 'Financial exchanges data' },
        { value: 'MORTGAGE_REIT', label: 'Mortgage reit' },
        { value: 'INSURANCE_BROKERS', label: 'Insurance brokers' },
        { value: 'LIFE_HEALTH_INSURANCE', label: 'Life health insurance' },
        { value: 'MULTI_LINE_INSURANCE', label: 'Multi line insurance' },
        { value: 'PROPERTY_CASUALTY_INSURANCE', label: 'Property casualty insurance' },
        { value: 'REINSURANCE', label: 'Reinsurance' }
    ],
    [COMPANY_SECTOR.INFORMATION_TECHNOLOGY]: [
        { value: 'IT_CONSULTING_OTHER_SERVICES', label: 'It consulting other services' },
        { value: 'DATA_PROCESSING_OUTSOURCED_SERVICES', label: 'Data processing outsourced services' },
        { value: 'INTERNET_SERVICES_INFRASTRUCTURE', label: 'Internet services infrastructure' },
        { value: 'APPLICATION_SOFTWARE', label: 'Application software' },
        { value: 'SYSTEMS_SOFTWARE', label: 'Systems software' },
        { value: 'COMMUNICATIONS_EQUIPMENT', label: 'Communications equipment' },
        { value: 'TECHNOLOGY_HARDWARE_STORAGE_PERIPHERALS', label: 'Electronic equipment instruments' },
        { value: 'ELECTRONIC_EQUIPMENT_INSTRUMENTS', label: 'Electronic equipment instruments' },
        { value: 'ELECTRONIC_COMPONENTS', label: 'Electronic components' },
        { value: 'ELECTRONIC_MANUFACTURING_SERVICES', label: 'Electronic manufacturing services' },
        { value: 'TECHNOLOGY_DISTRIBUTORS', label: 'Technology distributors' },
        { value: 'SEMICONDUCTOR_EQUIPMENT', label: 'Semiconductor equipment' },
        { value: 'SEMICONDUCTORS', label: 'Semiconductors' }
    ],
    [COMPANY_SECTOR.TELECOMMUNICATIONS_SERVICES]: [
        { value: 'ALTERNATIVE_CARRIERS', label: 'Alternative carriers' },
        { value: 'INTEGRATED_TELECOMS_SERVICES', label: 'Integrated telecoms services' },
        { value: 'WIRELESS_TELECOMS_SERVICES', label: 'Wireless telecoms services' },
        { value: 'ADVSERTISING', label: 'Advsertising' },
        { value: 'BROADCASTING', label: 'Broadcasting' },
        { value: 'CABLE_SATELLITE', label: 'Cable satellite' },
        { value: 'PUBLISHING', label: 'Publishing' },
        { value: 'MOVIES_ENTERTAINMENT', label: 'Movies entertainment' },
        { value: 'INTERACTIVE_HOME_ENTERTAINMENT', label: 'Interactive home entertainment' },
        { value: 'INTERACTIVE_MEDIA_SERVICES', label: 'Interactive media services' }
    ],
    [COMPANY_SECTOR.UTILITIES]: [
        { value: 'ELECTRONIC_UTILITIES', label: 'Electronic utilities' },
        { value: 'GAS_UTILITIES', label: 'Gas utilities' },
        { value: 'MULTI_UTILITIES', label: 'Multi utilities' },
        { value: 'WATER_UTILITIES', label: 'Water utilities' },
        { value: 'INDEPENDENT_POWER_PRODUCERS_ENERGY_TRADERS', label: 'Independent power producers energy traders' },
        { value: 'RENEWABLE_ELECTRICITY', label: 'Renewable electricity' }
    ],
    [COMPANY_SECTOR.REAL_ESTATE]: [
        { value: 'DIVERSIFIED_REIT', label: 'Diversified reit' },
        { value: 'INDUSTRIAL_REIT', label: 'Industrial reit' },
        { value: 'HOTEL_RESORT_REIT', label: 'Hotel resort reit' },
        { value: 'OFFICE_REIT', label: 'Office reit' },
        { value: 'HEALTHCARE_REIT', label: 'Healthcare reit' },
        { value: 'RESIDENTIAL_REIT', label: 'Residential reit' },
        { value: 'RETAIL_REIT', label: 'Retail reit' },
        { value: 'SPECIALIZED_REIT', label: 'Specialized reit' },
        { value: 'DIVERSIFIED_REAL_ESTATE_ACTIVITIES', label: 'Diversified real estate activities' },
        { value: 'REAL_ESTATE_OPERATING_COMPANIES', label: 'Real estate operating companies' },
        { value: 'REAL_ESTATE_DEVELOPMENT', label: 'Real estate development' },
        { value: 'REAL_ESTATE_SERVICES', label: 'Real estate services' }
    ]
}

export const COMPANY_SECTOR_OPTIONS = [
    { value: 'Administration', label: 'Administration' },
    { value: 'CharityRegistered', label: 'Charity Registered' },
    { value: 'CorporateTrusteeDuties', label: 'Corporate Trustee Duties' },
    { value: 'EducationTraining', label: 'Education Training' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'FinancialServiceAccounting', label: 'Financial Service Accounting' },
    { value: 'Investment', label: 'Investment' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'PrimaryProductionAndServices', label: 'Primary Production and Services' },
    { value: 'ProfessionalServices', label: 'Professional Services' },
    { value: 'PropertyRentalRealEstate', label: 'Property Rental Real Estate' },
    { value: 'ResearchConsulting', label: 'Research Consulting' },
    { value: 'TechnologyServices', label: 'Technology Services' },
    { value: 'TradeServices', label: 'Trade Services' },
    { value: 'TradingSharePurchasing', label: 'Trading Share Purchasing' }
]

export const COMPANY_TYPE_OPTIONS = [
    { value: 'PRIVATE', label: 'Private' },
    { value: 'UNLISTED_PUBLIC', label: 'Unlisted Public' },
    { value: 'LISTED_PUBLIC', label: 'Listed Public' },
    { value: 'FINANCIAL_INSTITUTION', label: 'Financial Institution' }
]

export const RELATIONSHIP_TYPE = {
    OWNER: 'OWNER',
    POWER_OF_ATTORNEY: 'POWER_OF_ATTORNEY',
    OTHER: 'OTHER',
    DIRECTOR: 'DIRECTOR',
    BENEFICIAL_OWNER: 'BENEFICIAL_OWNER',
    BENEFICARY: 'BENEFICARY',
    BENEFICARY_CLASS: 'BENEFICARY_CLASS',
    CONTRIBUTOR: 'CONTRIBUTOR',
    TRUST_CONTRIBUTOR: 'TRUST_CONTRIBUTOR',
    TRUST_EXECUTOR: 'TRUST_EXECUTOR',
    TRUST_APPOINTER_PROTECTOR: 'TRUST_APPOINTER_PROTECTOR',
    TRUST_SETTLOR: 'TRUST_SETTLOR',
    MEMBER: 'MEMBER'
}

export const TRUST_TYPE_OPTIONS = [
    { value: 'BARE', label: 'Bare' },
    { value: 'DISCRETIONARY', label: 'Discretionary' },
    { value: 'FIXED', label: 'Fixed' },
    { value: 'UNIT', label: 'Unit' },
    { value: 'OTHER', label: 'Other' }
]

export const ROLE_IN_COMPANY_OPTIONS = [
    { value: RELATIONSHIP_TYPE.DIRECTOR, label: 'Director' },
    { value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER, label: 'Beneficial Owner' },
    { value: RELATIONSHIP_TYPE.OTHER, label: 'Other' }
]

export const ROLE_IN_SUPER_OPTIONS = {
    [ACCOUNT_TYPE.INDIVIDUAL]: [
        { value: RELATIONSHIP_TYPE.MEMBER, label: 'Member' },
        { value: RELATIONSHIP_TYPE.OTHER, label: 'Other' }
    ],
    [ACCOUNT_TYPE.COMPANY]: [
        { value: RELATIONSHIP_TYPE.DIRECTOR, label: 'Director' },
        { value: RELATIONSHIP_TYPE.MEMBER, label: 'Member' },
        { value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER, label: 'Beneficial Owner' },
        { value: RELATIONSHIP_TYPE.OTHER, label: 'Other' }
    ]
}

export const ROLE_IN_TRUST_OPTIONS = [
    { label: 'Director', value: RELATIONSHIP_TYPE.DIRECTOR },
    { label: 'Member', value: RELATIONSHIP_TYPE.MEMBER },
    { label: 'Beneficial owner', value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER }
]

export const BENEFICIAL_POSITION_OPTIONS = [
    { value: 'treasurer', label: 'Treasurer' },
    { value: 'votermanager', label: 'Voter/Manager' },
    { value: 'controller', label: 'Controller' },
    { value: 'owner', label: 'Owner' },
    { value: 'other', label: 'Other' }
]

export const REASON_TIN_OPTIONS = [
    { value: 'I have not been issued a TIN', label: 'I have not been issued a TIN' },
    { value: 'I do not need a TIN', label: 'I do not need a TIN' },
    { value: 'I have applied for a TIN and have not yet received it', label: 'I have applied for a TIN and have not yet received it' },
    { value: 'I do not have a TIN for another reason', label: 'I do not have a TIN for another reason' }
]

export const POSITION_OPTIONS = [
    { value: 'administrator', label: 'Administrator' },
    { value: 'secretary', label: 'Company Secretary' },
    { value: 'director', label: 'Director' },
    { value: 'other', label: 'Other' }
]

export const VERIFY_STATUS = {
    VERIFIED: 'Verified',
    VERIFIED_WITH_MINOR_CHANGE: 'Verified, with minor changes',
    VERIFIED_EXTERNALLY: 'Verified externally',
    VERIFIED_BY_ADMINISTRATOR: 'Verified by an administrator',
    NOT_YET_VERIFIED: 'Not yet verified',
    LOCK_OUT: 'Locked out',
    FAILED: 'Failed',
    ABANDONED: 'Abandoned',
    VERIFIED_NOT_REQUIRED: 'Verification not required'
}

export const PREFERENCE_STATUS = {
    [VERIFY_STATUS.VERIFIED]: {
        icon: 'CheckCircleOutline',
        img: '/company/verified-icon-21@2x.png',
        iconColor: '#A8CD37',
        background: 'verified',
        text: VERIFY_STATUS.VERIFIED
    },
    [VERIFY_STATUS.VERIFIED_WITH_MINOR_CHANGE]: {
        icon: 'CheckCircleOutline',
        img: '/company/verified-icon-21@2x.png',
        iconColor: '#A8CD37',
        background: 'verified',
        text: VERIFY_STATUS.VERIFIED_WITH_MINOR_CHANGE
    },
    [VERIFY_STATUS.VERIFIED_EXTERNALLY]: {
        icon: 'CheckCircleOutline',
        img: '/company/verified-icon-21@2x.png',
        iconColor: '#A8CD37',
        background: 'verified',
        text: VERIFY_STATUS.VERIFIED_EXTERNALLY
    },
    [VERIFY_STATUS.VERIFIED_BY_ADMINISTRATOR]: {
        icon: 'CheckCircleOutline',
        img: '/company/verified-icon-21@2x.png',
        iconColor: '#A8CD37',
        background: 'verified',
        text: VERIFY_STATUS.VERIFIED_BY_ADMINISTRATOR
    },
    [VERIFY_STATUS.NOT_YET_VERIFIED]: {
        icon: 'PauseCircleOutline',
        img: '/company/inprogress-icon-21@2x.png',
        color: '#767676',
        background: 'normal',
        text: VERIFY_STATUS.NOT_YET_VERIFIED
    },
    [VERIFY_STATUS.LOCK_OUT]: {
        icon: 'Lock',
        img: '/company/lockout-icon-21@2x.png',
        color: '#B92022',
        background: 'failed',
        text: VERIFY_STATUS.LOCK_OUT
    },
    [VERIFY_STATUS.FAILED]: {
        icon: 'Close',
        img: '/company/failed-icon-52@2x.png',
        color: '#B92022',
        background: 'failed',
        text: VERIFY_STATUS.FAILED
    },
    [VERIFY_STATUS.ABANDONED]: {
        icon: 'PauseCircleOutline',
        img: '/company/abandoned-icon-52@2x.png',
        color: '#767676',
        background: 'normal',
        text: VERIFY_STATUS.ABANDONED
    },
    [VERIFY_STATUS.VERIFIED_NOT_REQUIRED]: {
        icon: 'PauseCircleOutline',
        img: '/company/not-contributing-icon-21@2x.png',
        color: '#767676',
        background: 'normal',
        text: VERIFY_STATUS.VERIFIED_NOT_REQUIRED
    }
}

export const ACCOUNT_STATUS = {
    IN_KYC: 'IN_KYC',
    REJECTED: 'REJECTED',
    PENDING_KYC_APPROVAL: 'PENDING_KYC_APPROVAL',
    PENDING_BANK_ACCOUNT_INFO: 'PENDING_BANK_ACCOUNT_INFO',
    PENDING_BANK_TPA: 'PENDING_BANK_TPA',
    PENDING_APPLICATION_SUBMIT: 'PENDING_APPLICATION_SUBMIT',
    IN_PENDING_DEVELOPMENT: 'IN_PENDING_DEVELOPMENT',
    IN_REFERRED: 'IN_REFERRED',
    IN_DEVELOPMENT: 'IN_DEVELOPMENT',
    PENDING_MANUAL_STEPS: 'PENDING_MANUAL_STEPS',
    IN_REVIEW: 'IN_REVIEW',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    PENDING_AUTO_STEPS: 'PENDING_AUTO_STEPS',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED'
}

export const ACCOUNT_STATUS_DISPLAY = {
    [ACCOUNT_STATUS.IN_KYC]: 'WE ARE VERIFYING YOUR IDENTITY',
    [ACCOUNT_STATUS.REJECTED]: 'WE CANNOT VERIFY YOUR IDENTITY',
    [ACCOUNT_STATUS.PENDING_KYC_APPROVAL]: 'WE ARE REVIEWING YOUR APPLICATION',
    [ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO]: 'WE ARE WAITING FOR YOUR BANK ACCOUNT INFO',
    [ACCOUNT_STATUS.PENDING_BANK_TPA]: 'BANK ACCOUNT IN PROGRESS',
    [ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.IN_REFERRED]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.IN_DEVELOPMENT]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.PENDING_MANUAL_STEPS]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.IN_REVIEW]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.PENDING_APPROVAL]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.PENDING_AUTO_STEPS]: 'WE ARE PROCESSING YOUR APPLICATION',
    [ACCOUNT_STATUS.COMPLETED]: 'DONE! YOUR ACCOUNT IS READY!',
    [ACCOUNT_STATUS.CANCELLED]: 'YOUR APPLICATION HAS BEEN CANCELLED'
}

export const GOVERNMENT_ID_TYPE = {
    DRIVER_LICENSE: 'DRIVER_LICENSE',
    MEDICARE_CARD: 'MEDICARE_CARD',
    PASSPORT: 'PASSPORT'
};

export const DEFAULT_APPLICANT = {
    is_trust_beneficial_owner: false,
    is_trust_beneficiary: false,
    alternate_name: '',
    applicant_id: '',
    nationality: '',
    role_in_trust: '',
    role_in_fund: '',
    role_in_company: '',
    tos_consent: true,
    ekyc_aml_consent: false,
    client_address: false,
    morrison_confirm: false,
    terms_confirm: false,
    quant_edge_privacy_statement_confirm: false,
    macquarie_confirm: false,
    total_confirm: false,
    australian_tax_resident: true,
    tax_exemption: false,
    applicant_email: '',
    same_as_ra: true,
    government_id: {
        type: { label: 'Drivers License', value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE },
        state_of_issue: '',
        number: '',
        medicare_card_expiry_date: '',
        medicare_card_colour: '',
        medicare_name_on_card: '',
        medicare_individual_reference_number: ''
    },
    equity: '',
    title: '',
    gender: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    dob: '',
    relationship_type: '',
    country_of_birth: '',
    occupation_type: '',
    occupation_category: '',
    source_of_wealth: '',
    tfn: '',
    tax_exemption_details: '',
    residential_address_manual_address: false,
    residential_address_street_number: '',
    residential_address_unit_flat_number: '',
    residential_address_postcode: '',
    residential_address_address_line_1: '',
    residential_address_address_line_2: '',
    residential_address_state: '',
    residential_address_street_name: '',
    residential_address_city_suburb: '',
    residential_address_street_type: '',
    residential_address_country: '',
    residential_address_full_address: '',
    applicant_mobile_phone: ''
}

export const ACCOUNT_TYPE_OPTIONS = [
    { label: 'Individual', value: ACCOUNT_TYPE.INDIVIDUAL },
    { label: 'Joint', value: ACCOUNT_TYPE.JOINT },
    { label: 'Company', value: ACCOUNT_TYPE.COMPANY }
];

export const COMPANY_VERIFY_TYPE = {
}

export const COMPANY_VERIFY_TYPE_OPTIONS = [
    { label: "Look up a business's structure using an ACN", value: '1' }
    // { label: "Look up a business's top-level details only using an ABN ", value: '2' },
    // { label: "Create a business's structure manually ", value: '3' },
];

export const GENDER_OPTIONS = [
    {
        label: 'Male',
        value: 'MALE'
    },
    {
        label: 'Female',
        value: 'FEMALE'
    },
    {
        label: 'Other',
        value: 'OTHER'
    }
];

export const AUSTRALIAN_TAX_OPTIONS = [
    {
        label: 'Yes',
        value: true
    },
    {
        label: 'No',
        value: false
    }
];

export const AUSTRALIAN_TAX_RESIDENT_OPTIONS = [
    {
        label: 'Yes, I am a tax resident of Australia',
        value: true
    },
    {
        label: 'No',
        value: false
    }
];

export const TRUSTEE_TYPE_OPTIONS = [
    {
        label: 'Individual trustee(s)',
        value: ACCOUNT_TYPE.INDIVIDUAL
    },
    {
        label: 'Corporate trustee',
        value: ACCOUNT_TYPE.COMPANY
    }
];

export const SETTLEMENT_METHOD_OPTIONS = [
    {
        label: 'Yes - Establish a new HIN',
        value: false
    },
    {
        label: 'No - Transfer an existing HIN from another broker',
        value: true
    }
];

export const GENDER_BY_TITLE = {
    MR: 'MALE',
    MRS: 'FEMALE',
    MS: 'FEMALE'
};

export const TITLE_OPTIONS = [
    { label: 'Mr', value: 'MR' },
    { label: 'Mrs', value: 'MRS' },
    { label: 'Ms', value: 'MS' },
    { label: 'Miss', value: 'MISS' },
    { label: 'Dr', value: 'DR' },
    { label: 'Prof', value: 'PROF' },
    { label: 'Adml', value: 'ADML' },
    { label: 'Att', value: 'ATT' },
    { label: 'Brig', value: 'BRIG' },
    { label: 'Brn', value: 'BRN' },
    { label: 'Bshp', value: 'BSHP' },
    { label: 'Capt', value: 'CAPT' },
    { label: 'Cmdr', value: 'CMDR' },
    { label: 'Clr', value: 'CLR' },
    { label: 'Col', value: 'COL' },
    { label: 'Comm', value: 'COMM' },
    { label: 'Cpl', value: 'CPL' },
    { label: 'Dame', value: 'DAME' },
    { label: 'Est', value: 'EST' },
    { label: 'Flt', value: 'FLT' },
    { label: 'Fr', value: 'FR' },
    { label: 'Gcpt', value: 'GCPT' },
    { label: 'Hon', value: 'HON' },
    { label: 'Jdg', value: 'JDG' },
    { label: 'Lady', value: 'LADY' },
    { label: 'Lt', value: 'LT' },
    { label: 'Ltcl', value: 'LTCL' },
    { label: 'Maj', value: 'MAJ' },
    { label: 'Mdm', value: 'MDM' },
    { label: 'Msrs', value: 'MSRS' },
    { label: 'Mstr', value: 'MSTR' },
    { label: 'Pstr', value: 'PSTR' },
    { label: 'Rab', value: 'RAB' },
    { label: 'Rev', value: 'REV' },
    { label: 'Sen', value: 'SEN' },
    { label: 'Sgt', value: 'SGT' },
    { label: 'Sir', value: 'SIR' },
    { label: 'Sr', value: 'SR' },
    { label: 'Wcmd', value: 'WCMD' },
    { label: 'Other', value: 'OTHER' }
];

export const GOVERNMENT_ID_TYPE_OPTIONS = [
    { label: 'Drivers License', value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE },
    // { label: 'Medicare Card', value: GOVERNMENT_ID_TYPE.MEDICARE_CARD },
    { label: 'Passport', value: GOVERNMENT_ID_TYPE.PASSPORT }
];

export const ALL_LANGUAGE = [
    { label: 'English', value: 'en' },
    { label: 'Vietnamese', value: 'vn' }
]

export const STATE_OF_ISSUE = {
    ACT: 'ACT',
    NSW: 'NSW',
    NT: 'NT',
    QLD: 'QLD',
    SA: 'SA',
    TAS: 'TAS',
    VIC: 'VIC',
    WA: 'WA'
};

export const STATE_OF_ISSUE_OPTIONS = [
    { label: STATE_OF_ISSUE.ACT, value: STATE_OF_ISSUE.ACT },
    { label: STATE_OF_ISSUE.NSW, value: STATE_OF_ISSUE.NSW },
    { label: STATE_OF_ISSUE.NT, value: STATE_OF_ISSUE.NT },
    { label: STATE_OF_ISSUE.QLD, value: STATE_OF_ISSUE.QLD },
    { label: STATE_OF_ISSUE.SA, value: STATE_OF_ISSUE.SA },
    { label: STATE_OF_ISSUE.TAS, value: STATE_OF_ISSUE.TAS },
    { label: STATE_OF_ISSUE.VIC, value: STATE_OF_ISSUE.VIC },
    { label: STATE_OF_ISSUE.WA, value: STATE_OF_ISSUE.WA }
];

export const MEDICARE_CARD_COLOUR = {
    GREEN: 'G',
    BLUE: 'B',
    YELLOW: 'Y'
};

export const MEDICARE_CARD_COLOUR_OPTIONS = [
    {
        label: 'Green',
        value: MEDICARE_CARD_COLOUR.GREEN
    },
    {
        label: 'Blue',
        value: MEDICARE_CARD_COLOUR.BLUE
    },
    {
        label: 'Yellow',
        value: MEDICARE_CARD_COLOUR.YELLOW
    }
];

export const MEDICARE_CARD_INDIDUAL_NUMBERS = [
    {
        label: '1',
        value: 1
    },
    {
        label: '2',
        value: 2
    },
    {
        label: '3',
        value: 3
    },
    {
        label: '4',
        value: 4
    },
    {
        label: '5',
        value: 5
    }
];

export const OCCUPATION_TYPE = {
    PROD: {
        BUSINESS_OWNER: 'Business Owner',
        CHIEF_EXECUTIVES: 'Chief Executives, General Managers and Legislators',
        CLERIAL_ADMINISTRATIVE: 'Clerical and administrative workers',
        COMUNITY_PERSONAL: 'Community and personal service workers',
        EMPLOYEES: 'Employees',
        HOMEMAKER: 'Homemaker',
        LABOURERS: 'Labourers',
        MACHINERY_OPERATORS: 'Machinery operators and drivers',
        MILITARY: 'Military',
        PROFESSIONALS: 'Professionals',
        RETIRED: 'Retired',
        SALES_WORKERS: 'Sales workers',
        STUDENT: 'Student',
        TECHNICIANS_TRADES: 'Technicians and trades workers'
    },
    DEV: {
        BUSINESS_OWNER: 'Business Owner',
        EMPLOYEES: 'Employees',
        CLERIAL_ADMINISTRATIVE: 'Clerical and administrative workers',
        COMUNITY_PERSONAL: 'Community and personal service workers',
        HOMEMAKER: 'Homemaker',
        LABOURERS: 'Labourers',
        MACHINERY_OPERATORS: 'Machinery operators and drivers',
        MILITARY: 'Military',
        PROFESSIONALS: 'Professionals',
        RETIRED: 'Retired',
        SALES_WORKERS: 'Sales workers',
        STUDENT: 'Student',
        TECHNICIANS_TRADES: 'Technicians and trades workers'
    }
};

export const OCCUPATION_TYPE_OPTIONS = Object.values(OCCUPATION_TYPE[getEnv()]).map(
    (e) => ({ label: e, value: e })
);

export const OCCUPATION_CATEGORY = {
    PROD: {
        [OCCUPATION_TYPE.PROD.BUSINESS_OWNER]: [
            'Accommodation and Food Services',
            'Administrative and Support Services',
            'Arms or Weapons Manufacture or Distribution',
            'Arts and Recreation Services',
            'Bar or Licensed Club',
            'Betting, Bookmaking, Gambling and Gaming',
            'Cafe and Restaurant',
            'Charity Community or Social Services',
            'Construction',
            'Strata Manager',
            'Digital Currency Traders',
            'Education and Training',
            'Electricity, Gas, Water and Waste Services',
            'Farming and Agriculture',
            'Financial and Insurance Services',
            'Health Care and Social Assistance',
            'Hotel and Motel',
            'Information Media and Telecommunications',
            'Jewel, Gem and Precious Metals',
            'Mining, Gas, Oil and Petroleum',
            'Money Exchange or Foreign FX Services',
            'Pawn Brokers',
            'Professional, Scientific and Technical Services',
            'Public Administration and Safety',
            'Real Estate Agent',
            'Rental, Hiring and Real Estate Services',
            'Retail Trade',
            'Transport, Postal and Warehousing',
            'Wholesale Trade'
        ],
        [OCCUPATION_TYPE.PROD.CHIEF_EXECUTIVES]: [
            'Accommodation and Food Services',
            'Administrative and Support Services',
            'Arms or Weapons Manufacture or Distribution',
            'Arts and Recreation Services',
            'Bar or Licensed Club',
            'Betting, Bookmaking, Gambling and Gaming',
            'Cafe and Restaurant',
            'Charity Community or Social Services',
            'Construction',
            'Strata Manager',
            'Digital Currency Traders',
            'Education and Training',
            'Electricity, Gas, Water and Waste Services',
            'Farming and Agriculture',
            'Financial and Insurance Services',
            'Health Care and Social Assistance',
            'Hotel and Motel',
            'Information Media and Telecommunications',
            'Jewel, Gem and Precious Metals',
            'Mining, Gas, Oil and Petroleum',
            'Money Exchange or Foreign FX Services',
            'Pawn Brokers',
            'Professional, Scientific and Technical Services',
            'Public Administration and Safety',
            'Real Estate Agent',
            'Rental, Hiring and Real Estate Services',
            'Retail Trade',
            'Transport, Postal and Warehousing',
            'Wholesale Trade'
        ],
        [OCCUPATION_TYPE.PROD.CLERIAL_ADMINISTRATIVE]: [
            'Clerical and Administrative Workers',
            'Clerical and Office Support Workers',
            'General Clerical Workers',
            'Inquiry Clerks and Receptionists',
            'Numerical Clerks',
            'Office Managers and Program Administrators',
            'Personal Assistants and Secretaries'
        ],
        [OCCUPATION_TYPE.PROD.COMUNITY_PERSONAL]: [
            'Carers and Aides',
            'Health and Welfare Support Workers',
            'Hospitality Workers',
            'Protective Service Workers',
            'Sports and Personal Service Workers'
        ],
        [OCCUPATION_TYPE.PROD.EMPLOYEES]: [
            'Advertising, Public Relations and Sales',
            'Construction, Distribution and Production',
            'Education, Health and Welfare Services',
            'Farmers and Farm',
            'Hospitality, Retail and Service',
            'Information & Communication Technology'
        ],
        [OCCUPATION_TYPE.PROD.HOMEMAKER]: ['Homemaker'],
        [OCCUPATION_TYPE.PROD.LABOURERS]: [
            'Cleaners and Laundry Workers',
            'Construction and Mining Labourers',
            'Factory Process Workers',
            'Farm, Forestry and Garden Workers',
            'Food Preparation Assistants',
            'Labourers'
        ],
        [OCCUPATION_TYPE.PROD.MACHINERY_OPERATORS]: [
            'Machinery',
            'Mobile Plant Operators',
            'Road and Rail Drivers',
            'Storepersons'
        ],
        [OCCUPATION_TYPE.PROD.MILITARY]: ['Military enlisted', 'Military officer'],
        [OCCUPATION_TYPE.PROD.PROFESSIONALS]: [
            'Accountants, Auditors and Company Secretaries',
            'Arts and Media Professionals',
            'Aviation',
            'Strata Manager',
            'Business, Human Resource and Marketing Professionals',
            'Design, Engineering, Science and Transport Professionals',
            'Doctor, Veterinarian, Health Professionals',
            'Education Professionals',
            'Financial Brokers and Dealers, and Investment Advisers',
            'Information & Communication Technology Professionals',
            'Legal, Social and Welfare Professionals',
            'Real Estate Agent'
        ],
        [OCCUPATION_TYPE.PROD.RETIRED]: ['Retired'],
        [OCCUPATION_TYPE.PROD.SALES_WORKERS]: [
            'Sales Assistants and Salespersons',
            'Sales Representatives and Agents',
            'Sales Support Workers'
        ],
        [OCCUPATION_TYPE.PROD.STUDENT]: ['Student'],
        [OCCUPATION_TYPE.PROD.TECHNICIANS_TRADES]: [
            'Automotive and Engineering Trades Workers',
            'Construction Trades Workers',
            'Electrotechnology and Telecommunications Trades Workers',
            'Engineering and Science Technicians',
            'Food Trades Workers',
            'Information & Communication Technology Technicians',
            'Other Technicians and Trades Workers',
            'Skilled Animal and Horticultural Workers'
        ]
    },
    DEV: {
        [OCCUPATION_TYPE.DEV.BUSINESS_OWNER]: [
            'Accommodation and Food Services',
            'Administrative and Support Services',
            'Arms or Weapons Manufacture or Distribution',
            'Arts and Recreation Services',
            'Bar or Licensed Club',
            'Betting, Bookmaking, Gambling and Gaming',
            'Cafe and Restaurant',
            'Charity Community or Social Services',
            'Construction',
            'Digital Currency Traders',
            'Education and Training',
            'Electricity, Gas, Water and Waste Services',
            'Farming and Agriculture',
            'Financial and Insurance Services',
            'Health Care and Social Assistance',
            'Hotel and Motel',
            'Information Media and Telecommunications',
            'Jewel, Gem and Precious Metals',
            'Mining, Gas, Oil and Petroleum',
            'Money Exchange or Foreign FX Services',
            'Pawn Brokers',
            'Professional, Scientific and Technical Services',
            'Public Administration and Safety',
            'Real Estate Agent',
            'Rental, Hiring and Real Estate Services',
            'Retail Trade',
            'Transport, Postal and Warehousing',
            'Wholesale Trade'
        ],
        [OCCUPATION_TYPE.DEV.CLERIAL_ADMINISTRATIVE]: [
            'Clerical and Administrative Workers',
            'Clerical and Office Support Workers',
            'General Clerical Workers',
            'Inquiry Clerks and Receptionists',
            'Numerical Clerks',
            'Office Managers and Program Administrators',
            'Personal Assistants and Secretaries'
        ],
        [OCCUPATION_TYPE.DEV.COMUNITY_PERSONAL]: [
            'Carers and Aides',
            'Health and Welfare Support Workers',
            'Hospitality Workers',
            'Protective Service Workers',
            'Sports and Personal Service Workers'
        ],
        [OCCUPATION_TYPE.DEV.EMPLOYEES]: [
            'Advertising, Public Relations and Sales',
            'Construction, Distribution and Production',
            'Education, Health and Welfare Services',
            'Farmers and Farm',
            'Hospitality, Retail and Service',
            'Information & Communication Technology'
        ],
        [OCCUPATION_TYPE.DEV.HOMEMAKER]: ['Homemaker'],
        [OCCUPATION_TYPE.DEV.LABOURERS]: [
            'Cleaners and Laundry Workers',
            'Construction and Mining Labourers',
            'Factory Process Workers',
            'Farm, Forestry and Garden Workers',
            'Food Preparation Assistants',
            'Labourers'
        ],
        [OCCUPATION_TYPE.DEV.MACHINERY_OPERATORS]: [
            'Machinery',
            'Mobile Plant Operators',
            'Road and Rail Drivers',
            'Storepersons'
        ],
        [OCCUPATION_TYPE.DEV.MILITARY]: ['Military enlisted', 'Military officer'],
        [OCCUPATION_TYPE.DEV.PROFESSIONALS]: [
            'Accountants, Auditors and Company Secretaries',
            'Arts and Media Professionals',
            'Aviation',
            'Business, Human Resource and Marketing Professionals',
            'Design, Engineering, Science and Transport Professionals',
            'Doctor, Veterinarian, Health Professionals',
            'Education Professionals',
            'Financial Brokers and Dealers, and Investment Advisers',
            'Information & Communication Technology Professionals',
            'Legal, Social and Welfare Professionals',
            'Real Estate Agent'
        ],
        [OCCUPATION_TYPE.DEV.RETIRED]: ['Retired'],
        [OCCUPATION_TYPE.DEV.SALES_WORKERS]: [
            'Sales Assistants and Salespersons',
            'Sales Representatives and Agents',
            'Sales Support Workers'
        ],
        [OCCUPATION_TYPE.DEV.STUDENT]: ['Student'],
        [OCCUPATION_TYPE.DEV.TECHNICIANS_TRADES]: [
            'Automotive and Engineering Trades Workers',
            'Construction Trades Workers',
            'Electrotechnology and Telecommunications Trades Workers',
            'Engineering and Science Technicians',
            'Food Trades Workers',
            'Information & Communication Technology Technicians',
            'Other Technicians and Trades Workers',
            'Skilled Animal and Horticultural Workers'
        ]
    }
}

export const OCCUPATION_CATEGORY_OPTIONS = Object.keys(
    OCCUPATION_CATEGORY[getEnv()]
).reduce((acc, cur) => {
    acc[cur] = OCCUPATION_CATEGORY[getEnv()][cur].map((e) => ({ label: e, value: e }));
    return acc;
}, {});

export const NO_TIN_REASON_OPTIONS = [
    {
        label: 'I have not been issued a TIN',
        value: 'I have not been issued a TIN'
    },
    {
        label: 'I do not need a TIN',
        value: 'I do not need a TIN'
    },
    {
        label: 'I have applied for a TIN and have not yet received it',
        value: 'I have applied for a TIN and have not yet received it'
    },
    {
        label: 'I do not have a TIN for another reason',
        value: 'I do not have a TIN for another reason'
    }
]

export const TAX_EXEMPTION_DETAILS_OPTIONS = [
    {
        label: 'Tax return not required',
        value: 'TAX_RETURN_NOT_REQUIRED'
    }
];

export const TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS = [
    {
        label: 'Pensioner Receiving Social Security / Service Pension',
        value: 'Pensioner receiving Social Security / Service Pension'
    },
    {
        label: 'Recipient Of Other Social Security Pension Or Benefit',
        value: 'Recipient of other Social Security Pension or benefit'
    },
    {
        label: 'Not Required To Lodge A Tax Return',
        value: 'Not required to lodge a Tax Return'
    },
    {
        label: 'Non-resident Of Australia',
        value: 'Non-resident of Australia'
    }
];

export const CMA = {
    CREATE_NEW: 'NEW_CMA',
    USE_EXISTING: 'EXISTING',
    DO_NOT_CREATE: 'DO_NOT_CREATE'
};

export const BANK_ACCOUNT_TYPE = {
    EMPTY: 'EMPTY',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    LINKED_CMT_CMA: 'LINKED_CMT_CMA'
}

export const TRANSACTION_TYPE = {
    BOTH: 'BOTH',
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT'
}

export const CMT_PROVIDER = {
    ADL: 'ADL',
    ANZ: 'ANZ',
    DDH: 'DDH',
    DDHW: 'DDHW',
    MBLA: 'MBLA'
}

export const STREET_TYPE = [
    { label: 'Alley', value: 'Alley' },
    { label: 'Amble', value: 'Amble' },
    { label: 'Approach', value: 'Approach' },
    { label: 'Arcade', value: 'Arcade' },
    { label: 'Arterial', value: 'Arterial' },
    { label: 'Avenue', value: 'Avenue' },
    { label: 'Bay', value: 'Bay' },
    { label: 'Bend', value: 'Bend' },
    { label: 'Brae', value: 'Brae' },
    { label: 'Break', value: 'Break' },
    { label: 'Boulevard', value: 'Boulevard' },
    { label: 'Boardwalk', value: 'Boardwalk' },
    { label: 'BowlBypass', value: 'BowlBypass' },
    { label: 'Circle', value: 'Circle' },
    { label: 'Circus', value: 'Circus' },
    { label: 'Circuit', value: 'Circuit' },
    { label: 'Chase', value: 'Chase' },
    { label: 'Close', value: 'Close' },
    { label: 'Corner', value: 'Corner' },
    { label: 'Common', value: 'Common' },
    { label: 'Concourse', value: 'Concourse' },
    { label: 'Crescent', value: 'Crescent' },
    { label: 'Cross', value: 'Cross' },
    { label: 'Course', value: 'Course' },
    { label: 'Crest', value: 'Crest' },
    { label: 'Cruiseway', value: 'Cruiseway' },
    { label: 'Court/S', value: 'Court/S' },
    { label: 'Cove', value: 'Cove' },
    { label: 'Dale', value: 'Dale' },
    { label: 'Dell', value: 'Dell' },
    { label: 'Dene', value: 'Dene' },
    { label: 'Divide', value: 'Divide' },
    { label: 'Domain', value: 'Domain' },
    { label: 'Drive', value: 'Drive' },
    { label: 'East', value: 'East' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Entrance', value: 'Entrance' },
    { label: 'Esplanade', value: 'Esplanade' },
    { label: 'Extension', value: 'Extension' },
    { label: 'Flats', value: 'Flats' },
    { label: 'Ford', value: 'Ford' },
    { label: 'Freeway', value: 'Freeway' },
    { label: 'Gate', value: 'Gate' },
    { label: 'Garden/S', value: 'Garden/S' },
    { label: 'Glade/S', value: 'Glade/S' },
    { label: 'Glen', value: 'Glen' },
    { label: 'Gully', value: 'Gully' },
    { label: 'Grange', value: 'Grange' },
    { label: 'Green', value: 'Green' },
    { label: 'Grove', value: 'Grove' },
    { label: 'Gateway', value: 'Gateway' },
    { label: 'Hill', value: 'Hill' },
    { label: 'Hollow', value: 'Hollow' },
    { label: 'Heath', value: 'Heath' },
    { label: 'Heights', value: 'Heights' },
    { label: 'Hub', value: 'Hub' },
    { label: 'Highway', value: 'Highway' },
    { label: 'Island', value: 'Island' },
    { label: 'Junction', value: 'Junction' },
    { label: 'Lane', value: 'Lane' },
    { label: 'Link', value: 'Link' },
    { label: 'Loop', value: 'Loop' },
    { label: 'Lower', value: 'Lower' },
    { label: 'Laneway', value: 'Laneway' },
    { label: 'Mall', value: 'Mall' },
    { label: 'Mew', value: 'Mew' },
    { label: 'Mews', value: 'Mews' },
    { label: 'Nook', value: 'Nook' },
    { label: 'North', value: 'North' },
    { label: 'Outlook', value: 'Outlook' },
    { label: 'Path', value: 'Path' },
    { label: 'Parade', value: 'Parade' },
    { label: 'Pocket', value: 'Pocket' },
    { label: 'Parkway', value: 'Parkway' },
    { label: 'Place', value: 'Place' },
    { label: 'Plaza', value: 'Plaza' },
    { label: 'Promenade', value: 'Promenade' },
    { label: 'Pass', value: 'Pass' },
    { label: 'Passage', value: 'Passage' },
    { label: 'Point', value: 'Point' },
    { label: 'Pursuit', value: 'Pursuit' },
    { label: 'Pathway', value: 'Pathway' },
    { label: 'Quadrant', value: 'Quadrant' },
    { label: 'Quay', value: 'Quay' },
    { label: 'Reach', value: 'Reach' },
    { label: 'Road', value: 'Road' },
    { label: 'Ridge', value: 'Ridge' },
    { label: 'Reserve', value: 'Reserve' },
    { label: 'Rest', value: 'Rest' },
    { label: 'Retreat', value: 'Retreat' },
    { label: 'Ride', value: 'Ride' },
    { label: 'Rise', value: 'Rise' },
    { label: 'Round', value: 'Round' },
    { label: 'Row', value: 'Row' },
    { label: 'Rising', value: 'Rising' },
    { label: 'Return', value: 'Return' },
    { label: 'Run', value: 'Run' },
    { label: 'Slope', value: 'Slope' },
    { label: 'Square', value: 'Square' },
    { label: 'Street', value: 'Street' },
    { label: 'South', value: 'South' },
    { label: 'Strip', value: 'Strip' },
    { label: 'Steps', value: 'Steps' },
    { label: 'Subway', value: 'Subway' },
    { label: 'Terrace', value: 'Terrace' },
    { label: 'Throughway', value: 'Throughway' },
    { label: 'Tor', value: 'Tor' },
    { label: 'Track', value: 'Track' },
    { label: 'Trail', value: 'Trail' },
    { label: 'Turn', value: 'Turn' },
    { label: 'Tollway', value: 'Tollway' },
    { label: 'Upper', value: 'Upper' },
    { label: 'Valley', value: 'Valley' },
    { label: 'Vista', value: 'Vista' },
    { label: 'View/S', value: 'View/S' },
    { label: 'Way', value: 'Way' },
    { label: 'Wood', value: 'Wood' },
    { label: 'West', value: 'West' },
    { label: 'Walk', value: 'Walk' },
    { label: 'Walkway', value: 'Walkway' },
    { label: 'Waters', value: 'Waters' },
    { label: 'Waterway', value: 'Waterway' },
    { label: 'Wynd', value: 'Wynd' }
]

export const CMT_PROVIDER_OPTIONS = [
    { label: 'Adelaide Bank Limited (ADL)', value: CMT_PROVIDER.ADL },
    { label: 'ANZ (ANZ)', value: CMT_PROVIDER.ANZ },
    { label: 'DDH Graham (DDH)', value: CMT_PROVIDER.DDH },
    { label: 'DDH Westpac (DDHW)', value: CMT_PROVIDER.DDHW },
    { label: 'Macquarie Bank Limited (MBLA)', value: CMT_PROVIDER.MBLA }
]

export const CMA_OPTIONS = [
    { label: 'New Macquarie CMA (Cash Management) Bank Account', value: CMA.CREATE_NEW },
    { label: 'Existing Macquarie CMA Bank Account', value: CMA.USE_EXISTING },
    { label: 'None', value: CMA.DO_NOT_CREATE }
];

export const TRUST_COUNTRY_ESTABLISHED = [
    { label: 'Australia', value: 'AUSTRALIA' },
    { label: 'Other', value: 'OTHER' }
]

export const SOURCE_OF_WEALTH_OPTIONS = [
    {
        label: 'Employment',
        value: 'EMPLOYMENT'
    },
    {
        label: 'Gift Windfall',
        value: 'GIFT_WINDFALL'
    },
    {
        label: 'Inheritance',
        value: 'INHERITANCE'
    },
    {
        label: 'Investments Australia',
        value: 'INVESTMENTS_AUSTRALIA'
    },
    {
        label: 'Investments Savings',
        value: 'INVESTMENTS_SAVINGS'
    },
    {
        label: 'Savings',
        value: 'SAVINGS'
    }
];

export const CMA_SOURCE_OF_FUNDS = {
    PROD: {
        EMPLOYMENT_BUSINESS_INCOME: 'EMPLOYMENT_BUSINESS_INCOME',
        INVESTMENTS_AND_DIVIDENDS: 'INVESTMENTS_AND_DIVIDENDS',
        ASSET_SALE: 'ASSET_SALE',
        SUPERANNUATION_PENSION: 'SUPERANNUATION_PENSION',
        SAVINGS: 'SAVINGS',
        INHERITANCE_GIFT: 'INHERITANCE_GIFT',
        GOVT_BENEFIT_SOCIAL_SECURITY: 'GOVT_BENEFIT_SOCIAL_SECURITY'
    },
    DEV: {
        SUPERANNUATION_CONTRIBUTIONS: 'SUPERANNUATION_CONTRIBUTIONS',
        COMMISSION: 'COMMISSION',
        INHERITANCE: 'INHERITANCE',
        SAVINGS: 'SAVINGS',
        INVESTMENT: 'INVESTMENT',
        NORMAL_COURSE_OF_BUSINESS: 'NORMAL_COURSE_OF_BUSINESS',
        ASSET_SALE: 'ASSET_SALE',
        OTHER: 'OTHER'
    }
}

export const CMA_SOURCE_OF_FUNDS_OPTIONS = {
    PROD: [
        {
            label: 'Employment / Business income',
            value: CMA_SOURCE_OF_FUNDS.PROD.EMPLOYMENT_BUSINESS_INCOME
        },
        { label: 'Investments and Dividends', value: CMA_SOURCE_OF_FUNDS.PROD.INVESTMENTS_AND_DIVIDENDS },
        { label: 'Asset sale', value: CMA_SOURCE_OF_FUNDS.PROD.ASSET_SALE },
        { label: 'Superannuation / Pension', value: CMA_SOURCE_OF_FUNDS.PROD.SUPERANNUATION_PENSION },
        { label: 'Savings', value: CMA_SOURCE_OF_FUNDS.PROD.SAVINGS },
        {
            label: 'Inheritance / Gift',
            value: CMA_SOURCE_OF_FUNDS.PROD.INHERITANCE_GIFT
        },
        { label: 'Govt benefit / Social security', value: CMA_SOURCE_OF_FUNDS.PROD.GOVT_BENEFIT_SOCIAL_SECURITY }
    ],
    DEV: [
        {
            label: 'Superannuation Contributions',
            value: CMA_SOURCE_OF_FUNDS.DEV.SUPERANNUATION_CONTRIBUTIONS
        },
        { label: 'Commission', value: CMA_SOURCE_OF_FUNDS.DEV.COMMISSION },
        { label: 'Inheritance', value: CMA_SOURCE_OF_FUNDS.DEV.INHERITANCE },
        { label: 'Savings', value: CMA_SOURCE_OF_FUNDS.DEV.SAVINGS },
        { label: 'Investment', value: CMA_SOURCE_OF_FUNDS.DEV.INVESTMENT },
        {
            label: 'Normal Course Of Business',
            value: CMA_SOURCE_OF_FUNDS.DEV.NORMAL_COURSE_OF_BUSINESS
        },
        { label: 'Asset Sale', value: CMA_SOURCE_OF_FUNDS.DEV.ASSET_SALE },
        { label: 'Other', value: CMA_SOURCE_OF_FUNDS.DEV.OTHER }
    ]
}

export const CMA_ACCOUNT_PURPOSE = {
    PROD: {
        SAVINGS: 'SAVINGS',
        INVESTMENT_GROWTH: 'INVESTMENT_GROWTH',
        PERSONAL_DAY_TO_DAY_USAGE: 'PERSONAL_DAY_TO_DAY_USAGE',
        BUSINESS_TRANSACTING: 'BusinessTransacting',
        RETIREMENT: 'Retirement'
    },
    DEV: {
        SAVINGS: 'SAVINGS',
        GROWTH: 'GROWTH',
        INCOME: 'INCOME',
        RETIREMENT: 'RETIREMENT',
        BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
        OTHER: 'OTHER'
    }
}

export const CMA_ACCOUNT_PURPOSE_OPTIONS = {
    PROD: [
        { label: 'Savings', value: CMA_ACCOUNT_PURPOSE.PROD.SAVINGS },
        { label: 'Investment / Growth', value: CMA_ACCOUNT_PURPOSE.PROD.INVESTMENT_GROWTH },
        { label: 'Personal day-to-day usage', value: CMA_ACCOUNT_PURPOSE.PROD.PERSONAL_DAY_TO_DAY_USAGE }
    ],
    DEV: [
        { label: 'Savings', value: CMA_ACCOUNT_PURPOSE.DEV.SAVINGS },
        { label: 'Growth', value: CMA_ACCOUNT_PURPOSE.DEV.GROWTH },
        { label: 'Income', value: CMA_ACCOUNT_PURPOSE.DEV.INCOME },
        { label: 'Retirement', value: CMA_ACCOUNT_PURPOSE.DEV.RETIREMENT },
        { label: 'Business Account', value: CMA_ACCOUNT_PURPOSE.DEV.BUSINESS_ACCOUNT },
        { label: 'Other', value: CMA_ACCOUNT_PURPOSE.DEV.OTHER }
    ]
}

export const BSB_OPTION = [
    { label: '182-222', value: '182-222' },
    { label: '184-446', value: '184-446' },
    { label: '182-512', value: '182-512' },
    { label: '183-334', value: '183-334' },
    { label: '186-200', value: '186-200' }
]

export const LIST_OF_PIDS = [
    { label: '[01027] Euroz Securities Limited', value: '01027' },
    { label: '[01028] Euroz Securities Limited', value: '01028' },
    { label: '[01033] Terrain Securities Pty Ltd', value: '01033' },
    { label: '[01042] Cybertrade Australia Securities Limited', value: '01042' },
    { label: '[01052] Westpac Securities Limited', value: '01052' },
    { label: '[01062] Bridges Financial Services Pty Ltd', value: '01062' },
    { label: '[01063] Bridges Financial Services Pty Limited', value: '01063' },
    { label: '[01064] Bridges Financial Services Pty Limited', value: '01064' },
    { label: '[01089] Morrison Securities Pty Ltd', value: '01089' },
    { label: '[01103] Credit Suisse First Boston Aust Equ Ltd', value: '01103' },
    { label: '[01104] Credit Suisse Equities (australia) Ltd', value: '01104' },
    { label: '[01113] Berndale Securities Ltd', value: '01113' },
    { label: '[01123] Burdett, Buckeridge & Young Limited', value: '01123' },
    { label: '[01124] Bby Limited', value: '01124' },
    { label: '[01132] Cameron Stockbroking Limited', value: '01132' },
    { label: '[01135] Openmarkets Australia Limited', value: '01135' },
    { label: '[01136] Open Markets Australia Limited', value: '01136' },
    { label: '[01153] Peake Lands Kirwan Pty Ltd', value: '01153' },
    { label: '[01158] Lands Kirwan Tong Stockbrokers Pty Ltd', value: '01158' },
    { label: '[01183] Fleet Stockbroking Ltd', value: '01183' },
    { label: '[01192] Fortis Clearing Sydney Pty Limited', value: '01192' },
    { label: '[01193] Abn Amro Clearing Sydney Pty Ltd', value: '01193' },
    { label: '[01212] Reynolds & Co Pty Ltd', value: '01212' },
    { label: '[01226] Wealthhub Securities Limited', value: '01226' },
    { label: '[01227] Wealthhub Securities Limited', value: '01227' },
    { label: '[01231] Foster Stockbroking Pty Ltd', value: '01231' },
    { label: '[01312] Grange Securities Limited', value: '01312' },
    { label: '[01322] Bnp Paribas Equities Private (aust) Ltd', value: '01322' },
    { label: '[01353] Bnp Paribas Equities (australia) Limited', value: '01353' },
    { label: '[01382] Hudson Securities Pty Ltd', value: '01382' },
    { label: '[01392] Dicksons Ltd', value: '01392' },
    { label: '[01402] Commonwealth Securities Limited', value: '01402' },
    { label: '[01442] Etrade Australia Securities Limited', value: '01442' },
    { label: '[01452] Southern Cross Equities Ltd', value: '01452' },
    { label: '[01459] Southern Cross Equities Limited', value: '01459' },
    { label: '[01493] William Noall Ltd', value: '01493' },
    { label: '[01502] Ubs Securities Australia Limited', value: '01502' },
    { label: '[01505] Ubs Securities Australia Limited', value: '01505' },
    { label: '[01543] Bell Potter Securities Limited', value: '01543' },
    { label: '[01572] Macquarie Securities (australia) Limited', value: '01572' },
    { label: '[01602] Commonwealth Securities Ltd (margin Len)', value: '01602' },
    { label: '[01652] Religare Securities Australia Pty Ltd', value: '01652' },
    { label: '[01782] Findlay & Co Stockbroking Ltd', value: '01782' },
    { label: '[01792] Pershing Securities Australia Pty Ltd', value: '01792' },
    { label: '[01802] Hsbc Stockbroking (australia) Pty Ltd', value: '01802' },
    { label: '[01822] Finclear Execution Limited', value: '01822' },
    { label: '[01833] Tiffit Securities (australia) Ptd Ltd', value: '01833' },
    { label: '[01843] Merrill Lynch Private (australia) Ltd', value: '01843' },
    { label: '[01862] Cazenove Australia Pty Ltd', value: '01862' },
    { label: '[01892] Bnp Paribas Securities Sevices', value: '01892' },
    { label: '[01992] Avcol Stockbroking Pty Ltd', value: '01992' },
    { label: '[02002] Td Waterhouse Investor Services Limited', value: '02002' },
    { label: '[02022] Baker Young Stockbrokers Limited', value: '02022' },
    { label: '[02026] Baker Young Stockbrokers Limited', value: '02026' },
    { label: '[02032] Citigroup Global Markets Australia P/l', value: '02032' },
    { label: '[02033] Citigroup Global Markets Aust Pty Ltd', value: '02033' },
    { label: '[02102] Deutsche Securities Australia Limited', value: '02102' },
    { label: '[02103] Deutsche Securities Australia Limited', value: '02103' },
    { label: '[02104] Deutsche Securities Australia Limited', value: '02104' },
    { label: '[02105] Deutsche Securities Australia Limited', value: '02105' },
    { label: '[02183] Mj Wren And Partners Stockbrokers', value: '02183' },
    { label: '[02262] Joseph Palmer & Sons', value: '02262' },
    { label: '[02282] Aot Australia Pty Ltd', value: '02282' },
    { label: '[02332] Ord Minnett Ltd', value: '02332' },
    { label: '[02337] Ord Minnett Limited', value: '02337' },
    { label: '[02338] Ord Minnett', value: '02338' },
    { label: '[02339] Ord Minnett Limited', value: '02339' },
    { label: '[02353] Anz Securities Limited', value: '02353' },
    { label: '[02442] Macquarie Equities Ltd Retail', value: '02442' },
    { label: '[02492] Statton Securities', value: '02492' },
    { label: '[02493] Ccz Statton Equities Pty Ltd', value: '02493' },
    { label: '[02502] Instinet Australia Limited', value: '02502' },
    { label: '[02542] Rbc Securities Australia Pty Limited', value: '02542' },
    { label: '[02552] Third Party Platform Pty Ltd', value: '02552' },
    { label: '[02622] Kj Polkinghorne & Co Pty Ltd', value: '02622' },
    { label: '[02652] Cmc Markets Stockbroking Limited', value: '02652' },
    { label: '[02662] Cmc Markets Stockbroking Limited', value: '02662' },
    { label: '[02703] Abn Amro Equities Australia Ltd', value: '02703' },
    { label: '[02705] Cimb Securities (australia) Ltd', value: '02705' },
    { label: '[02894] D2mx Pty Ltd', value: '02894' },
    { label: '[02921] Intersuisse Securities Limited', value: '02921' },
    { label: '[02922] Octa Phillip Securities Limited(pershing', value: '02922' },
    { label: '[02923] Intersuisse Ltd', value: '02923' },
    { label: '[02924] Phillip Capital Limited', value: '02924' },
    { label: '[02972] Jp Morgan Securities Australia Limited', value: '02972' },
    { label: '[02981] Shaw Stockbroking Limited', value: '02981' },
    { label: '[02982] Shaw Stockbroking Limited', value: '02982' },
    { label: '[02992] Morgan Stanley Dean Witter Aust Sec Ltd', value: '02992' },
    { label: '[03003] Ubs Private Clients Australia Limited', value: '03003' },
    { label: '[03033] Citigroup Securities Clearing Aust Ltd', value: '03033' },
    { label: '[03063] Daiwa Securities Smbc Stockbroking Ltd', value: '03063' },
    { label: '[03073] Austock Securities Limited', value: '03073' },
    { label: '[03074] Austock Limited', value: '03074' },
    { label: '[03083] Barton Capital Securities Pty Ltd', value: '03083' },
    { label: '[03102] Comsec Trading Limited', value: '03102' },
    { label: '[03103] Cba Equities Limited', value: '03103' },
    { label: '[03113] El&c Baillieu Stockbroking Ltd', value: '03113' },
    { label: '[03118] Baillieu Holst Ltd', value: '03118' },
    { label: '[03173] Johnson Taylor Potter Limited', value: '03173' },
    { label: '[03223] Tolhurst Noall Limited', value: '03223' },
    { label: '[03273] Falkiners Stockbroking Limited', value: '03273' },
    { label: '[03303] Opes Prime Stockbroking Ltd', value: '03303' },
    { label: '[03332] Tir Securities (australia) Limited', value: '03332' },
    { label: '[03383] Morgan Stanley Wealth Management Austral', value: '03383' },
    { label: '[03453] Itg Australia Limited', value: '03453' },
    { label: '[03523] Fw Holst & Co Pty Ltd', value: '03523' },
    { label: '[03552] Lonsec Limited', value: '03552' },
    { label: '[03554] Lonsec Limited (pershing)', value: '03554' },
    { label: '[03555] Finclear Execution Limited (ubs)', value: '03555' },
    { label: '[03556] Finclear Execution Limited (ubs)', value: '03556' },
    { label: '[03610] Goldman Sachs J B Were Pty Ltd', value: '03610' },
    { label: '[03614] Goldman Sachs Jb Were', value: '03614' },
    { label: '[03663] Merrill Lynch Equities (australia) Ltd', value: '03663' },
    { label: '[03666] Merrill Lynch Equities (aust) Ltd', value: '03666' },
    { label: '[03882] Charles Schwab Australia Pty Ltd', value: '03882' },
    { label: '[04064] Morgans Financial Limited', value: '04064' },
    { label: '[04094] Burrell Stockbroking Pty Ltd', value: '04094' },
    { label: '[04114] Challenger First Pacific Limited', value: '04114' },
    { label: '[04124] Wilson Htm Ltd', value: '04124' },
    { label: '[04125] Wilsons Advisory And Stockbroking Ltd', value: '04125' },
    { label: '[04126] Wilsons Advisory And Stockbroking Ltd', value: '04126' },
    { label: '[05125] Taylor Collison Ltd', value: '05125' },
    { label: '[05127] Taylor Collison Limited', value: '05127' },
    { label: '[05128] Taylor Collison Limited', value: '05128' },
    { label: '[05129] Taylor Collison Limited', value: '05129' },
    { label: '[06046] Dj Carmichael Pty Limited', value: '06046' },
    { label: '[06047] Dj Carmichael Pershing', value: '06047' },
    { label: '[06056] Cibc World Markets Australia Limited', value: '06056' },
    { label: '[06066] Jdv Limited', value: '06066' },
    { label: '[06086] Hartleys Limited', value: '06086' },
    { label: '[06126] Kirke Securities Ltd', value: '06126' },
    { label: '[06128] Kirke Securities Ltd', value: '06128' },
    { label: '[06129] Kirke Securities Ltd', value: '06129' },
    { label: '[06176] Montagu Stockbrokers Pty Ltd', value: '06176' },
    { label: '[06186] Hogan & Partners Stockbrokers Pty Ltd', value: '06186' },
    { label: '[06206] Chartpac Securities Ltd', value: '06206' },
    { label: '[06381] Australian Investment Exchange Limited', value: '06381' },
    { label: '[06386] Australian Investment Exchange', value: '06386' },
    { label: '[06682] Tricom Equities Limited', value: '06682' },
    { label: '[06684] Stonebridge Securities Limited', value: '06684' },
    { label: '[06776] Canaccord Genuity Patersons Limited', value: '06776' },
    { label: '[06777] Canaccord Genuity Financial Ltd', value: '06777' },
    { label: '[06786] State One Stockbroking Ltd', value: '06786' },
    { label: '[06788] State One Stockbroking', value: '06788' },
    { label: '[06886] Mortimer & Chua Pty Ltd', value: '06886' },
    { label: '[07047] Shadforths Limited', value: '07047' },
    { label: '[07107] C J Weedon & Company', value: '07107' },
    { label: '[08014] Citicorp Warrants', value: '08014' },
    { label: '[08072] Rbs Warrants', value: '08072' },
    { label: '[08332] Australian Clearing House Pty Limited', value: '08332' },
    { label: '[08902] Asx International Services Pty Limited', value: '08902' },
    { label: '[09232] Optiver Australia Pty Ltd', value: '09232' },
    { label: '[09402] Timberhill Australia', value: '09402' },
    { label: '[11225] Onevue Fund Serviced Limited', value: '11225' },
    { label: '[11331] National Australia Bank Ltd', value: '11331' },
    { label: '[20001] Commonwealth Custodial Services Limited', value: '20001' },
    { label: '[20003] Jpmorgan Custodial Services Pty Ltd', value: '20003' },
    { label: '[20005] Anz Nominees Limited', value: '20005' },
    { label: '[20006] National Australia Bank Custodian Serv', value: '20006' },
    { label: '[20007] Bnp Paribas Fund Services Australasia Pl', value: '20007' },
    { label: '[20009] Rbc Global Services Australia Pty Ltd', value: '20009' },
    { label: '[20016] Navigator Australia Limited', value: '20016' },
    { label: '[20018] Citicorp Nominees Pty Ltd', value: '20018' },
    { label: '[20021] Bt Securities Limited (margin Lending)', value: '20021' },
    { label: '[20025] Trust Company Of Australia Limited', value: '20025' },
    { label: '[20027] Permanent Trustee Company Ltd', value: '20027' },
    { label: '[20031] Hih Casualty & General Insurance Ltd', value: '20031' },
    { label: '[20036] Merrill Lynch (australia) Nominees Pty Ltd', value: '20036' },
    { label: '[20039] Invia Custodian Pty Ltd', value: '20039' },
    { label: '[20041] Westpac Custodian Nominees Limited', value: '20041' },
    { label: '[20042] Iag Asset Management Limited', value: '20042' },
    { label: '[20052] Hsbc Asset Management Noms (aust) P/l', value: '20052' },
    { label: '[20054] Equity Trustees Limited', value: '20054' },
    { label: '[20057] Hsbc Custody Nominees (aust) Limited', value: '20057' },
    { label: '[20059] National Mutual Funds Management Limited', value: '20059' },
    { label: '[20063] Guardian Trust Australia Limited', value: '20063' },
    { label: '[20066] Certes Ct Pty Ltd', value: '20066' },
    { label: '[20068] Perpetual Trustees Consolidated Limited', value: '20068' },
    { label: '[20075] Australian Casualty & Life Limited', value: '20075' },
    { label: '[20080] Jpmorgan Custodians Limited', value: '20080' },
    { label: '[20081] Jpmorgan Custodial Services P/l (btlife)', value: '20081' },
    { label: '[20082] Sandhurst Trustees Limited', value: '20082' },
    { label: '[20083] Ioof Investment Management Limited (vic)', value: '20083' },
    { label: '[20085] Tower Australia Limited', value: '20085' },
    { label: '[20092] Sepon (australia) Pty Limited', value: '20092' },
    { label: '[20096] Gio Asset Management Ltd', value: '20096' },
    { label: '[20098] Anz Executors & Trustee Company Limited', value: '20098' },
    { label: '[20100] Cgu Insurance Limited', value: '20100' },
    { label: '[20104] Jp Morgan Nominess Australia Limited', value: '20104' },
    { label: '[20106] National Australia Trustees Limited', value: '20106' },
    { label: '[20110] Bank Of Western Australia Limited', value: '20110' },
    { label: '[20117] Margaret Street Nominees Pty Limited', value: '20117' },
    { label: '[20120] Australian Executor Trustee Limited', value: '20120' },
    { label: '[20127] Computershare Clearing Pty Ltd', value: '20127' },
    { label: '[20128] Jdv Solutions Pty Ltd', value: '20128' },
    { label: '[20131] Asgard Capital Management Limited', value: '20131' },
    { label: '[20151] Suncorp Metway Insurance Limited', value: '20151' },
    { label: '[20152] Suncorp Custodian Services Pty Ltd', value: '20152' },
    { label: '[20157] National Mutual Life Nominees Ltd', value: '20157' },
    { label: '[20159] State Trustees Limited', value: '20159' },
    { label: '[20168] Individual Portfolio Managers Pty Ltd', value: '20168' },
    { label: '[20306] Bpc Securities Pty Ltd', value: '20306' },
    { label: '[20311] Australian Stockbroking & Advisory Serv', value: '20311' },
    { label: '[20366] Merrill Lynch Investment Managers Ltd', value: '20366' },
    { label: '[20367] Netwealth Investments Limited', value: '20367' },
    { label: '[20378] Link Market Services', value: '20378' },
    { label: '[20404] Custody Execution & Clearing Services Pl', value: '20404' },
    { label: '[21000] Ing Life Limited', value: '21000' },
    { label: '[21003] Bond Street Custodians Limited Insto', value: '21003' },
    { label: '[21007] Tower Trust (sa) Ltd', value: '21007' },
    { label: '[21008] Leveraged Equities Nominees Limited', value: '21008' },
    { label: '[21009] Public Trustee (sa)', value: '21009' },
    { label: '[21013] Hsbc Securities Investments (aust) P/l', value: '21013' },
    { label: '[21015] Macquarie Investment Management Ltd', value: '21015' },
    { label: '[21017] Zurich Investment Management Limited', value: '21017' },
    { label: '[21018] Metway Credit Corporation Ltd', value: '21018' },
    { label: '[21019] Anz Margin Services Pty Ltd', value: '21019' },
    { label: '[21021] Tyndall Investment Management Limited', value: '21021' },
    { label: '[21022] Perpetual Trustees Tasmania Limited', value: '21022' },
    { label: '[21023] Acs Securities Pty Ltd', value: '21023' },
    { label: '[21024] Jpmorgan Custodial Services P/l (subcus)', value: '21024' },
    { label: '[21025] Bt Portfolio Services Limited', value: '21025' },
    { label: '[21026] Bnp Paribas Equity Finance Pty Ltd', value: '21026' },
    { label: '[21028] Bond Street Custodians Ltd Ptf Serv', value: '21028' },
    { label: '[21029] Office Of The Protective Commissioner', value: '21029' },
    { label: '[21030] National Margin Services Pty Limited', value: '21030' },
    { label: '[21031] National Stock Exchange Of Australia Ltd', value: '21031' },
    { label: '[21033] The Rock Investment Planning', value: '21033' },
    { label: '[21034] Bt Portfolio Services Ltd', value: '21034' },
    { label: '[21035] Tasmanian Perpetual Trustees Limited', value: '21035' },
    { label: '[21036] Mutual Trust Pty Ltd', value: '21036' },
    { label: '[21104] Jp Morgan Nominees Australia Ltd Gsp', value: '21104' },
    { label: '[21105] Anz Nominees Ltd', value: '21105' },
    { label: '[21119] Rbc Investor Services Trust', value: '21119' },
    { label: '[22123] Chimaera Capital Pty Ltd', value: '22123' },
    { label: '[22353] Anz Securities Limited', value: '22353' },
    { label: '[22888] Value Nominees (st George)', value: '22888' },
    { label: '[23005] Ubs Wealth Management Australia', value: '23005' },
    { label: '[27048] Shadforths/ubs Securities', value: '27048' },
    { label: '[27050] Bnp Paribas Securities Sevices', value: '27050' },
    { label: '[27052] One Managed Investment Funds Limited', value: '27052' },
    { label: '[27054] Crestone Wealth Management Limited', value: '27054' }
]

export const SETTLEMENT_METHOD = {
    SPONSORED_NEW_HIN: 'SPONSORED_NEW_HIN',
    SPONSORED_HIN_TRANSFER: 'SPONSORED_HIN_TRANSFER',
    DVP: 'DVP',
    ISSUER_SPONSORED: 'ISSUER_SPONSORED'
};

export const LIST_OF_SETTLEMENT_METHODS = [
    {
        value: SETTLEMENT_METHOD.SPONSORED_NEW_HIN,
        label: 'New HIN'
    },
    {
        value: SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER,
        label: 'HIN Transfer'
    },
    {
        value: SETTLEMENT_METHOD.DVP,
        label: 'DVP (Delivery VS. Payment)'
    },
    {
        value: SETTLEMENT_METHOD.ISSUER_SPONSORED,
        label: 'Issuer Sponsored'
    }
];

export const FATCA_STATUS = {
    DEEMED_COMPLIANT_FINANCIAL_INSTITUTION: 'DeemedCompliantFinancialInstitution',
    EXCEPTED_FINANCIAL_INSTITUTION: 'ExceptedFinancialInstitution',
    EXEMPT_BENEFICIAL_OWNER: 'ExemptBeneficialOwner',
    NON_REPORTING_IGA_FINANCIAL_INSTITUTION: 'NonReportingIGAFinancialInstitution',
    NONPARTICIPATING_FINANCIAL_INSTITUTION: 'NonparticipatingFinancialInstitution',
    OTHER: 'Other'
}

export const FATCA_STATUS_OPTIONS = [
    { value: FATCA_STATUS.DEEMED_COMPLIANT_FINANCIAL_INSTITUTION, label: 'Deemed Compliant Financial Institution' },
    { value: FATCA_STATUS.EXCEPTED_FINANCIAL_INSTITUTION, label: 'Excepted Financial Institution' },
    { value: FATCA_STATUS.EXEMPT_BENEFICIAL_OWNER, label: 'Exempt Beneficial Owner' },
    { value: FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION, label: 'Non Reporting IGA Financial Institution' },
    { value: FATCA_STATUS.NONPARTICIPATING_FINANCIAL_INSTITUTION, label: 'Nonparticipating Financial Institution' },
    { value: FATCA_STATUS.OTHER, label: 'Other' }
]

export const FATCA_STATUS_OPTIONALS = [
    { value: null, label: 'Please Select' },
    { value: FATCA_STATUS.DEEMED_COMPLIANT_FINANCIAL_INSTITUTION, label: 'Deemed Compliant Financial Institution' },
    { value: FATCA_STATUS.EXCEPTED_FINANCIAL_INSTITUTION, label: 'Excepted Financial Institution' },
    { value: FATCA_STATUS.EXEMPT_BENEFICIAL_OWNER, label: 'Exempt Beneficial Owner' },
    { value: FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION, label: 'Non Reporting IGA Financial Institution' },
    { value: FATCA_STATUS.NONPARTICIPATING_FINANCIAL_INSTITUTION, label: 'Nonparticipating Financial Institution' },
    { value: FATCA_STATUS.OTHER, label: 'Other' }
]

export const TAX_STATUS = {
    FINANCIAL_INSTITUTION: 'FinancialInstitution',
    AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE: 'AustralianRegisteredCharityOrDeceasedEstate',
    ACTIVE_NONFINANCIAL_ENTITY: 'ActiveNonFinancialEntity',
    OTHER: 'Other'
}

export const TAX_STATUS_OPTIONS = [
    {
        "value": TAX_STATUS.FINANCIAL_INSTITUTION,
        "label": "Financial Institution"
    },
    {
        "value": TAX_STATUS.AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE,
        "label": "Australian Registered Charity Or Deceased Estate"
    },
    {
        "value": TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY,
        "label": "Active Non-Financial Entity (NFE)"
    },
    {
        "value": TAX_STATUS.OTHER,
        "label": "Other"
    }
]

export const TAX_STATUS_COMPANY_OPTIONS = [
    {
        value: TAX_STATUS.FINANCIAL_INSTITUTION,
        label: 'Financial Institution'
    },
    {
        value: TAX_STATUS.AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE,
        label: 'Australian Registered Charity'
    },
    {
        value: TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY,
        label: 'Active Non-Financial Entity (NFE)'
    },
    {
        value: TAX_STATUS.OTHER,
        label: 'Other'
    }
]

export const TIN_REASON_OPTIONS = [
    { value: null, label: 'Please Select' },
    { value: 'CountryOfTaxResidencyDoesNotIssueTIN', label: 'The country of tax residency does not issue TINs to tax residents' },
    { value: 'NotIssuedWithTIN', label: 'The individual or entity has not been issued with a TIN' },
    { value: 'NoTINRequiredForCountryOfTaxResidency', label: 'The country of tax residency does not require the TIN to be disclosed' }
]

export const TRUST_ACTIVITY = {
    ADMINISTRATION: 'Administration',
    CHARITY_REGISTERED: 'CharityRegistered',
    EDUCATION_TRAINING: 'EducationTraining',
    ENGINEERING: 'Engineering',
    FINANCIAL_SERVICE_ACCOUNTING: 'FinancialServiceAccounting',
    INVESTMENT: 'Investment',
    MANUFACTURING: 'Manufacturing',
    PRIMARY_PRODUCTION_AND_SERVICES: 'PrimaryProductionAndServices',
    PROFESSIONAL_SERVICES: 'ProfessionalServices',
    PROPERTY_RENTAL_REAL_ESTATE: 'PropertyRentalRealEstate',
    RESEARCH_CONSULTING: 'ResearchConsulting',
    TECHNOLOGY_SERVICES: 'TechnologyServices',
    TRADE_SERVICES: 'TradeServices',
    TRADING_SHARE_PURCHASING: 'TradingSharePurchasing',
    SUPERANNUATION_PENSION: 'SuperannuationPension'
}

export const TRUST_ACTIVITY_OPTIONS = [
    { value: TRUST_ACTIVITY.ADMINISTRATION, label: 'Administration' },
    { value: TRUST_ACTIVITY.CHARITY_REGISTERED, label: 'Charity Registered' },
    { value: TRUST_ACTIVITY.EDUCATION_TRAINING, label: 'Education Training' },
    { value: TRUST_ACTIVITY.ENGINEERING, label: 'Engineering' },
    { value: TRUST_ACTIVITY.FINANCIAL_SERVICE_ACCOUNTING, label: 'Financial Service Accounting' },
    { value: TRUST_ACTIVITY.INVESTMENT, label: 'Investment' },
    { value: TRUST_ACTIVITY.MANUFACTURING, label: 'Manufacturing' },
    { value: TRUST_ACTIVITY.PRIMARY_PRODUCTION_AND_SERVICES, label: 'Primary Production and Services' },
    { value: TRUST_ACTIVITY.PROFESSIONAL_SERVICES, label: 'Professional Services' },
    { value: TRUST_ACTIVITY.PROPERTY_RENTAL_REAL_ESTATE, label: 'Property Rental Real Estate' },
    { value: TRUST_ACTIVITY.RESEARCH_CONSULTING, label: 'Research Consulting' },
    { value: TRUST_ACTIVITY.TECHNOLOGY_SERVICES, label: 'Technology Services' },
    { value: TRUST_ACTIVITY.TRADE_SERVICES, label: 'Trade Services' },
    { value: TRUST_ACTIVITY.TRADING_SHARE_PURCHASING, label: 'Trading Share Purchasing' },
    { value: TRUST_ACTIVITY.SUPERANNUATION_PENSION, label: 'Superannuation / Pension' }
]

// CRM OPTIONS

export const aspectFile = {
    Image: 'Image',
    Icon: 'Icon'
}

export const ACCOUNT_STATUS_LABEL = {
    IN_KYC: 'IN KYC',
    REJECTED: 'REJECTED',
    PENDING_KYC_APPROVAL: 'PENDING KYC APPROVAL',
    PENDING_BANK_ACCOUNT_INFO: 'PENDING BANK ACCOUNT INFO',
    PENDING_BANK_TPA: 'PENDING BANK TPA',
    PENDING_APPLICATION_SUBMIT: 'PENDING APPLICATION SUBMIT',
    IN_PENDING_DEVELOPMENT: 'IN PENDING DEVELOPMENT',
    IN_REFERRED: 'IN REFERRED',
    IN_DEVELOPMENT: 'IN DEVELOPMENT',
    PENDING_MANUAL_STEPS: 'PENDING MANUAL STEPS',
    IN_REVIEW: 'IN REVIEW',
    PENDING_APPROVAL: 'PENDING APPROVAL',
    PENDING_AUTO_STEPS: 'PENDING AUTO STEPS',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED'
}

export const NOTIFICATION_TYPE = {
    DIRECT: 'Direct',
    SEGMENT: 'Segment'
}

export const COLUMN_TYPE = {
    ACTION: 'action',
    NOTIFICATION_ACTION: 'notification_action',
    DATE: 'date',
    LINK: 'link',
    CHECKBOX: 'checkbox',
    ARRAY_STRING: 'array_string',
    CHIP: 'chip'
}

export const NOTIFICATION_TYPE_OPTIONS = [
    { value: NOTIFICATION_TYPE.DIRECT, label: NOTIFICATION_TYPE.DIRECT },
    { value: NOTIFICATION_TYPE.SEGMENT, label: NOTIFICATION_TYPE.SEGMENT }
]

export const DELIVERY_TYPE = {
    INSTANT: 'Instant',
    SCHEDULE: 'Schedule'
}

export const STATUS = {
    Triggered: 'Triggered',
    Pending: 'Pending'
}

export const EXPIRE_TIME = {
    HOUR: 'Hours',
    DAY: 'Days',
    WEEK: 'Weeks'
}

export const Type = {
    new_order: 'new_order',
    order_detail: 'order_detail',
    security_detail: 'security_detail',
    article: 'article'
}

export const ORDER = {
    limit: 'limit',
    market_to_limit: 'market-to-limit'
}

export const DELIVERY_TYPE_OPTIONS = [
    { value: DELIVERY_TYPE.INSTANT, label: DELIVERY_TYPE.INSTANT },
    { value: DELIVERY_TYPE.SCHEDULE, label: DELIVERY_TYPE.SCHEDULE }
]

export const DELIVERY_TYPE_OPTIONS_EXIST = [
    { value: 'Instant', label: 'Instantd' },
    { value: 'Scheduled', label: 'Scheduled' }
]

export const EXPIRE_TIME_OPTIONS = [
    { value: 'H', label: EXPIRE_TIME.HOUR },
    { value: 'D', label: EXPIRE_TIME.DAY },
    { value: 'W', label: EXPIRE_TIME.WEEK },
]

export const STATUS_OPTIONS = [
    { value: STATUS.Triggered, label: STATUS.Triggered },
    { value: STATUS.Pending, label: STATUS.Triggered },
]

export const TYPE_URL = [
    { value: Type.new_order, label: 'New Order' },
    { value: Type.order_detail, label: 'Order Detail' },
    { value: Type.security_detail, label: 'Security Detail' },
    { value: Type.article, label: 'Article' }]

export const ORDER_TYPE = [
    { value: ORDER.limit, label: 'Limit' },
    { value: ORDER.market_to_limit, label: 'Market To Limit' }
]
export const TYPE_SCOPE = {
    Operation: 'Operation',
    Advisor: 'Advisor'
}
export const ACCOUNT_TYPE_PUT_DRAFT = {
    COMPANY: 'COMPANY',
    TRUST: 'TRUST',
    FUND: 'SUPER_FUND',
}
export const USER_TYPE_ACCOUNT = {
    Operation: 'operation',
    Advisor: 'advisor'
}