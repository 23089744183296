import { configureStore } from '@reduxjs/toolkit'
import auth from './auth/auth';
import segment from './segment/segment';
import notification from './notification/notification';
import alert from './alert/alert';

const store = configureStore({
    reducer: {
        auth,
        segment,
        notification,
        alert
    }
});

export default store;

