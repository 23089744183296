import {
    ACCOUNT_TYPE,
    GOVERNMENT_ID_TYPE,
    TRUST_ACTIVITY
} from 'src/constants';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
    smsf: false,
    fatca_status: '',
    fatca_status_other: '',
    name_of_regulator: 'ATO',
    abn_or_registration_licensing_details: '',
    global_intermediary_identification_number: '',
    trustee_global_intermediary_identification_number: '',
    applicant: '',
    mailing_address_manual_address: false,
    mailing_address_country: '',
    mailing_address_full_address: '',
    mailing_address_street_number: '',
    mailing_address_street_name: '',
    mailing_address_street_type: '',
    mailing_address_unit_flat_number: '',
    mailing_address_postcode: '',
    mailing_address_state: '',
    mailing_address_city_suburb: '',
    mailing_address_address_line_1: '',
    mailing_address_address_line_2: '',
    account_designation: '',
    super_fund_address_manual_address: false,
    super_fund_address_country: '',
    super_fund_address_full_address: '',
    super_fund_address_street_number: '',
    super_fund_address_street_name: '',
    super_fund_address_street_type: '',
    super_fund_address_unit_flat_number: '',
    super_fund_address_postcode: '',
    super_fund_address_state: '',
    super_fund_address_city_suburb: '',
    super_fund_address_address_line_1: '',
    super_fund_address_address_line_2: '',
    company_registered_office_address_manual_address: false,
    company_principal_place_of_business_address_manual_address: false,
    tos_ip: '',
    tos_user_agent: '',
    account_type: ACCOUNT_TYPE.FUND,
    send_registration_email: true,
    new_cma: true,
    bank_method: '',
    bank_account_type: '',
    tradeable_products: {
        equity: ''
    },
    trade_confirmations: [{
        email: '',
        client_address: false
    }],
    branch: { value: 'BR00000000', label: 'DEFAULT VETTING RULES' },
    super_fund_name: '',
    super_fund_type: '',
    trustee_type: { value: ACCOUNT_TYPE.INDIVIDUAL, label: 'Individual trustee(s)' },
    super_fund_tfn: '',
    super_fund_tax_exemption: false,
    super_fund_tax_exemption_details: '',
    super_fund_abn: '',
    super_fund_activity: '',
    company_name: '',
    company_type: '',
    company_sector: '',
    company_acn: '',
    company_abn: '',
    company_tfn: '',
    company_tax_exemption: false,
    company_tax_exemption_details: '',
    company_date_of_incorporation: '',
    company_country_of_incorporation: 'AUSTRALIA',
    company_nature_of_business_activity: '',
    company_work_phone: '',
    company_mobile_phone: '',
    company_email: '',
    company_registered_office_address_country: '',
    company_registered_office_address_full_address: '',
    company_registered_office_address_street_number: '',
    company_registered_office_address_street_name: '',
    company_registered_office_address_street_type: '',
    company_registered_office_address_unit_flat_number: '',
    company_registered_office_address_postcode: '',
    company_registered_office_address_state: '',
    company_registered_office_address_city_suburb: '',
    company_registered_office_address_address_line_1: '',
    company_registered_office_address_address_line_2: '',
    company_same_as_roa: false,
    company_principal_place_of_business_address_country: '',
    company_principal_place_of_business_address_full_address: '',
    company_principal_place_of_business_address_street_number: '',
    company_principal_place_of_business_address_street_name: '',
    company_principal_place_of_business_address_street_type: '',
    company_principal_place_of_business_address_unit_flat_number: '',
    company_principal_place_of_business_address_postcode: '',
    company_principal_place_of_business_address_state: '',
    company_principal_place_of_business_address_city_suburb: '',
    company_principal_place_of_business_address_address_line_1: '',
    company_principal_place_of_business_address_address_line_2: '',
    applicant_details: [{
        applicant_id: uuidv4(),
        ekyc_overall_status: '',
        nationality: '',
        role_in_super: '',
        role_in_super_other: '',
        role_in_super_individual: '',
        tos_consent: true,
        ekyc_aml_consent: false,
        client_address: false,
        morrison_confirm: false,
        terms_confirm: false,
        quant_edge_privacy_statement_confirm: false,
        macquarie_confirm: false,
        total_confirm: false,
        australian_tax_resident: true,
        tax_exemption: false,
        applicant_email: '',
        same_as_ra: true,
        government_id: {
            type: { label: 'Drivers License', value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE },
            state_of_issue: '',
            number: '',
            medicare_card_expiry_date: '',
            medicare_card_colour: '',
            medicare_name_on_card: '',
            medicare_individual_reference_number: ''
        },
        equity: '',
        title: '',
        gender: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        relationship_type: '',
        country_of_birth: '',
        occupation_type: '',
        business_owner_trading_name: '',
        abn_acn_registration_number: '',
        business_owner_trading_address_country: '',
        business_owner_trading_address_full_address: '',
        business_owner_trading_address_street_number: '',
        business_owner_trading_address_street_name: '',
        business_owner_trading_address_street_type: '',
        business_owner_trading_address_unit_flat_number: '',
        business_owner_trading_address_postcode: '',
        business_owner_trading_address_state: '',
        business_owner_trading_address_city_suburb: '',
        business_owner_trading_address_address_line_1: '',
        business_owner_trading_address_address_line_2: '',
        occupation_category: '',
        source_of_wealth: '',
        tfn: '',
        tax_exemption_details: '',
        residential_address_manual_address: false,
        residential_address_street_number: '',
        residential_address_unit_flat_number: '',
        residential_address_postcode: '',
        residential_address_address_line_1: '',
        residential_address_address_line_2: '',
        residential_address_state: '',
        residential_address_street_name: '',
        residential_address_city_suburb: '',
        residential_address_street_type: '',
        residential_address_country: '',
        residential_address_full_address: '',
        applicant_mobile_phone: ''
    },
    {
        applicant_id: uuidv4(),
        ekyc_overall_status: '',
        nationality: '',
        role_in_super: '',
        role_in_super_other: '',
        role_in_super_individual: '',
        tos_consent: true,
        ekyc_aml_consent: false,
        client_address: false,
        morrison_confirm: false,
        terms_confirm: false,
        quant_edge_privacy_statement_confirm: false,
        macquarie_confirm: false,
        total_confirm: false,
        australian_tax_resident: true,
        tax_exemption: false,
        applicant_email: '',
        same_as_ra: true,
        government_id: {
            type: { label: 'Drivers License', value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE },
            state_of_issue: '',
            number: '',
            medicare_card_expiry_date: '',
            medicare_card_colour: '',
            medicare_name_on_card: '',
            medicare_individual_reference_number: ''
        },
        equity: '',
        title: '',
        gender: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        relationship_type: '',
        country_of_birth: '',
        occupation_type: '',
        business_owner_trading_name: '',
        abn_acn_registration_number: '',
        business_owner_trading_address_country: '',
        business_owner_trading_address_full_address: '',
        business_owner_trading_address_street_number: '',
        business_owner_trading_address_street_name: '',
        business_owner_trading_address_street_type: '',
        business_owner_trading_address_unit_flat_number: '',
        business_owner_trading_address_postcode: '',
        business_owner_trading_address_state: '',
        business_owner_trading_address_city_suburb: '',
        business_owner_trading_address_address_line_1: '',
        business_owner_trading_address_address_line_2: '',
        occupation_category: '',
        source_of_wealth: '',
        tfn: '',
        tax_exemption_details: '',
        residential_address_manual_address: false,
        residential_address_street_number: '',
        residential_address_unit_flat_number: '',
        residential_address_postcode: '',
        residential_address_address_line_1: '',
        residential_address_address_line_2: '',
        residential_address_state: '',
        residential_address_street_name: '',
        residential_address_city_suburb: '',
        residential_address_street_type: '',
        residential_address_country: '',
        residential_address_full_address: '',
        applicant_mobile_phone: ''
    }],
    cma_source_of_funds: '',
    cma_source_of_funds_desc: '',
    cma_account_purpose: '',
    cma_account_purpose_desc: '',
    organization_code: '',
    branch_code: '',
    advisor_code: '',
    bank_bsb: '',
    bank_account_name: '',
    bank_account_number: '',
    bank_cmt_provider: '',
    settlement_method: '',
    settlement_existing_hin: '',
    settlement_pid: '',
    settlement_supplementary_reference: ''
};

export default initialValues
