
import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import {
    postData,
    getData,
    getNotificationUrl
} from 'src/api/api'
import dataStorage from 'src/dataStorage'

const initialState = {
    notifications: [],
    loading: false,
    currentRequestId: undefined,
    error: '',
    paginate: {
        page_size: 10,
        current_page: 1,
        total_count: 0,
        total_pages: 0
    },
    peddingRequest: false
}

export const fetchNotifications = createAsyncThunk(
    'notifications/fetch',
    async (params) => {
        let requestMethod = getData
        let data = {}
        if (params.filter) {
            requestMethod = postData
            data = params.filter
        }
        const url = getNotificationUrl(params)
        const [response, error] = await requestMethod(url, data)
        if (error) throw error
        else return { response, error, pageSize: params.pageSize }
    }
);

export const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setPaginate(state, action) {
            state.paginate = { ...state.paginate, ...action.payload }
        },
        addNotification(state, action) {
            if (dataStorage.selfCreateNotification === action.payload.notification_id || action.payload.segment_id) {
                state.notifications = [action.payload, ...state.notifications]
            }
            dataStorage.selfCreateNotification = ''
        }, editStatusNotification(state, action) {
            const change = state.notifications.map(e => {
                return e.notification_id === action.payload.notification_id ? { ...e, status: action.payload.status } : e
            })
            state.notifications = change
        },
        updateNotification(state, action) {
            const index = state.notifications.findIndex(e => e.notification_id === action.payload.notification_id)
            state.notifications[index] = action.payload
        },
        deleteNotification(state, action) {
            state.notifications = state.notifications.filter(e => e.notification_id !== action.payload)
        },
        setPeddingRequest(state) {
            state.peddingRequest = false
        }
    },
    extraReducers: (builder) => {
        // fetch notifications
        builder.addCase(fetchNotifications.pending, (state, action) => {
            state.loading = true;
            state.currentRequestId = action.meta.requestId;
            state.error = undefined
        })
        builder.addCase(fetchNotifications.fulfilled, (state, action) => {
            if (action.meta.requestId === state.currentRequestId) {
                const { data, ...rest } = action.payload.response
                state.loading = false;
                state.peddingRequest = false
                state.notifications = data || [];
                state.paginate = { ...state.paginate, ...rest, page_size: action.payload.pageSize, current_page: rest.current_page || 1 }
                state.currentRequestId = undefined;
            }
        })
        builder.addCase(fetchNotifications.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (requestId === state.currentRequestId) {
                state.loading = false;
                state.error = action.error.message;
                if (action.error.message) {
                    state.peddingRequest = true
                }
                state.currentRequestId = undefined;
            }
        })
    }
})
const { actions, reducer } = notification
export const {
    setPaginate,
    addNotification,
    updateNotification,
    deleteNotification,
    setPeddingRequest,
    editStatusNotification
} = actions;
export default reducer;

